import { InfoCircleFilled } from '@ant-design/icons';
import { Button, Col, Form, Input, Row, Select, Space, Tooltip, message } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { useForm } from 'antd/lib/form/Form';
import { UploadFile } from 'antd/lib/upload/interface';
import { FormInputPosition } from 'components/form.component';
import { DateSelector, LayoutNav, UploadImage } from 'components/layout.component';
import { MemberGroupSelect } from 'components/member.component';
import { CKEditorComp } from 'components/promotion.component';
import { RESPONSE_CODE_BONUS } from 'constants/response';
import dayjs from 'dayjs';
import { PROMOTION_STATE, PROMOTION_TYPE } from 'enum/promotion';
import { PROMOTION_CYCLE, WHETHER } from 'enum/state';
import useSite from 'hooks/site.hook';
import i18n from 'i18n';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { $get, $post } from 'services';
import { enumToOptions, selectFuzzyFilter, specialProviderName, timeL2S, tzShift, verify } from "utils/common";

// 新增其他優惠

const PageMain: React.FC = () => {
  const site = useSite();
  const navigate = useNavigate();
  const [form] = useForm();
  const [loading, setLoading] = useState(false);
  const [editorContent, setEditorContent] = useState<string>();
  const [isPopupValue, setIsPopupValue] = useState(true);
  const [isOneTime, setIsOneTime] = useState(false);
  const [categoryCodes, setCategoryCodes] = useState<any>([]);

  const { data: category } = $get({
    url: 'api/category/list',
    params: {
      Status: 1,
    },
  });
  const { data: provider } = $get({
    url: 'api/provider/list',
    params: {
      Status: 1,
      PageSize: 999,
      PageIndex: 1
    },
    allow: !!categoryCodes && categoryCodes.length > 0,
  });

  useEffect(() => {
    form.setFieldsValue({
      promotionType: PROMOTION_TYPE.otherPromotions,
      promotionName: '',
      popupDisplay: null,
      redirectToPromotionPage: null,
      period: PROMOTION_CYCLE.promotionPeriod,
      oneTimePromotion: null,
      expectedValidBet: '',
      promotionAmount: '',
      rolloverRate: '',
      applicationMethod: 1,
      distributionMethod: null,
      status: null,
      Position: 0,
    })
    setImageData([])
  }, []);

  const [date, setDate] = useState([
    tzShift(dayjs().startOf('day'), 'YYYY-MM-DD HH:mm:ss'),
    tzShift(dayjs().endOf('day'), 'YYYY-MM-DD HH:mm:ss')
  ]);

  const onFinish = (formData: any) => {
    $post({
      url: 'api/bonus/create',
      send: {
        Name: formData.promotionName,
        SettleMethod: formData.distributionMethod,
        ApplyMethod: formData.applicationMethod,
        Status: formData.status,
        IsPopUp: formData.popupDisplay,
        IsRedirect: formData.redirectToPromotionPage,
        Period: formData.period,
        IsOneTime: formData.oneTimePromotion,
        CategoryCodes: formData.CategoryCodes,
        ProviderIds: formData.ProviderIds,
        LevelsId: formData.LevelId,
        MemberGroupsId: formData.MemberGroupsId,
        ValidBet: Number(formData.expectedValidBet),
        Amount: Number(formData.promotionAmount),
        ValidBetTimes: Number(formData.rolloverRate),
        Position: Number(formData.Position),
        StartDate: timeL2S(date[0]),
        EndDate: timeL2S(date[1]),
        Content: editorContent,
        Photo: imageData[0] ? [imageData[0].url] : []
      },
      success: () => {
        message.success(i18n.t('addSuccess'));
        navigate('/promotion');
      },
      resCode: RESPONSE_CODE_BONUS
    }, setLoading)
  }

  const [imageData, setImageData] = useState<UploadFile<any>[]>([]);

  const typeChanged = (val: number) => {
    switch (val) {
      case PROMOTION_TYPE.otherPromotions:
        navigate('/promotion/add-other')
        break;
      case PROMOTION_TYPE.storePromotions:
        navigate('/promotion/add-store')
        break;
      case PROMOTION_TYPE.redEnvelopePromotions:
        navigate('/promotion/add-red-envelope')
        break;
    }
  }

  const categoryId2Code = (ids: number[]) => {
    const list = (category?.Data || [])
      .filter((item: any) => ids && ids.includes(Number(item.Id)))
      .map((item: any) => item.Code)

    setCategoryCodes(list);
  }

  // 篩選遊戲類型的遊戲商
  const providerFilter = () => {
    let list: string[] = provider?.Data || [];
    return list.filter((item: any) => categoryCodes.includes(item.CategoryCode));
  }

  return (
    <div id="container">
      <LayoutNav />
      <Content className="ph-2">
        <Form layout="vertical" form={form} onFinish={onFinish} initialValues={{
          Position: 0
        }}>
          <Row gutter={[12, 12]}>
            {/* 類別與確認 */}
            <Col span={24}>
              <Row justify="space-between" align="middle">
                <Col>
                  <Form.Item name="promotionType" className="w-12" label={i18n.t('promotionCategory')}
                    rules={[{ required: true, message: `${i18n.t('required')}` }]}>
                    <Select options={enumToOptions(PROMOTION_TYPE)} onChange={typeChanged} />
                  </Form.Item>
                </Col>
                <Col>
                  <Space size="middle">
                    <Button onClick={() => navigate('/promotion')}>{i18n.t('cancel')}</Button>
                    <Button type="primary" htmlType="submit" loading={loading}>{i18n.t('confirm')}</Button>
                  </Space>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Form.Item name="promotionName" label={i18n.t('promotionName')}
                rules={[{ required: true, message: `${i18n.t('required')}` },
                { type: 'string', max: 100, message: `${i18n.t('promotionActivityNameLimitedTo100Characters')}` }]}>
                <Input className="w-12" placeholder={`${i18n.t('inputData')}`} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Row gutter={[12, 12]} align="middle">
                <Col>
                  <Form.Item name="popupDisplay" className="w-12" label={i18n.t('popupDisplay')}
                    rules={[{ required: true, message: `${i18n.t('required')}` }]}>
                    <Select
                      onChange={e => {
                        setIsPopupValue(e === 0);
                        form.setFieldValue('redirectToPromotionPage', 0);
                      }}
                      placeholder={i18n.t('pleaseSelect')}
                      options={enumToOptions(WHETHER)}
                    />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item name="redirectToPromotionPage" className="w-12" label={i18n.t('redirectToPromotionPage')}
                    rules={[{ required: !isPopupValue, message: `${i18n.t('required')}` }]}>
                    <Select
                      disabled={isPopupValue}
                      placeholder={i18n.t('pleaseSelect')}
                      options={enumToOptions(WHETHER)}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            {/* 週期 */}
            <Col span={24}>
              <Row gutter={[12, 12]}>
                <Col>
                  <Form.Item name="period" className="w-12" label={i18n.t('cycle')} required
                    rules={[{ required: true, message: `${i18n.t('required')}` }]}>
                    <Select
                      placeholder={i18n.t('pleaseSelect')}
                      options={enumToOptions(PROMOTION_CYCLE)}
                    />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item name="oneTimePromotion" className="w-12" label={i18n.t('oneTimePromotion')}
                    rules={[{ required: true, message: `${i18n.t('required')}` }]}>
                    <Select
                      onChange={e => {
                        setIsOneTime(e !== 1);
                        e === 0 &&
                          form.setFieldValue('distributionMethod', 1);
                      }}
                      placeholder={i18n.t('pleaseSelect')}
                      options={enumToOptions(WHETHER)}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            {/* 遊戲大類 / 遊戲商 */}
            <Col span={24}>
              <Row gutter={[12, 12]}>
                <Col>
                  <Form.Item name="CategoryCodes" className="w-12" label={
                    <>
                      {`${i18n.t('applicable')}${i18n.t('gameCategory')}`}<span className="color-03">{`(${i18n.t('selectableMultiple')})`}</span>
                    </>
                  }>
                    <Select
                      mode="multiple"
                      allowClear
                      placeholder={i18n.t('pleaseSelect')}
                      filterOption={selectFuzzyFilter}
                      onChange={setCategoryCodes}
                      options={
                        category?.Data.map((option: any) => ({
                          value: option.Code,
                          label: i18n.t(option.Name)
                        }))
                      }
                    />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item name="ProviderIds" className="w-12" label={
                    <>
                      {`${i18n.t('applicable')}${i18n.t('gameProvider')}`}<span className="color-03">{`(${i18n.t('selectableMultiple')})`}</span>
                    </>
                  }>
                    <Select
                      mode="multiple"
                      allowClear
                      placeholder={i18n.t('pleaseSelect')}
                      filterOption={selectFuzzyFilter}
                      disabled={!categoryCodes || categoryCodes.length < 1}
                      options={
                        providerFilter().map((option: any) => ({
                          value: option.Id,
                          label: `${specialProviderName(option.Name, site.data.SiteName, option.Code)}${i18n.t(option.CategoryCode)}`
                        }))
                      }
                    />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item name="MemberGroupsId" className="w-12" rules={[{ required: true, message: `${i18n.t('required')}` }]} label={
                    <>
                      {i18n.t('applicableMemberGroup')}<span className="color-03">{`(${i18n.t('selectableMultiple')})`}</span>
                    </>
                  }>
                    <MemberGroupSelect mode="multiple" name="MemberGroupsId" form={form} />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row gutter={[12, 12]} align="middle">
                <Col>
                  <Form.Item name="expectedValidBet" label={
                    <Row gutter={5}>
                      <Col>{i18n.t('expectedValidBet')}</Col>
                      <Col>
                        <Tooltip placement="top" title={
                          i18n.t('meetTheSetConditions')
                        }>
                          <InfoCircleFilled />
                        </Tooltip>
                      </Col>
                    </Row>
                  } rules={verify({ required: false, point: 4, max: 999999999 })}>
                    <Input className="w-12" placeholder={`${i18n.t('inputData')}`} />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item name="promotionAmount" label={i18n.t('promotionAmount')} rules={verify({ point: 4, max: 999999999 })}>
                    <Input className="w-12" placeholder={`${i18n.t('inputData')}`} />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item name="rolloverRate" label={i18n.t('rolloverRate')} rules={verify({ point: 0 })}>
                    <Input className="w-12" placeholder={`${i18n.t('inputData')}`} />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row gutter={[12, 12]} align="middle">
                <Col>
                  <Form.Item name="applicationMethod" className="w-12" label={i18n.t('applicationMethod')}
                    rules={[{ required: true, message: `${i18n.t('required')}` }]}>
                    <Select
                      disabled
                      placeholder={i18n.t('pleaseSelect')}
                      options={[
                        { value: 0, label: `${i18n.t('autoApplication')}` },
                        { value: 1, label: `${i18n.t('memberManualApplication')}` },
                      ]}
                    />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item name="distributionMethod" className="w-12" label={i18n.t('distributionMethod')}
                    rules={[{ required: true, message: `${i18n.t('required')}` }]}>
                    <Select
                      disabled={isOneTime}
                      placeholder={i18n.t('pleaseSelect')}
                      options={[
                        { value: 0, label: `${i18n.t('systemDistribution')}` },
                        { value: 1, label: `${i18n.t('manualDispatch')}` },
                      ]}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row gutter={[12, 12]} align="middle">
                <Col>
                  <Form.Item name="status" className="w-12" label={i18n.t('status')}
                    rules={[{ required: true, message: `${i18n.t('required')}` }]}>
                    <Select
                      placeholder={i18n.t('pleaseSelect')}
                      options={enumToOptions(PROMOTION_STATE)}
                    />
                  </Form.Item>
                </Col>
                <Col>
                  <FormInputPosition className="w-12" />
                </Col>
                <Col>
                  <Form.Item name="promotionTime" label={`${i18n.t('promotionStartTime')} - ${i18n.t('promotionEndTime')}`}
                    rules={[{ required: true, message: `${i18n.t('required')}` }]}>
                    <DateSelector form={form} name="promotionTime" date={date} setDate={setDate} />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            {/* 上傳封面圖片 */}
            <Col span={24}>
              <Row>
                <Col span={24}>
                  <Form.Item name="Photo" label={i18n.t('image')} 
                    rules={[{ required: !imageData[0], message: `${i18n.t('uploadImage')}` }]}>
                    <UploadImage
                      name="Photo"
                      url={'/upload/images/public/bonus'}
                      imageData={imageData}
                      setImageData={setImageData}
                      form={form}
                      w={1920}
                      h={560}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            {/* 內文編輯器 */}
            <Col span={24}>
              <Form.Item name="internalContent" label={i18n.t('internalContent')}
                rules={[{ required: true, message: `${i18n.t('required')}` }]}>
                <CKEditorComp data={editorContent} setData={setEditorContent} form={form} fieldName={'internalContent'} image />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Content >
    </div >
  );
};

export default PageMain;