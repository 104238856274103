import { InfoCircleFilled } from '@ant-design/icons';
import { Button, Col, Descriptions, Form, Input, Row, Select, Spin, Tooltip, message } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { UploadFile } from 'antd/lib';
import { useForm } from 'antd/lib/form/Form';
import { LayoutNav, LayoutTabPlatform, UploadImage } from 'components/layout.component';
import { RESPONSE_CODE_SITE_SETTING } from 'constants/response';
import { ENABLE } from 'enum/state';
import useAccount from 'hooks/account.hook';
import useSite, { SettingInfo } from 'hooks/site.hook';
import i18n from 'i18n';
import React, { useEffect, useState } from 'react';
import { $post } from 'services';
import { enumToOptions, timeS2L } from "utils/common";

// 站台設置

const PageMain: React.FC = () => {
  const { permissions: $p } = useAccount();
  const [form] = useForm();
  const [isEditDone, setIsEditDone] = useState(false);
  const [iconData, setIconData] = useState<UploadFile<any>[]>([]);
  const [imageData, setImageData] = useState<UploadFile<any>[]>([]);
  const [welcomeImageForPc, setWelcomeImageForPc] = useState<UploadFile<any>[]>([]);
  const [welcomeImageForMobile, setWelcomeImageForMobile] = useState<UploadFile<any>[]>([]);
  const [imageMobileLogo, setImageMobileLogo] = useState<UploadFile<any>[]>([]);
  const [imageShortCutIcon, setImageShortCutIcon] = useState<UploadFile<any>[]>([]);
  const [settingInfo, setSettingInfo] = useState<SettingInfo>();
  const [serviceSetting, setServiceSetting] = useState({});
  const { data: siteInfo, mutate, isValidating } = useSite();
  useEffect(() => {
    if (siteInfo?.Domain) {
      setSettingInfo(siteInfo);
    }
  }, [siteInfo])

  useEffect(() => {
    if (settingInfo) {
      form.setFieldsValue({
        SiteName: settingInfo.SiteName,
        currency: settingInfo.Currency,
        Status: settingInfo.Status,
        CreateDate: timeS2L(settingInfo.CreateDate),
        Type: settingInfo.Type,
        WhiteList: JSON.parse(settingInfo.WhiteList).join(','),
      })
      setIconData([
        {
          uid: settingInfo.Logo1,
          name: settingInfo.Logo1,
          url: settingInfo.Logo1
        },
      ]);
      setImageData([
        {
          uid: settingInfo.Logo2,
          name: settingInfo.Logo2,
          url: settingInfo.Logo2
        },
      ]);
      setWelcomeImageForPc([
        {
          uid: settingInfo.WelcomePagePC,
          name: settingInfo.WelcomePagePC,
          url: settingInfo.WelcomePagePC
        },
      ]);
      setWelcomeImageForMobile([
        {
          uid: settingInfo.WelcomePageMobile,
          name: settingInfo.WelcomePageMobile,
          url: settingInfo.WelcomePageMobile
        },
      ]);
      setImageMobileLogo([
        {
          uid: settingInfo.MobileLogo,
          name: settingInfo.MobileLogo,
          url: settingInfo.MobileLogo
        },
      ]);
      setImageShortCutIcon([
        {
          uid: settingInfo.ShortCutIcon,
          name: settingInfo.ShortCutIcon,
          url: settingInfo.ShortCutIcon
        },
      ]);
      setServiceSetting(settingInfo?.CustomerServiceSetting);
    }
  }, [settingInfo])

  const onFinish = () => {
    const value = form.getFieldsValue();
    const whiteList = value?.WhiteList ? value.WhiteList.split(',') : [];
    $post({
      url: 'api/sitedata/update',
      send: {
        SiteName: value.SiteName,
        Status: value.Status,
        WhiteList: whiteList[0] === '' ? [] : whiteList,
        CustomerServiceSetting: serviceSetting
      },
      success: () => {
        message.success(i18n.t('operationSuccess'));
        setIsEditDone(false);
        mutate();
      },
      resCode: RESPONSE_CODE_SITE_SETTING
    })
  }

  const onCancel = () => {
    setIsEditDone(false);
    if (settingInfo) {
      form.setFieldsValue({
        SiteName: settingInfo.SiteName,
        currency: settingInfo.Currency,
        Status: settingInfo.Status,
        CreateDate: timeS2L(settingInfo.CreateDate),
        Type: settingInfo.Type,
        WhiteList: JSON.parse(settingInfo.WhiteList).join(',')
      })
      setServiceSetting(settingInfo?.CustomerServiceSetting);
    }
  }

  const onChangeServiceSetting = (activeKey: string, value: string) => {
    setServiceSetting(pre => ({
      ...pre,
      [activeKey]: value
    }));
  };

  return (
    <div id="container">
      <LayoutNav />
      <Content className="ph-2 pt-1">
        <LayoutTabPlatform activeKey="1" />
        <Spin spinning={isValidating}>
          <Form form={form} onFinish={onFinish}>
            <Row align="middle" gutter={[10, 16]}>
              <Col span={24} style={{ display: "flex", justifyContent: "end" }}>
                {
                  isEditDone && <>
                    <Button className="mr-1" onClick={onCancel}>{i18n.t('cancel')}</Button>
                    <Button type="primary" onClick={form.submit}>{i18n.t('confirm')}</Button>
                  </>
                }
                {
                  !isEditDone && $p('00102') &&
                  <Button type="primary" onClick={() => setIsEditDone(!isEditDone)}>{i18n.t('edit')}</Button>
                }
              </Col>
              {/* 資料欄位 */}
              <Col span={24}>
                <Descriptions size="middle" layout="horizontal" bordered
                  labelStyle={{ minWidth: 150, maxWidth: 150 }} contentStyle={{ minWidth: 300, maxWidth: 300 }}>
                  {/* 第一排 */}
                  <Descriptions.Item label={<><span className="require">*</span>{i18n.t('platformName')}</>}>
                    <Form.Item name="SiteName" rules={[{ required: true, message: `${i18n.t('inputData')}${i18n.t('platformName')}` }]}>
                      <Input placeholder={`${i18n.t('inputData')}${i18n.t('platformName')}`}
                        disabled={!$p('99999') || !isEditDone} />
                    </Form.Item>
                  </Descriptions.Item>
                  <Descriptions.Item label={<><span className="require">*</span>{i18n.t('currency')}</>}>
                    <Form.Item name="currency" className="w-full" rules={[{ required: true }]}>
                      <Select disabled />
                    </Form.Item>
                  </Descriptions.Item>
                  <Descriptions.Item label={<><span className="require">*</span>{i18n.t('status')}</>}>
                    <Form.Item name="Status" className="w-full" rules={[{ required: true }]}>
                      <Select
                        disabled
                        options={enumToOptions(ENABLE)}
                      />
                    </Form.Item>
                  </Descriptions.Item>

                  {/* 第二排 */}
                  <Descriptions.Item label={i18n.t('createTime')}>
                    <Form.Item name="CreateDate">
                      <Input disabled />
                    </Form.Item>
                  </Descriptions.Item>
                  <Descriptions.Item label={<><span className="require">*</span>{i18n.t('platformType')}</>}>
                    <Form.Item name="Type" rules={[{ required: true }]}>
                      <Select
                        disabled
                        options={[
                          { value: 0, label: i18n.t('cashVersion') },
                          { value: 1, label: i18n.t('creditVersion') },
                        ]}
                      />
                    </Form.Item>
                  </Descriptions.Item>
                  <Descriptions.Item label=""><></></Descriptions.Item>

                  {/* 第三排 */}
                  <Descriptions.Item label={
                    <Row align="middle" gutter={5}>
                      <Col className="require">*</Col>Favicon
                      <Col>
                        <Tooltip placement="top" className="size-12" title={i18n.t('overwriteOriginalImageOnUpload')}>
                          <InfoCircleFilled />
                        </Tooltip>
                      </Col>
                    </Row>
                  }>
                    <Form.Item name="Photo1" rules={[{ required: !iconData[0], message: `${i18n.t('uploadImage')}` }]}>
                      <UploadImage
                        disabled={isEditDone || !$p('00102')}
                        name="Photo1"
                        url={'/upload/images/public/sitedata/favicon'}
                        preUrl={settingInfo ? settingInfo.Logo1 : ''}
                        imageData={iconData}
                        setImageData={setIconData}
                        w={16}
                        h={16}
                        s={'1MB'}
                        accept=".ico"
                        form={form}
                        otherFunction={mutate}
                      />
                    </Form.Item>
                  </Descriptions.Item>
                  <Descriptions.Item label={
                    <Row align="middle" gutter={5}>
                      <Col className="require">*</Col>{i18n.t('tagLogo')}
                      <Col>
                        <Tooltip placement="top" className="size-12" title={i18n.t('overwriteOriginalImageOnUpload')}>
                          <InfoCircleFilled />
                        </Tooltip>
                      </Col>
                    </Row>
                  }>
                    <Form.Item name="Photo2" rules={[{ required: !imageData[0], message: `${i18n.t('uploadImage')}` }]}>
                      <UploadImage
                        disabled={isEditDone || !$p('00102')}
                        name="Photo2"
                        url={'/upload/images/public/sitedata/logo'}
                        preUrl={settingInfo ? settingInfo.Logo2 : ''}
                        imageData={imageData}
                        setImageData={setImageData}
                        w={342}
                        h={70}
                        s={'1MB'}
                        // fillColor="#1E211D"
                        form={form}
                        otherFunction={mutate}
                      />
                    </Form.Item>
                  </Descriptions.Item>
                  <Descriptions.Item label={i18n.t('systemWhitelist')}>
                    <Form.Item name="WhiteList">
                      <Input.TextArea
                        disabled={!isEditDone}
                        autoSize={{ minRows: 5, maxRows: 5 }}
                        placeholder={`${i18n.t('toSeparate')}`}
                      />
                    </Form.Item>
                  </Descriptions.Item>

                  {/* 第四排 */}
                  <Descriptions.Item label={
                    <Row align="middle" gutter={5}>
                      <Col>{i18n.t('welcomePage')}-{i18n.t('pcVersion')}</Col>
                      <Col>
                        <Tooltip placement="top" className="size-12" title={i18n.t('overwriteOriginalImageOnUpload')}>
                          <InfoCircleFilled />
                        </Tooltip>
                      </Col>
                    </Row>
                  }>
                    <Form.Item name="Photo3">
                      <UploadImage
                        disabled={isEditDone || !$p('00102')}
                        name="Photo3"
                        preUrl={settingInfo ? settingInfo.WelcomePagePC : ''}
                        url={'/upload/images/public/sitedata/welcomepc'}
                        imageData={welcomeImageForPc}
                        setImageData={setWelcomeImageForPc}
                        w={500}
                        h={500}
                        s={'1MB'}
                        form={form}
                      />
                    </Form.Item>
                  </Descriptions.Item>
                  <Descriptions.Item label={
                    <Row align="middle" gutter={5}>
                      <Col>{i18n.t('welcomePage')}-{i18n.t('mobileVersion')}</Col>
                      <Col>
                        <Tooltip placement="top" className="size-12" title={i18n.t('overwriteOriginalImageOnUpload')}>
                          <InfoCircleFilled />
                        </Tooltip>
                      </Col>
                    </Row>
                  }>
                    <Form.Item name="Photo4">
                      <UploadImage
                        disabled={isEditDone || !$p('00102')}
                        name="Photo4"
                        preUrl={settingInfo ? settingInfo.WelcomePageMobile : ''}
                        url={'/upload/images/public/sitedata/welcomemobile'}
                        imageData={welcomeImageForMobile}
                        setImageData={setWelcomeImageForMobile}
                        w={325}
                        h={325}
                        s={'1MB'}
                        form={form}
                      />
                    </Form.Item>
                  </Descriptions.Item>
                  <Descriptions.Item label={
                    <Row align="middle" gutter={5}>
                      <Col>{i18n.t('mobileLogo')}</Col>
                      <Col>
                        <Tooltip placement="top" className="size-12" title={i18n.t('layoutLogo', { layout: 'M004' })}>
                          <InfoCircleFilled />
                        </Tooltip>
                      </Col>
                    </Row>
                  }>
                    <Form.Item name="Photo4">
                      <UploadImage
                        disabled={isEditDone || !$p('00102')}
                        name="Photo4"
                        preUrl={settingInfo?.MobileLogo || ''}
                        url={'/upload/images/public/sitedata/mobilelogo'}
                        imageData={imageMobileLogo}
                        setImageData={setImageMobileLogo}
                        w={256}
                        h={256}
                        s={'1MB'}
                        form={form}
                      />
                    </Form.Item>
                  </Descriptions.Item>

                  {/* 第五排 */}
                  <Descriptions.Item label={<><span className="require">*</span>{i18n.t('desktopIcon')}</>}>
                    <Form.Item name="Photo4">
                      <UploadImage
                        disabled={isEditDone || !$p('00102')}
                        name="Photo4"
                        preUrl={settingInfo?.ShortCutIcon || ''}
                        url={'/upload/images/public/sitedata/shortcuticon'}
                        imageData={imageShortCutIcon}
                        setImageData={setImageShortCutIcon}
                        w={512}
                        h={512}
                        s={'1MB'}
                        form={form}
                      />
                    </Form.Item>
                  </Descriptions.Item>
                  <Descriptions.Item><></></Descriptions.Item>
                  <Descriptions.Item><></></Descriptions.Item>

                  {/* 客服金鑰(已移去客服軟體設定) */}
                  {/* <Descriptions.Item label={i18n.t('customerServiceKey')}>
                    {Object.entries(serviceSetting).map(([key, value]) => (
                      <Input key={key} placeholder={key} value={`${value}`}
                        disabled={!$p('99999') || !isEditDone}
                        onChange={e => onChangeServiceSetting(key, e.target.value)} />
                    ))}
                  </Descriptions.Item> */}
         
                </Descriptions>
              </Col>
            </Row>
          </Form>
        </Spin>
      </Content>
    </div>
  );
};

export default PageMain;