import { Button, Col, Form, Input, Row, Select, Spin } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { ViewMemberWithdrawList, ViewMemberWithdrawOverview } from 'components/finance.component';
import { DatePickerCol, Export, LayoutNav, LayoutPagination, LayoutUpdateControl } from 'components/layout.component';
import { ExcelColumns } from 'constants/excel';
import dayjs from 'dayjs';
import { DATE_RANGE_LIMIT } from 'enum/date';
import { SEARCH_DATE_TYPE, WITHDRAW_STATE } from 'enum/state';
import useAccount from 'hooks/account.hook';
import i18n from 'i18n';
import React, { useState } from 'react';
import { $get } from 'services';
import { enumToOptions, timeL2S, tzShift } from "utils/common";

// 財務：提款查詢

const PageMain: React.FC = () => {
  const { permissions: $p } = useAccount();

  const [form] = Form.useForm();
  const [reset, setReset] = useState(false);
  const [page, setPage] = useState<number[]>([1, 10]);
  const [startDate, setStartDate] = useState('CreateStartDate');
  const [EndDate, setEndDate] = useState('CreateEndDate');
  const [date, setDate] = useState([
    tzShift(dayjs().startOf('day'), 'YYYY-MM-DD HH:mm:ss'),
    tzShift(dayjs().endOf('day'), 'YYYY-MM-DD HH:mm:ss')
  ]);
  const [params, setParams] = useState<any>({
    CreateStartDate: timeL2S(date[0]),
    CreateEndDate: timeL2S(date[1]),
  });
  const { data: withdrawList, isValidating, mutate } = $get({
    url: 'api/withdrawticket/member/list',
    params: {
      ...params,
      PageIndex: page[0],
      PageSize: page[1],
    }
  })

  const defaultDate = SEARCH_DATE_TYPE.createTime;
  const handleDateType = (type: number) => {
    if (type === defaultDate) {
      setStartDate('CreateStartDate');
      setEndDate('CreateEndDate')
    } else {
      setStartDate('AuditedStartDate');
      setEndDate('AuditedEndDate')
    }
  }

  const onFinish = (formData: any) => {
    setPage([1, page[1]]);
    if (date) {
      setParams({
        Code: formData.code,
        Account: formData.account,
        UpMemberAccount: formData.upMemberAccount,
        AGAccount: formData.agAccount,
        Status: formData.status,
        [startDate]: timeL2S(date[0]),
        [EndDate]: timeL2S(date[1]),
      })
    }
    mutate();
  };

  const onClear = () => {
    setPage([1, 10]);
    setReset(!reset);
    form.resetFields();
    setStartDate('CreateStartDate');
    setEndDate('CreateEndDate');
  }

  return (
    <div id="container">
      <LayoutNav />
      <Content className="ph-2 pt-1">
        <Form form={form} onFinish={onFinish} initialValues={{
          TimeMode: defaultDate
        }}>
          <Row align="middle" gutter={[12, 12]}>
            <Col className="w-12">
              <Form.Item name="code">
                <Input placeholder={`${i18n.t('order')}`} />
              </Form.Item>
            </Col>
            <Col className="w-12">
              <Form.Item name="account">
                <Input placeholder={`${i18n.t('memberAccount')}`} />
              </Form.Item>
            </Col>
            <Col className="w-12">
              <Form.Item name="status">
                <Select
                  className="w-full"
                  placeholder={i18n.t('status')}
                  options={[
                    { value: '', label: `${i18n.t('ALL')}` },
                    ...enumToOptions(WITHDRAW_STATE)
                  ]}
                />
              </Form.Item>
            </Col>
            <Col className="w-12">
              <Form.Item name="upMemberAccount">
                <Input placeholder={`${i18n.t('recommendedMembers')}`} />
              </Form.Item>
            </Col>
            <Col className="w-12">
              <Form.Item name="agAccount">
                <Input placeholder={`${i18n.t('agentAccount')}`} />
              </Form.Item>
            </Col>
            <Col span="24">
              <Row justify="space-between">
                <Col>
                  <Row align="middle" gutter={12}>
                    <Col className="w-12">
                      <Form.Item name="TimeMode">
                        <Select
                          className="w-full"
                          onChange={handleDateType}
                          options={enumToOptions(SEARCH_DATE_TYPE)}
                        />
                      </Form.Item>
                    </Col>
                    <DatePickerCol width={180} date={date} setDate={setDate} initDate={reset}
                      rangeLimit={DATE_RANGE_LIMIT.past31Days} />
                    <Col>
                      <Button type="primary" htmlType="submit">{i18n.t('search')}</Button>
                    </Col>
                    <Col>
                      <Button onClick={onClear}>{i18n.t('clear')}</Button>
                    </Col>
                  </Row>
                </Col>
                <Col>
                  <LayoutUpdateControl callback={mutate} />
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
        <Row className="mt-1">
          <Col span={24}>
            <Spin spinning={isValidating}>
              <ViewMemberWithdrawOverview data={withdrawList} />
            </Spin>
          </Col>
        </Row>
        {$p('20110') && <Row className="mt-1">
          <Col>
            <Export url={'/withdrawticket/member/list/export'} param={{
              ...params, PageSize: withdrawList && withdrawList.TotalRecord, PageIndex: 1
            }} sheetName={i18n.t('withdrawalQuery')} columns={ExcelColumns.MemberWithdraw} />
          </Col>
        </Row>}
        <Row className="mt-1">
          <Col span={24}>
            <Spin spinning={isValidating}>
              <ViewMemberWithdrawList data={withdrawList} mutate={mutate} />
              <LayoutPagination total={withdrawList ? withdrawList.TotalRecord : 0} setPage={setPage} />
            </Spin>
          </Col>
        </Row>
      </Content>
    </div >
  );
};

export default PageMain;