import { CloseCircleOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, Row, Select, Table, Tabs, TabsProps } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { Content } from 'antd/es/layout/layout';
import { DatePickerCol, LayoutNav, LayoutPagination } from 'components/layout.component';
import { ReportPromotionList } from 'components/report.component';
import dayjs from 'dayjs';
import { DATE_RANGE_LIMIT } from 'enum/date';
import i18n from 'i18n';
import React, { useEffect, useState } from 'react';
import { $get } from 'services';
import { toFormatNumber as $f, agentLevelWord, enumToOptions, timeL2S, tzShift } from "utils/common";

// 代理優惠統計
// NOTE: 同步AG成本報表

enum TAB {
  代理 = '1',
  會員 = '2',
  優惠 = '3'
}

enum BONUS_TYPE {
  dailyCheck = 1,
  referralReward = 3,
  promotionAdjustment = 4,
  upgradeBonus = 5,
  weeklyBonus = 6,
  amountMonthly = 7,
  otherPromotions = 8,
  storePromotions = 10,
  redEnvelopePromotions = 12,
  rebate = 9,
  jackpot = 11,
  signupBonus = 14,
  couponOffer = 13,
  personalRebate = 15,
  rebateForDirectDownlineValidMembers = 16,
}

const PageMain: React.FC = () => {
  const [activeKey, setActiveKey] = useState<string>(TAB.代理);
  const [form] = useForm();
  const [formData,setFormData]= useState<any>({})

  const items: TabsProps['items'] = [
    {
      key: TAB.代理,
      label: <div className="w-5 text-center">{i18n.t('agent')}</div>
    },
    {
      key: TAB.會員,
      label: <div className="w-5 text-center">{i18n.t('member')}</div>
    },
    {
      key: TAB.優惠,
      label: <div className="w-5 text-center">{i18n.t('discount')}</div>
    },
  ];

  const onTabClick = (key: string) => {
    setParams({
      ...params,
      PageIndex: 1
    });
    setActiveKey(key)
    updateTotal(key);
  }

  const updateTotal = (key: any) => {
    switch (key) {
      case TAB.代理:
        setPromotionData({
          key: 1,
          count: _saList?.TotalCount,
          amount: _saList?.TotalAmount
        })
        break;
      case TAB.會員:
        setPromotionData({
          key: 1,
          count: _memberList?.TotalCount,
          amount: _memberList?.TotalAmount,
        })
        break;
      case TAB.優惠:
        setPromotionData({
          key: 1,
          count: _categoryList?.TotalCount,
          amount: _categoryList?.TotalAmount,
        })
        break;
    }
  }

  const searchAgent = (agent: any, tab: any) => {
    setActiveKey(tab);
    setParams({
      ...params,
      PageIndex: 1,
      AgentAccount: agent.Account,
      MemberAccount: formData.MemberAccount,
      BonusId: formData.BonusId,
    })
    form.setFieldsValue({
      AgentAccount: agent.Account,
      MemberAccount: formData.MemberAccount,
      BonusId: formData.BonusId,
    });
    setFormData((pre:any)=>{return {...pre,AgentAccount:agent.Account}})
  };

  const searchMember = (member: any, tab: any) => {
    setActiveKey(tab);
    setParams({
      ...params,
      PageIndex: 1,
      AgentAccount: formData.AgentAccount,
      MemberAccount: member.Account,
      BonusId: formData.BonusId,
    })
    form.setFieldsValue({
      AgentAccount: formData.AgentAccount,
      MemberAccount: member.Account,
      BonusId: formData.BonusId,
    });
    setFormData((pre:any)=>{return{...pre,MemberAccount:member.Account}})
  };

  const [promotionData, setPromotionData] = useState({
    key: 1,
    amount: 0,
    count: 0,
  });

  const [date, setDate] = useState([
    tzShift(dayjs().startOf('day'), 'YYYY-MM-DD HH:mm:ss'),
    tzShift(dayjs().endOf('day'), 'YYYY-MM-DD HH:mm:ss')
  ]);
  const [reset, setReset] = useState<boolean>(false);
  const [resetAgentList, setResetAgentList] = useState<boolean>(false);
  const [page, setPage] = useState([1, 10]);
  const [params, setParams] = useState<any>({
    StartDate: timeL2S(date[0]),
    EndDate: timeL2S(date[1]),
    PageIndex: page[0],
    PageSize: page[1],
  });
  const { data: _saList, mutate: mutateSa, isValidating: saValidating } = $get({
    url: 'api/report/bonusstat/ag/list',
    params: {
      ...params,
      PageIndex: page[0],
      PageSize: page[1]
    }
  });
  const { data: _memberList, mutate: mutateMember, isValidating: memberValidating } = $get({
    url: 'api/report/bonusstat/member/list',
    params: {
      ...params,
      PageIndex: page[0],
      PageSize: page[1]
    }
  });
  const { data: _categoryList, mutate: mutateCategory, isValidating: categoryValidating } = $get({
    url: 'api/report/bonusstat/category/list',
    params: {
      ...params,
      PageIndex: page[0],
      PageSize: page[1]
    }
  });
  useEffect(() => {
    updateTotal(activeKey)
  }, [_saList, _memberList, _categoryList]);

  useEffect(() => {
    setParams((pre:any)=>{
      return {...pre,
        PageIndex: page[0]}
    })
  }, [page]);

  const search = (formData: any) => {
    setParams({
      StartDate: timeL2S(date[0]),
      EndDate: timeL2S(date[1]),
      PageIndex: 1,
      PageSize: params.PageSize,
      AgentAccount: formData.AgentAccount,
      MemberAccount: formData.MemberAccount,
      BonusId: formData.BonusId,
    })
    mutateSa()
    mutateMember()
    mutateCategory()
    setResetAgentList(!resetAgentList)
    setFormData(formData)
  }

  const onClear = () => {
    form.resetFields();
    setReset(!reset);
    setParams({
      ...params,
      PageSize: 10,
      PageIndex: 1,
    })
  }
  const onClearAndToAgent = () => {
    form.setFieldsValue({
      AgentAccount: null,
      MemberAccount: formData.MemberAccount,
      BonusId: formData.BonusId
    });
    setParams({
      ...params,
      PageSize: 10,
      PageIndex: 1,
      AgentAccount:"",
    })
    mutateSa()
    mutateMember()
    mutateCategory()
    setActiveKey((TAB.代理))
  }

  const columns: any = [
    {
      dataIndex: 'Account',
      title: i18n.t('agentAccount'),
      width: 300,
      render: (val: any, { Account, Level }: any) => {
        const word = agentLevelWord(Level);
        return `${word}／${Account}`
      }
    },
    {
      dataIndex: 'up_count',
      title: i18n.t('teamPromotionCount'),
      align: 'right',
      width: 300,
      render: (val: any) => $f(val, 0)
    },
    {
      dataIndex: 'up_amount',
      title: i18n.t('teamPromotionAmount'),
      align: 'right',
      width: 300,
      render: (val: any) => $f(val)
    },
    {
      dataIndex: 'bonus_count',
      title: i18n.t('promotionCount'),
      align: 'right',
      width: 300,
      render: (val: any) => $f(val, 0)
    },
    {
      dataIndex: 'bonus_amount',
      title: i18n.t('promotionAmount'),
      align: 'right',
      width: 300,
      render: (val: any) => $f(val)
    },
    {
      title: i18n.t('operation'),
      width: 200,
      render: (val: any, record: any) => (
        <>
          <Button className="size-12" type="link" onClick={() => searchAgent(record, TAB.會員)}>
            {i18n.t('viewMember')}
          </Button>
          <Button className="size-12" type="link" onClick={() => searchAgent(record, TAB.優惠)}>
            {i18n.t('viewPromotion')}
          </Button>
        </>
      )
    },
  ]

  return (
    <div id="container">
      <LayoutNav />
      <Content className="p-2">
        {/* 搜尋列 */}
        <Form form={form} onFinish={search}>
          <Row align="middle" gutter={[12, 12]}>
            <Col className='w-12'>
              <Form.Item name="BonusId">
                <Select
                  placeholder={i18n.t("promotionCategory")}
                  options={[
                    { value: '', label: i18n.t('ALL') },
                    ...enumToOptions(BONUS_TYPE)
                  ]}
                />
              </Form.Item>
            </Col>
            <Col className='w-11'>
              <Form.Item name="AgentAccount">
                <Input placeholder={`${i18n.t('agentAccount')}`} addonAfter={<CloseCircleOutlined onClick={onClearAndToAgent}/>}/>
              </Form.Item>
            </Col>
            <Col className='w-11'>
              <Form.Item name="MemberAccount">
                <Input placeholder={`${i18n.t('memberAccount')}`} />
              </Form.Item>
            </Col>
            <DatePickerCol width={190} date={date} setDate={setDate} initDate={reset} rangeLimit={DATE_RANGE_LIMIT.past31Days} />
            <Col>
              <Button type="primary" htmlType="submit" loading={saValidating}>{i18n.t('search')}</Button>
            </Col>
            <Col>
              <Button onClick={onClear}>{i18n.t('clear')}</Button>
            </Col>
          </Row>
        </Form>
        {/* 數據總覽 */}
        <Row className="mt-1" gutter={[12, 12]}>
          <Col span={24}>
            <Table
              size="small"
              dataSource={[promotionData]}
              columns={[
                {
                  dataIndex: 'count',
                  title: i18n.t('totalPromotionCount'),
                  align: 'right',
                  render: (val) => $f(val, 0)
                },
                {
                  dataIndex: 'amount',
                  title: i18n.t('totalPromotionAmount'),
                  align: 'right',
                  render: (val) => $f(val)
                },
              ]}
              pagination={false}
            />
          </Col>
        </Row>
        <Tabs className="mt-1" activeKey={activeKey} items={items} onTabClick={onTabClick} />
        {/* 代理 */}
        {
          activeKey === TAB.代理 &&
          <Row className="mt-1" gutter={[10, 10]}>
            <Col span={24}>
              <ReportPromotionList agentSaData={_saList} isValidating={saValidating}
                columns={columns} params={params} page={page} setPage={setPage} setParams={setParams} reset={resetAgentList} />
            </Col>
          </Row>
        }
        {/* 會員 */}
        {
          activeKey === TAB.會員 &&
          <Row className="mt-1" gutter={[10, 10]}>
            <Col span={24}>
              <Table
                size="middle"
                loading={memberValidating}
                dataSource={_memberList?.Data.map((item: any) => ({ key: item.id, ...item }))}
                columns={[
                  {
                    dataIndex: 'Account',
                    title: i18n.t('memberAccount'),
                    width: 300,
                  },
                  {
                    dataIndex: 'up_count',
                    title: i18n.t('teamPromotionCount'),
                    align: 'right',
                    width: 300,
                    render: (val) => $f(val, 0)
                  },
                  {
                    dataIndex: 'up_amount',
                    title: i18n.t('teamPromotionAmount'),
                    align: 'right',
                    width: 300,
                    render: (val) => $f(val)
                  },
                  {
                    dataIndex: 'bonus_count',
                    title: i18n.t('promotionCount'),
                    align: 'right',
                    width: 300,
                    render: (val) => $f(val, 0)
                  },
                  {
                    dataIndex: 'bonus_amount',
                    title: i18n.t('promotionAmount'),
                    align: 'right',
                    width: 300,
                    render: (val) => $f(val)
                  },
                  {
                    title: i18n.t('operation'),
                    width: 200,
                    render: (val, record: any) => (
                      <Button className="size-12" type="link" onClick={() => searchMember(record, TAB.優惠)}>
                        {i18n.t('viewPromotion')}
                      </Button>
                    )
                  },
                ]}
                pagination={false}
              />
              <LayoutPagination total={_memberList?.TotalRecord} page={page} setPage={setPage} />
            </Col>
          </Row>
        }
        {/* 優惠 */}
        {
          activeKey === TAB.優惠 &&
          <Row className="mt-1" gutter={[10, 10]}>
            <Col span={24}>
              <Table
                size="middle"
                loading={categoryValidating}
                dataSource={_categoryList?.Data.map((item: any) => ({ key: item.bonus_name, ...item }))}
                columns={[
                  {
                    dataIndex: 'bonus_name',
                    title: i18n.t('promotionName'),
                    render: (val) => i18n.t(val)
                  },
                  {
                    dataIndex: 'bonus_count',
                    title: i18n.t('promotionCount'),
                    align: 'right',
                    render: (val) => $f(val, 0)
                  },
                  {
                    dataIndex: 'bonus_amount',
                    title: i18n.t('promotionAmount'),
                    align: 'right',
                    render: (val) => $f(val)
                  },
                ]}
                pagination={false}
              />
              <LayoutPagination total={_categoryList?.TotalRecord} page={page} setPage={setPage} />
            </Col>
          </Row>
        }
      </Content>
    </div>
  );
};

export default PageMain;