import { InfoCircleFilled } from "@ant-design/icons"
import { Button, Col, Form, Row, Tooltip } from "antd"
import { useForm } from "antd/es/form/Form"
import { Select } from "antd/lib"
import { Content } from "antd/lib/layout/layout"
import { DatePickerCol, Export, LayoutNav } from "components/layout.component"
import { GameCategorySelect } from "components/member.component"
import { ViewSettlementList } from "components/report.component"
import { ExcelColumns } from "constants/excel"
import dayjs from "dayjs"
import { DATE_RANGE_LIMIT, DATE_TYPE } from "enum/date"
import useAccount from "hooks/account.hook"
import useSite from "hooks/site.hook"
import i18n from "i18n"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { $get } from "services"
import { numColor03, selectFuzzyFilter, specialProviderName, timeL2S, toFormatNumber, tzShift } from "utils/common"

// 交收報表

const PageMain = () => {
  const navigate = useNavigate();

  const { data: site } = useSite();
  const { permissions: $p } = useAccount();

  const [form] = useForm();
  const [reset, setReset] = useState<boolean>(false);
  const [date, setDate] = useState([
    tzShift(dayjs().add(-1, 'M').startOf('month'), 'YYYY-MM-DD HH:mm:ss'),
    tzShift(dayjs().add(-1, 'M').endOf('month'), 'YYYY-MM-DD HH:mm:ss'),
    DATE_TYPE.lastMonth
  ]);
  const [params, setParams] = useState<any>({
    StartTime: timeL2S(date[0] as string),
    EndTime: timeL2S(date[1] as string)
  });
  const { data: provider } = $get({ url: 'api/provider/code/list' });
  const { data: settingList, isValidating, mutate } = $get({
    url: 'api/providerreceivable/report/list',
    params
  })
  const [list, setList] = useState<any>([]);
  useEffect(() => {
    if (settingList && settingList.Data) {
      const ary: any = []
      const apiList = JSON.parse(JSON.stringify(settingList.Data))
      apiList.forEach((record: any) => {
        record.Detail.forEach((item: any, i: number) => {
          ary.push(item)
        });
      });
      setList(ary)
    }
  }, [settingList])

  useEffect(() => {
    if (provider) {
      form.setFieldsValue({
        provider: null,
        category: null,
      })
    }
  }, [provider])

  const onFinish = (value: {
    provider: string;
    category: number;
  }) => {
    setParams({
      ...params,
      ProviderCode: value.provider,
      CategoryId: value.category,
      StartTime: timeL2S(date[0] as string),
      EndTime: timeL2S(date[1] as string)
    })
    mutate();
  }

  const onClear = () => {
    setReset(!reset);
    form.resetFields();
  }

  return (
    <div id="container">
      <LayoutNav />
      <Content>
        <Form form={form} onFinish={onFinish} initialValues={{
          provider: null,
          category: null,
        }}>
          <Row gutter={[12, 12]}>
            <Col span={24}>
              <Row align="middle" gutter={[12, 12]}>
                <Col>
                  <Form.Item name="provider">
                    <Select
                      showSearch
                      filterOption={selectFuzzyFilter}
                      className="w-12"
                      placeholder={i18n.t('gameProvider')}
                      options={
                        provider ? [
                          { value: '', label: i18n.t('ALL') },
                          ...provider?.Data.map((item: ProviderType) => ({
                            value: item.ProviderCode,
                            label: specialProviderName(item.ProviderName, site.SiteName, item.ProviderCode)
                          }))
                        ] : []
                      }
                    />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item name="category" className="w-12">
                    <GameCategorySelect name="category" form={form} code={false} />
                  </Form.Item>
                </Col>
                <DatePickerCol width={200} date={date} setDate={setDate} initDate={reset} format={'YYYY-MM-DD HH:mm:ss'}
                  defaultDateType={DATE_TYPE.lastMonth} ignoreDateType={[DATE_TYPE.lastWeek, DATE_TYPE.thisWeek, DATE_TYPE.today, DATE_TYPE.yesterday]}
                  components={
                    <Col>
                      <Tooltip placement="top" className="size-12" title={i18n.t('settlementTime')}>
                        <InfoCircleFilled />
                      </Tooltip>
                    </Col>
                  } />
                <Col>
                  <Button type="primary" htmlType="submit">{i18n.t('search')}</Button>
                </Col>
                <Col>
                  <Button onClick={onClear}>{i18n.t('clear')}</Button>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row gutter={[12, 12]}>
                {
                  $p('99999') &&
                  <Col>
                    <Button type="primary" onClick={() => navigate('/report/settlement/setting')}>
                      {i18n.t('setting')}
                    </Button>
                  </Col>
                }
                {
                  $p('60902') &&
                  <Col>
                    <Export url={'/providerreceivable/report/list/export'} param={params}
                      sheetName={i18n.t('settlementReport')} columns={ExcelColumns.ReportSettlement} externalData={list} />
                  </Col>
                }
              </Row>
            </Col>
            <Col span={24}>
              <ViewSettlementList loading={isValidating} data={settingList} reset={reset} columns={[
                {
                  dataIndex: 'ProviderName',
                  title: i18n.t('gameProvider'),
                  width: 150,
                  render: (val: string, { ProviderCode }: any) => specialProviderName(val, site.SiteName, ProviderCode)
                },
                {
                  dataIndex: 'BetAmount',
                  title: i18n.t('totalBetAmount'),
                  align: 'right',
                  width: 300,
                  render: (val: any) => toFormatNumber(val)
                },
                {
                  dataIndex: 'ValidBetAmount',
                  title: i18n.t('totalValidBets'),
                  align: 'right',
                  width: 150,
                  render: (val: any) => toFormatNumber(val)
                },
                {
                  dataIndex: 'WinLossAmount',
                  title: i18n.t('totalGamePnL'),
                  align: 'right',
                  width: 150,
                  render: (val: any) => <div className={numColor03(val)}>{toFormatNumber(val)}</div>
                },
                {
                  dataIndex: 'ReceivableAmount',
                  title: i18n.t('totalSettlementAmount'),
                  align: 'right',
                  width: 150,
                  render: (val: any) => <div className={numColor03(val)}>{toFormatNumber(val)}</div>
                },
                {
                  dataIndex: 'BonusAmount',
                  title: i18n.t('totalDiscountAmount'),
                  align: 'right',
                  width: 150,
                  render: (val: any) => toFormatNumber(val)
                },
                {
                  dataIndex: 'DonateAmount',
                  title: i18n.t('totalDonateAmount'),
                  align: 'right',
                  width: 150,
                  render: (val: any) => toFormatNumber(val)
                },
              ]} />
            </Col>
          </Row>
        </Form>
      </Content>
    </div >
  )
}

export default PageMain