import { Button, Col, Form, Input, Row, Select, Space, Spin } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { useForm } from 'antd/lib/form/Form';
import { PopupAddAdjust, ViewAdjustList, ViewAdjustOverview } from 'components/adjust.component';
import { DatePickerCol, Export, LayoutNav, LayoutPagination } from 'components/layout.component';
import { ExcelColumns } from 'constants/excel';
import dayjs from 'dayjs';
import { DATE_RANGE_LIMIT } from 'enum/date';
import { ADJUST_TYPE, AGENT_ADJUST_STATE, SEARCH_DATE_TYPE } from 'enum/state';
import useAccount from 'hooks/account.hook';
import i18n from 'i18n';
import React, { useState } from 'react';
import { $get } from 'services';
import { enumToOptions, tzShift, timeL2S } from "utils/common";

// 財務調帳查詢

const PageMain: React.FC = () => {
  const { permissions: $p } = useAccount();
  const [page, setPage] = useState<number[]>([1, 10]);
  const [reset, setReset] = useState<boolean>(false);
  const [isModalOpenAdd, setIsModalOpenAdd] = useState(false);
  const [form] = useForm();
  const [date, setDate] = useState([
    tzShift(dayjs().startOf('day'), 'YYYY-MM-DD HH:mm:ss'),
    tzShift(dayjs().endOf('day'), 'YYYY-MM-DD HH:mm:ss')
  ]);

  const defaultDate = SEARCH_DATE_TYPE.createTime;
  const [memberListParams, setMemberListParams] = useState<any>({
    CreateDateStartTime: timeL2S(date[0]),
    CreateDateEndTime: timeL2S(date[1]),
  });

  const { data: adjustMemberList, mutate, isValidating } = $get({
    url: 'api/adjustticket/member/list',
    params: {
      ...memberListParams,
      PageIndex: page[0],
      PageSize: page[1],
    }
  })

  // 搜尋
  const search = (formData: any) => {
    const timeMode = form.getFieldValue('TimeMode') || defaultDate;
    const type = timeMode === defaultDate ? 'Create' : 'Audited';
    setPage([1, page[1]]);
    setMemberListParams({
      Code: formData.Code,
      Account: formData.Account,
      Status: formData.Status,
      Type: formData.Type,
      UPMemberAccount: formData.UPMemberAccount,
      AGAccount: formData.AGAccount,
      [`${type}DateStartTime`]: timeL2S(date[0]),
      [`${type}DateEndTime`]: timeL2S(date[1])
    })

    mutate();
  }

  const onClear = () => {
    form.resetFields();
    setReset(!reset);
    setPage([1, 10]);
  }

  return (
    <div id="container">
      <LayoutNav />
      <Content className="ph-2 pt-1">
        <Form form={form} onFinish={search} initialValues={{
          TimeMode: defaultDate,
        }}>
          {/* 搜尋 */}
          <Row align="middle" gutter={[12, 12]}>
            <Col className="w-12">
              <Form.Item name="Code">
                <Input placeholder={`${i18n.t('order')}`} />
              </Form.Item>
            </Col>
            <Col className="w-12">
              <Form.Item name="Account">
                <Input placeholder={`${i18n.t('memberAccount')}`} />
              </Form.Item>
            </Col>
            <Col className="w-12">
              <Form.Item name="Status">
                <Select
                  className="w-full"
                  placeholder={i18n.t('status')}
                  options={[
                    { value: '', label: `${i18n.t('ALL')}` },
                    ...enumToOptions(AGENT_ADJUST_STATE, [AGENT_ADJUST_STATE.fail])
                  ]}
                />
              </Form.Item>
            </Col>
            <Col className="w-12">
              <Form.Item name="Type">
                <Select
                  className="w-full"
                  placeholder={i18n.t('transactionType')}
                  options={[
                    { value: '', label: `${i18n.t('ALL')}` },
                    ...enumToOptions(ADJUST_TYPE)
                  ]}
                />
              </Form.Item>
            </Col>
            <Col className="w-12">
              <Form.Item name="UPMemberAccount">
                <Input placeholder={`${i18n.t('recommendedMembers')}`} />
              </Form.Item>
            </Col>
            <Col className="w-12">
              <Form.Item name="AGAccount">
                <Input placeholder={`${i18n.t('agentAccount')}`} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[12, 12]} className="mt-1">
            <Col className="w-12">
              <Form.Item name="TimeMode">
                <Select
                  className="w-full"
                  placeholder={i18n.t('pleaseSelect')}
                  options={enumToOptions(SEARCH_DATE_TYPE)}
                />
              </Form.Item>
            </Col>
            <DatePickerCol width={180} date={date} setDate={setDate} initDate={reset} rangeLimit={DATE_RANGE_LIMIT.past31Days} />
            <Col>
              <Button type="primary" htmlType="submit" loading={isValidating}>{i18n.t('search')}</Button>
            </Col>
            <Col>
              <Button onClick={onClear}>{i18n.t('clear')}</Button>
            </Col>
          </Row>
          <Row gutter={[12, 12]} className="mt-1">
            <Col span={24}>
              <Spin spinning={isValidating}>
                <ViewAdjustOverview data={adjustMemberList} />
              </Spin>
            </Col>
          </Row>
          <Row gutter={[12, 12]} className="mt-1" justify="space-between">
            <Col>
              <Space>
                {
                  $p('20303') &&
                  <Button type="primary" onClick={() => setIsModalOpenAdd(true)}>{i18n.t('add')}</Button>
                }
                {
                  $p('20306') &&
                  <Export url={'/adjustticket/member/list/export'} param={{
                    ...memberListParams, PageSize: adjustMemberList && adjustMemberList.TotalRecord, PageIndex: 1
                  }} sheetName={i18n.t('agentWithdrawalQuery')} columns={ExcelColumns.AdjustList} />
                }
              </Space>
            </Col>
          </Row>
        </Form>
        <Spin spinning={isValidating}>
          <ViewAdjustList data={adjustMemberList} mutate={mutate} />
          <LayoutPagination total={adjustMemberList && adjustMemberList.TotalRecord} setPage={setPage} page={page} />
        </Spin>
        <PopupAddAdjust isOpen={isModalOpenAdd} close={() => setIsModalOpenAdd(false)} mutate={mutate} />
      </Content>
    </div>
  );
};

export default PageMain;