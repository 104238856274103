import { Col, Row, Switch, Table, message, Tabs, Checkbox } from "antd";
import { Content } from 'antd/es/layout/layout';
import { LayoutNav } from 'components/layout.component';
import { RESPONSE_CODE_FRONT } from 'constants/response';
import i18n from 'i18n';
import React, { useState, useEffect} from "react";
import { $get, $post } from 'services';
import { timeS2L } from 'utils/common';
import useSite from "../../../hooks/site.hook";
import useAccount from "hooks/account.hook";

// 前台頁面 > 彈窗設定

export const FRON_POP_ITEM_CODE: any = {
  AGENT_FIRST_VERIFY_PASSWORD_SETTING: "agentInitialVerification",
  WELCOME_PAGE_POP_UP_SETTING: "welcomePopupWindow",
  RATIONAL_GAME_SETTING: "responsibleGamblingReminder",
  SERVICE_TERMS_SETTING: "termsServiceSettings"
}

const FRON_POP_DESC_CODE: any = {
  AGENT_FIRST_VERIFY_PASSWORD_SETTING: "needsVerifyPhoneAndResetPassword",
  WELCOME_PAGE_POP_UP_SETTING: "welcomePopupPageDisplayed",
  RATIONAL_GAME_SETTING: "needToResponsibleGamblingDisplayed",
  SERVICE_TERMS_SETTING: "needToTermsServiceDisplayed"
}

// 前台頁面 > 前台頁面
export const FRON_FRONT_DESK_ITEM_CODE: any = {
  MEMBER_KYC_PHOTO_REQUIRED: "memberRealNameVerificationDocumentUpload",
  WITHDRAW_BANK_METHOD_PHOTO_REQUIRED: "uploadIdentificationForWithdrawingBankCard",
  MEMBER_LOGIN_CAPTCHA_REQUIRED: "loginGraphicVerificationCoded",
  CUSTOMER_SERVICE_CHATROOM_SWITCH: "chatRoom",
  MEMBER_ACCOUNT_DETAILS_OPTIONS: "accountDetailsOptions"
}

// 說明
const FRON_FRONT_DESK_DESC_CODE: any = {
  MEMBER_KYC_PHOTO_REQUIRED: "requiredWhenEnabledOptionalWhenDisabled",
  WITHDRAW_BANK_METHOD_PHOTO_REQUIRED: "requiredWhenEnabledOptionalWhenDisabled",
  MEMBER_LOGIN_CAPTCHA_REQUIRED: "requiredWhenEnabledOptionalWhenDisabled",
  CUSTOMER_SERVICE_CHATROOM_SWITCH: "turnonEnableTurnoffDisable",
  MEMBER_ACCOUNT_DETAILS_OPTIONS: "memberFrontEndTransactionInquiryItems"
}

const FRON_FRONT_DESK_ACCOUNT_DETAILS_CODE: any = {
  Deposit: "depositing",
  Withdraw: "withdrawal",
  AdjustMinus: "deductBalance",
  AdjustAdd: "addBalance",
  AgentAdjustAdd: "transferIn",
  Donate: "donate",
  PromoBet: "providerPromotions"
}

enum TAB {
  彈窗設定 = '1',
  前台設定 = '2',
}

const PageMain: React.FC = () => {
  const { init, permissions: $p } = useAccount();
  const { isCashVersion: $sc } = useSite();

  const [activeKey, setActiveKey] = useState<string>("");
  const [tabsItems, setTabsItems] = useState([]);
  // 帳戶明細選項
  const [memberAccountDetailsOptions, setMemberAccountDetailsOptions] = useState({});

  useEffect(() => {
    if (init) {
      const ary: any = [];
      if ($p('50301')) ary.push({
        key: TAB.彈窗設定,
        label: <div className="text-center">{i18n.t('popupSetting')}</div>
      })
      if ($p('50501')) ary.push( {
        key: TAB.前台設定,
        label: <div className="text-center">{i18n.t('frontDeskSettings')}</div>
      })
      setTabsItems(ary);
      setActiveKey(ary[0]?.key);
    }
  }, [init]);

  // 彈窗設定
  const { data: PopupList, isValidating, mutate } = $get({ 
    url: 'api/fronpopsetting/list',
    allow: activeKey === TAB.彈窗設定 
  });

  const onEditPopup = (id: number, state: boolean) => {
    $post({
      url: 'api/fronpopsetting/update',
      send: {
        Id: id,
        IsPopUp: state ? 1 : 0
      },
      success: () => {
        message.success(i18n.t('operationSuccess'));
        mutate();
      },
      resCode: RESPONSE_CODE_FRONT
    })
  }


  // 前台設定
  const { data: FrontDeskList, isValidating: FrontDeskIsValidating, mutate: FrontDeskMutate } = $get({ 
    url: 'api/systemsetting/front/list',
    allow: activeKey === TAB.前台設定
  });

  useEffect(() => {
    if (FrontDeskList?.Data) {
      const frontItem = FrontDeskList.Data.find((item: any) => item.KeyId === 'MEMBER_ACCOUNT_DETAILS_OPTIONS')
      setMemberAccountDetailsOptions(JSON.parse(frontItem?.Code || '{}'))
    }
  }, [FrontDeskList]);

  const onEditFrontDesk = (id: number | string, state: any) => {
    $post({
      url: 'api/systemsetting/front/update',
      send: {
        KeyId: id,
        Code: id === 'MEMBER_ACCOUNT_DETAILS_OPTIONS' ? state : state ? '1' : '0'
      },
      success: () => {
        message.success(i18n.t('operationSuccess'));
        FrontDeskMutate();
      },
      resCode: RESPONSE_CODE_FRONT
    })
  }

  const onEditFrontDeskAccountDetails = (key: any) => {
    setMemberAccountDetailsOptions((prevState: any) => {
      const updatedState = {
        ...prevState,
        [key]: prevState[key] === 1 ? 0 : 1, // 切換開關
      };

      // 在更新後執行 onEditFrontDesk
      onEditFrontDesk('MEMBER_ACCOUNT_DETAILS_OPTIONS', updatedState);

      return updatedState; // 返回更新後的 state
    });
  }

  return (
    <div id="container">
      <LayoutNav />
      <Content>
        <Tabs className="mt-1" activeKey={activeKey} items={tabsItems} onTabClick={setActiveKey} />
        {activeKey === TAB.彈窗設定&&
        <Row>
          <Col span={24}>
            <Table
              loading={isValidating}
              size="middle"
              dataSource={
                PopupList?.Data.map((item: any) => ({ key: item.Id, ...item })) || []
              }
              columns={[
                {
                  dataIndex: 'Item',
                  title: i18n.t('project'),
                  render: (val: any, { KeyId }: any,) => i18n.t(FRON_POP_ITEM_CODE[KeyId] || KeyId)
                },
                {
                  dataIndex: 'Description',
                  title: i18n.t('explanation'),
                  render: (val: any, { KeyId }: any) => i18n.t(FRON_POP_DESC_CODE[KeyId] || KeyId)
                },
                {
                  dataIndex: 'LastModifyAccount',
                  title: i18n.t('updater'),
                  width: 160,
                  render: (val: any) => val || 'System'
                },
                {
                  dataIndex: 'UpdateDate',
                  title: i18n.t('updateTime'),
                  width: 200,
                  render: (val: string) => timeS2L(val)
                },
                {
                  dataIndex: 'IsPopUp',
                  title: i18n.t('status'),
                  width: 120,
                  render: (val: any, { Id }: any) => (
                    <Switch defaultChecked={val} onClick={e => onEditPopup(Id, e)} disabled={!$p('50302')}/>
                  )
                },
              ]
            }
              pagination={false}
            />
          </Col>
        </Row>
        }

        {activeKey === TAB.前台設定&&
          <Row>
            <Col span={24}>
              <Table
                loading={FrontDeskIsValidating}
                size="middle"
                dataSource={
                  FrontDeskList?.Data
                    .filter((item: any)=>{
                      return $sc ? true: item.KeyId !== "WITHDRAW_BANK_METHOD_PHOTO_REQUIRED"
                    })
                    .map((item: any) => ({ key: item.Id, ...item })) || []
                }
                columns={[
                  {
                    dataIndex: 'Item',
                    title: i18n.t('project'),
                    render: (val: any, { KeyId }: any) => i18n.t(FRON_FRONT_DESK_ITEM_CODE[KeyId] || KeyId)
                  },
                  {
                    dataIndex: 'Description',
                    title: i18n.t('explanation'),
                    render: (val: any, { KeyId }: any) => i18n.t(FRON_FRONT_DESK_DESC_CODE[KeyId] || KeyId)
                  },
                  {
                    dataIndex: 'LastModifyAccount',
                    title: i18n.t('updater'),
                    width: 160,
                    render: (val: any) => val || 'System'
                  },
                  {
                    dataIndex: 'UpdateDate',
                    title: i18n.t('updateTime'),
                    width: 200,
                    render: (val: string) => timeS2L(val)
                  },
                  {
                    dataIndex: 'Code',
                    title: i18n.t('status'),
                    width: 360,
                    render: (val: any, { KeyId }: any) => {

                      if (KeyId !== 'MEMBER_ACCOUNT_DETAILS_OPTIONS') {
                        return (
                          <Switch defaultChecked={val === "1"} onClick={e => onEditFrontDesk(KeyId, e)} disabled={!$p('50502')} />
                        )

                      // 帳戶明細選項
                      } else {
                        return (
                          <Row gutter={[12, 12]} align={'middle'}>
                            {Object.entries(memberAccountDetailsOptions).map(([key, value]) => (
                              <Col key={key} span={12}>
                                <Checkbox
                                  disabled={!$p('50502') || key === 'Deposit' || key === 'Withdraw'}
                                  checked={value === 1}
                                  onChange={() => onEditFrontDeskAccountDetails(key)}
                                >
                                  {i18n.t(FRON_FRONT_DESK_ACCOUNT_DETAILS_CODE[key])}
                                </Checkbox>
                              </Col>
                            ))}
                          </Row>
                        )
                      }}
                  },
                ]
                }
                pagination={false}
              />
            </Col>
          </Row>
        }
      </Content>
    </div>
  );
};

export default PageMain;