import { Col, Row, Switch, Table, message } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { LayoutNav, LayoutTabPlatform } from 'components/layout.component';
import { RESPONSE_CODE_GAME_DATA } from 'constants/response';
import useAccount from 'hooks/account.hook';
import useSite from 'hooks/site.hook';
import i18n from 'i18n';
import React, { useEffect, useState } from 'react';
import { $get, $post } from 'services';
import { specialProviderName, timeS2L } from "utils/common";

// 站台設置 > 遊戲資料

interface ProviderInfoType {
  Id: number;
  CategoryId: number;
  CategoryCode: string;
  ProviderName: string;
  ProviderCode: string;
  Status: number;
  Account: string;
  UpdateDate: string;
}

const PageMain: React.FC = () => {
  const [list, setList] = useState([]);

  const { data: site } = useSite();
  const { permissions: $p } = useAccount();
  const { data, mutate, isValidating } = $get({ url: 'api/provider/open/list' });

  const categoryOrder = ["SLOT", "LIVE", "SPORTS", "POKER", "FISHING","COCKFIGHT", "LOTTERY", "EGAMES"];

  useEffect(() => {
    if (data?.Data) {
      const ary: any = [];
      let headIndex = 0;
      let count = 1;
      let currentCode = '';
      data.Data
        ?.sort((currentRecord:any , nextRecord: any) => {
          if (currentRecord.ProviderCode === nextRecord.ProviderCode) {
            return categoryOrder.indexOf(currentRecord.CategoryCode) - categoryOrder.indexOf(nextRecord.CategoryCode);
          }
          return 0;
        })
        .forEach((record: any, i: number) => {
          if (currentCode === record.ProviderCode) {
            record.rowSpan = 0;
            count++;
          } else {
            data.Data[headIndex].rowSpan = count;

            headIndex = i;
            count = 1;
            currentCode = record.ProviderCode;
          }

          ary.push(record);
        });

      data.Data[headIndex].rowSpan = count;

      setList(ary);
    }
  }, [data])

  const handleStatus = (value: boolean, id: number) => {
    const Status = value ? 1 : 0
    $post({
      url: 'api/provider/status/update',
      send: {
        Id: id,
        Status
      },
      success: () => {
        message.success(i18n.t('operationSuccess'));
        mutate();
      },
      resCode: RESPONSE_CODE_GAME_DATA
    })
  }

  return (
    <div id="container">
      <LayoutNav />
      <Content className="ph-2 pt-1">
        <LayoutTabPlatform activeKey="3" />
        <Row align="middle" gutter={[10, 16]}>
          <Col span={24}>
            <Table
              loading={isValidating}
              className={'table-border'}
              size="middle"
              dataSource={list.map((item: ProviderInfoType) => ({ ...item, key: item.Id }))}
              columns={[
                {
                  dataIndex: 'ProviderName',
                  className: 'table-border-right',
                  title: i18n.t('gameProvider'),
                  width: 250,
                  fixed: 'left',
                  onCell: (record: any) => ({ rowSpan: record.rowSpan }),
                  render: (val, { ProviderCode }: any) => specialProviderName(val, site.SiteName, ProviderCode)
                },
                {
                  dataIndex: 'CategoryCode',
                  title: i18n.t('gameCategory'),
                  width: 250,
                  render: (val) => i18n.t(val)
                },
                {
                  dataIndex: 'Status',
                  title: i18n.t('gameStatus'),
                  width: 250,
                  render: (val, { Id }) =>
                    <Switch defaultChecked={val} onChange={v => handleStatus(v, Id)} disabled={!$p('00202')} />
                },
                {
                  dataIndex: 'UpdateAccount',
                  title: i18n.t('updater'),
                  width: 250,
                  render: (val) => val || 'System'
                },
                {
                  dataIndex: 'UpdateDate',
                  title: i18n.t('updateTime'),
                  width: 250,
                  render: (val: string) => timeS2L(val)
                },
              ]}
              pagination={false}
            />
          </Col>
        </Row>
      </Content>
    </div>
  );
};

export default PageMain;