import { Row } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { ViewBank, ViewVirtualCurrency } from 'components/bank.component';
import { LayoutNav, LayoutTabMember } from 'components/layout.component';
import React from 'react';
import { useParams } from 'react-router-dom';

// 會員列表 > 銀行卡列表

const PageMain: React.FC = () => {

  const { id, account, agId } = useParams();

  return (
    <div id="container">
      <LayoutNav id={id|| 0} account={account || ''} agId={agId}/>
      <Content className="pr-2 pl-2 pb-2">
        <LayoutTabMember activeKey="4" id={id || 0} account={account} agId={agId}/>
        <Row>
          <ViewBank id={id} />
          <ViewVirtualCurrency id={id} />
        </Row>
      </Content>
    </div>
  );
};

export default PageMain;