import { Button, Card, Col, Divider, Form, Input, Row, Select, Space, Spin, Table, Tag, message } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { Content } from 'antd/es/layout/layout';
import { PopupPaddingReview } from 'components/agent.component';
import { DatePickerCol, InquiryWindow, LayoutNav, LayoutPagination } from 'components/layout.component';
import dayjs from 'dayjs';
import { DATE_TYPE } from 'enum/date';
import { SETTLE_CYCLE } from 'enum/promotion';
import { AGENT_STATE } from 'enum/state';
import useAccount from 'hooks/account.hook';
import useIcon from 'hooks/icon.hook';
import i18n from 'i18n';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { $get, $post } from 'services';
import { toFormatNumber as $f, enumToOptions, timeL2S, tzShift } from "utils/common";

// 代理報表審核

const PageMain: React.FC = () => {
  const navigate = useNavigate();

  const icon = useIcon();
  const { permissions: $p } = useAccount();
  const [isOpenPaddingReview, setIsOpenPaddingReview] = useState(false);
  const [isOpenAllDistribute, setIsOpenAllDistribute] = useState(false);
  const [currentBill, setCurrentBill] = useState({});
  const [popmode, setPopmode] = useState('1');

  // 總計
  const { data: receiveticketCount, mutate: mutateCount, isValidating: CountValidating } = $get({
    url: 'api/report/receiveticket/count'
  });

  const [form] = useForm();
  const [date, setDate] = useState([
    tzShift(dayjs().startOf('month'), 'YYYY-MM-DD HH:mm:ss'),
    tzShift(dayjs().endOf('month'), 'YYYY-MM-DD HH:mm:ss'),
    DATE_TYPE.thisMonth
  ]);
  const [reset, setReset] = useState<boolean>(false);
  const [params, setParams] = useState<any>({
    StartDate: timeL2S(date[0] as string),
    EndDate: timeL2S(date[1] as string),
  });
  const { data: commissionticketList, mutate: mutateCommission, isValidating: commissionValidating } = $get({
    url: 'api/commissionticket/sa/date/list',
    params
  })
  const { data: revenueticketList, mutate: mutateRevenue, isValidating: revenueValidating } = $get({
    url: 'api/revenueticket/sa/date/list',
    params
  });

  const [page, setPage] = useState([1, 10]);
  const { data: sa, mutate, isValidating } = $get({
    url: 'api/agent/sa/list',
    params: {
      Account: params.SAAccount,
      Status: params.Status,
      CreateDateStartTime: '2022-01-01 00:00:00',
      CreateDateEndTime: '2030-01-01 00:00:00',
      PageIndex: page[0],
      PageSize: page[1]
    }
  })
  const [salist, setSalist] = useState([]);
  useEffect(() => {
    if (sa && sa.Data) {
      const list = sa.Data.map((item: any) => {
        const commissionList = commissionticketList
          ? commissionticketList.Data.filter((c: any) => c.SAAccount === item.Account) : []
        const revenueShareList = revenueticketList
          ? revenueticketList.Data.filter((c: any) => c.SAAccount === item.Account) : []
        return {
          key: item.Id,
          account: item.Account,
          status: item.Status,
          commissionSettleCycle: item.CommissionSettleCycle,
          revenueShareSettleCycle: item.RevenueShareSettleCycle,
          commissionList,
          revenueShareList,
        }
      })
      setSalist(list)
    }
  }, [sa, commissionticketList, revenueticketList]);

  const search = (formData: any) => {
    setParams({
      SAAccount: formData.SAAccount,
      Status: formData.Status,
      StartDate: timeL2S(date[0] as string),
      EndDate: timeL2S(date[1] as string)
    })
    setPage([1, page[1]]);
    mutateAll();
  }

  const mutateAll = () => {
    mutate()
    mutateCommission()
    mutateRevenue()
    mutateCount()
  }

  const onClear = () => {
    form.resetFields();
    setReset(!reset);
    setPage([1, 10]);
  }

  const distributeAll = async () => {
    if (popmode === '1') {
      $post({
        url: 'api/commissionticket/agent/pass',
        send: {
          // StartDate: null,
          // EndDate: null
        },
        success: () => {
          message.success(i18n.t('operationSuccess'));
          mutateAll();
        }
      })
    } else {
      $post({
        url: 'api/revenueticket/pass',
        send: {},
        success: () => {
          message.success(i18n.t('operationSuccess'));
          mutateAll();
        }
      })
    }
  };

  return (
    <div id="container">
      <LayoutNav />
      <Content className="p-2 pt-1">
        <Row gutter={[12, 12]}>
          {/* 左邊 */}
          <Col span={4}>
            <Space style={{ width: '100%' }} direction="vertical">
              {/* 總數 */}
              <Card className="card-bg-02 color-01" loading={CountValidating}>
                <Row align="middle">
                  <Col span={24}>
                    <Row align="middle" gutter={[12, 12]}>
                      <Col span={12} className="size-14">{i18n.t('agentCount')}</Col>
                      <Col className="size-24">
                        {$f(receiveticketCount?.Data.SAAgentCount + receiveticketCount?.Data.AgentCount, 0)}
                      </Col>
                      <Col>
                        {`${receiveticketCount?.Data.SAAgentCount} + ${receiveticketCount?.Data.AgentCount}`}
                      </Col>
                    </Row>
                  </Col>
                  <Col span={24}>
                    <Row align="middle" gutter={[12, 12]}>
                      <Col span={12} className="size-14"> {i18n.t('memberCount')}</Col>
                      <Col className="size-24">{$f(receiveticketCount?.Data.member, 0)}</Col>
                    </Row>
                  </Col>
                </Row>
              </Card>
              {/* 返水 */}
              <Card className="mt-1" loading={CountValidating}>
                <Row align="middle" gutter={[12, 12]}>
                  <Col span={24} className="size-18">{i18n.t('rebate')}</Col>
                  <Col span={24}>
                    <Row className="mt-1" justify="space-between">
                      <Col><Tag className='color-withdraw-padding'>{i18n.t('pendingDistribution')}</Tag></Col>
                      <Col>{receiveticketCount?.Data.Commission}</Col>
                    </Row>
                    {$p('40908') &&
                      <Row className="mt-1" justify="space-between">
                        <Col onClick={() => {
                          setIsOpenAllDistribute(true);
                          setPopmode('1');
                        }}>
                          {/* {icon.WithdrawStateTag(AGENT_WITHDRAW_STATE.dispatched)} */}
                          <Tag className='color-withdraw-pass' style={{ cursor: 'pointer' }}>
                            {i18n.t('batchPass')}
                          </Tag>
                        </Col>
                        <Col></Col>
                      </Row>}
                  </Col>
                </Row>
              </Card>
              {/* 占成 */}
              <Card className="mt-1" loading={CountValidating}>
                <Row align="middle" gutter={[12, 12]}>
                  <Col span={24} className="size-18">{i18n.t('revenue')}</Col>
                  <Col span={24}>
                    <Row className="mt-1" justify="space-between">
                      <Col><Tag className='color-withdraw-padding'>{i18n.t('pendingDistribution')}</Tag></Col>
                      <Col>{receiveticketCount?.Data.Revenue}</Col>
                    </Row>
                    {$p('40908') &&
                      <Row className="mt-1" justify="space-between">
                        <Col onClick={() => {
                          setIsOpenAllDistribute(true);
                          setPopmode('2');
                        }}>
                          {/* {icon.WithdrawStateTag(AGENT_WITHDRAW_STATE.dispatched)} */}
                          <Tag className='color-withdraw-pass' style={{ cursor: 'pointer' }}>
                            {i18n.t('batchPass')}
                          </Tag>
                        </Col>
                        <Col></Col>
                      </Row>}
                  </Col>
                </Row>
              </Card>
              {/* 提款 */}
              <Card className="mt-1" loading={CountValidating}>
                <Row align="middle" gutter={[12, 12]}>
                  <Col span={24} className="size-18">{i18n.t('withdrawal')}</Col>
                  <Col span={24}>
                    <Row justify="space-between">
                      <Col>{i18n.t('waitApproval')}</Col>
                      <Col>{receiveticketCount?.Data.PendingWithdrawTicket}</Col>
                    </Row>
                    <Divider className="mt-1 mb-1" />
                    <Row justify="space-between">
                      <Col>{i18n.t('paymentProcessing')}</Col>
                      <Col>{receiveticketCount?.Data.MerchantWithdrawTicket}</Col>
                    </Row>
                  </Col>
                </Row>
              </Card>
              {/* 調帳 */}
              <Card className="mt-1" loading={CountValidating}>
                <Row align="middle" gutter={[12, 12]}>
                  <Col span={24} className="size-18">{i18n.t('pendingAdjustment')}</Col>
                  <Col span={24}>
                    <Row justify="space-between">
                      <Col>{i18n.t('waitApproval')}</Col>
                      <Col>{receiveticketCount?.Data.Adjust}</Col>
                    </Row>
                  </Col>
                </Row>
              </Card>
            </Space>
          </Col>
          {/* 右邊 */}
          <Col span={20}>
            {/* 搜尋 */}
            <Form form={form} onFinish={search}>
              <Row gutter={[12, 12]}>
                <Col className="w-12">
                  <Form.Item name="SAAccount">
                    <Input placeholder={`${i18n.t('masterAgent')}`} />
                  </Form.Item>
                </Col>
                <Col className="w-12">
                  <Form.Item name="Status">
                    <Select
                      placeholder={`${i18n.t('status')}`}
                      options={[
                        { value: '', label: i18n.t('ALL') },
                        ...enumToOptions(AGENT_STATE)
                      ]}
                    />
                  </Form.Item>
                </Col>
                <DatePickerCol width={190} date={date} setDate={setDate} initDate={reset}
                  defaultDateType={DATE_TYPE.thisMonth} />
                <Col>
                  <Button type="primary" htmlType="submit">{i18n.t('search')}</Button>
                </Col>
                <Col>
                  <Button onClick={onClear}>{i18n.t('clear')}</Button>
                </Col>
              </Row>
            </Form>
            <Row className="mt-1">
              <Col span={24}>
                <Table
                  size="middle"
                  loading={isValidating}
                  dataSource={salist}
                  columns={[
                    {
                      key: 1,
                      dataIndex: 'account',
                      title: i18n.t('account'),
                      className: 'size-12',
                      width: 250
                    },
                    {
                      key: 2,
                      dataIndex: 'status',
                      title: i18n.t('status'),
                      className: 'size-12',
                      width: 180,
                      render: (val) => icon.AgentStateTag(val, "w-4")
                    },
                    {
                      key: 3,
                      dataIndex: 'commissionSettleCycle',
                      title: i18n.t('rebate'),
                      className: 'size-12 vertical-top',
                      render: (val, record: any) => (
                        <Spin spinning={commissionValidating}>
                          {$p('40301') &&
                          <span>
                            {i18n.t(SETTLE_CYCLE[val])}
                            <Button onClick={() => navigate(`/agent/settle/${record.key}`)} className="size-12" type="link">
                              {i18n.t('settlementReports')}
                            </Button>
                          </span>
                          }
                          {record.commissionList.map((item: any) => (
                            <div key={`c-${item.SAId}-${item.Period}`} style={{ marginBottom: 8 }}>
                              <span onClick={() => {
                                if (item.Status === 0) {
                                  setIsOpenPaddingReview(true)
                                  setPopmode('1')
                                  setCurrentBill(item);
                                }
                              }}>
                                {icon.WithdrawStateTag(item.Status, item.Period, item.Auditor)}
                              </span>
                            </div>
                          ))}
                        </Spin>
                      )
                    },
                    {
                      key: 4,
                      dataIndex: 'revenueShareSettleCycle',
                      title: i18n.t('revenue'),
                      className: 'size-12 vertical-top',
                      render: (val, record: any) => (
                        <Spin spinning={revenueValidating}>
                          {$p('40301') &&
                          <span>
                            {i18n.t(SETTLE_CYCLE[val])}
                            <Button onClick={() => navigate(`/agent/settle/${record.key}`)} className="size-12" type="link">
                              {i18n.t('settlementReports')}
                            </Button>
                          </span>
                          }
                          {record.revenueShareList.map((item: any) => (
                            <div key={`r-${item.SAId}-${item.Period}`} style={{ marginBottom: 8 }}>
                              <span onClick={() => {
                                if (item.Status === 0) {
                                  setIsOpenPaddingReview(true)
                                  setPopmode('2')
                                  setCurrentBill(item);
                                }
                              }}>
                                {icon.WithdrawStateTag(item.Status, item.Period, item.Auditor)}
                              </span>
                            </div>
                          ))}
                        </Spin>
                      )
                    }
                  ]}
                  pagination={false}
                />
                <LayoutPagination total={sa?.TotalRecord} page={page} setPage={setPage} />
              </Col>
            </Row>
          </Col>
        </Row>
        {/* 派發視窗 */}
        <PopupPaddingReview currentBill={currentBill} mode={popmode} isOpen={isOpenPaddingReview}
          close={() => setIsOpenPaddingReview(false)} mutate={mutateAll} />
        <InquiryWindow isOpen={isOpenAllDistribute} close={() => setIsOpenAllDistribute(false)}
          msg={i18n.t('confirmAllPendingDistributionsApproved')} action={distributeAll} />
      </Content>
    </div>
  );
};

export default PageMain;