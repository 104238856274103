import { Button, Col, Form, Image, Input, Row, Table, Tooltip } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { Content } from 'antd/es/layout/layout';
import { PopupChatHistoryDetail } from 'components/chat.component';
import { DatePickerCol, LayoutNav, LayoutPagination, LayoutTabChatLog } from 'components/layout.component';
import dayjs from 'dayjs';
import { DATE_RANGE_LIMIT, DATE_TYPE } from 'enum/date';
import { ROLE } from 'enum/socket';
import i18n from 'i18n';
import React, { useState } from 'react';
import { $get } from 'services';
import { timeL2S, timeS2L, tzShift } from "../../../utils/common";

// 聊天室紀錄

interface SearchParams {
  Account?: string;
  MemberAccount?: string;
  AgentAccount?: string;
  NoCode?: string;
  StartDate?: string;
  EndDate?: string;
}

const PageMain: React.FC = () => {
  const [form] = useForm();
  const [isOpenDetail, setIsOpenDetail] = useState<boolean>(false);
  const [sessionId, setSessionId] = useState<string | null>(null);
  const [sessionTime, setSessionTime] = useState<string | null>(null);
  const [endTime, setEndTime] = useState<string | null>(null);
  const [role, setRole] = useState<number | null>(null);
  const [account, setAccount] = useState<string | null>(null);
  const [date, setDate] = useState([
    tzShift(dayjs().startOf('day'), 'YYYY-MM-DD HH:mm:ss'),
    tzShift(dayjs().endOf('day'), 'YYYY-MM-DD HH:mm:ss')
  ]);
  const [reset, setReset] = useState(false);
  const [page, setPage] = useState<number[]>([1, 10]);
  const [params, setParams] = useState<SearchParams>({
    StartDate: timeL2S(date[0]),
    EndDate: timeL2S(date[1])
  });
  const { data: ServiceHistory, isValidating, mutate } = $get({
    url: 'api/chathistory/service/session/list',
    params: {
      ...params,
      PageIndex: page[0],
      PageSize: page[1]
    }
  })
  const onFinish = (value: {
    backOfficeAccount: string;
    memberAccount: string;
    id: string;
    SAaccount: string;
  }) => {
    setPage([1, page[1]]);
    if (date) {
      setParams({
        Account: value.backOfficeAccount,
        MemberAccount: value.memberAccount,
        AgentAccount: value.SAaccount,
        NoCode: value.id,
        StartDate: timeL2S(date[0]),
        EndDate: timeL2S(date[1])
      })
    }
    mutate();
  };

  const onDetail = (key: string, time: string, role: number, account: string, EndTIme: string) => {
    setSessionId(key);
    setSessionTime(time);
    setRole(role);
    setAccount(account);
    setIsOpenDetail(true);
    setEndTime(EndTIme)
  }

  const onClear = () => {
    form.resetFields();
    setReset(!reset);
    setPage([1, 10]);
    mutate();
  };

  return (
    <div id="container">
      <LayoutNav />
      <Content>
        <Form form={form} onFinish={onFinish}>
          <LayoutTabChatLog activeKey='1' />
          <Row align="middle" gutter={[12, 12]}>
            <Col>
              <Form.Item name="backOfficeAccount">
                <Input className="w-12" placeholder={`${i18n.t('backOfficeAccount')}`} />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item name="memberAccount">
                <Input className="w-12" placeholder={`${i18n.t('memberAccount')}`} />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item name="id" rules={[
                { max: 8, message: `${i18n.t('invalidFormat')}` },
              ]}>
                <Input className="w-12" placeholder={`${i18n.t('identifier')}`} />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item name="SAaccount">
                <Input className="w-12" placeholder={`${i18n.t('masterAgentAndAgentAccount')}`} />
              </Form.Item>
            </Col>
            <DatePickerCol width={190} date={date} setDate={setDate} initDate={reset}
              ignoreDateType={[DATE_TYPE.thisMonth, DATE_TYPE.lastMonth]} rangeLimit={DATE_RANGE_LIMIT.past31Days} />
            <Col>
              <Button type="primary" htmlType="submit">{i18n.t('search')}</Button>
            </Col>
            <Col>
              <Button onClick={onClear}>{i18n.t('clear')}</Button>
            </Col>
            <Col span={24}>
              <Table
                loading={isValidating}
                size="middle"
                scroll={{ x: 1500 }}
                dataSource={ServiceHistory?.Data?.map((item: ChatServiceHistory, i: number) => ({ key: item.Id, ...item })) || []}
                columns={[
                  {
                    title: i18n.t('sendMessageRole'),
                    dataIndex: 'CustomerRole',
                    fixed: 'left',
                    width: 100,
                    render: (_, { CustomerRole }: any) => i18n.t(ROLE[CustomerRole])
                  },
                  {
                    title: i18n.t('account'),
                    fixed: 'left',
                    width: 300,
                    render: (_, { key, CustomerRole, CustomerAccount }) => CustomerRole === ROLE.visitor ? key : CustomerAccount
                  },
                  {
                    title: <>{i18n.t('nickname')} / {i18n.t('identifier')}</>,
                    width: 200,
                    render: (_, { NickName, NoCode }) => <>{NickName || '-'}／{NoCode || '-'}</>
                  },
                  {
                    title: i18n.t('receiveMessageRole'),
                    dataIndex: 'ServicePermissionName',
                    width: 150,
                  },
                  {
                    title: i18n.t('account'),
                    dataIndex: 'ServiceAccount',
                    width: 150,
                  },
                  {
                    title: i18n.t('content'),
                    render: (_, { LatestMessageType, LatestMessage, }) => (
                      LatestMessageType === 0
                        ? <Tooltip title={LatestMessage} overlayStyle={{ maxWidth: 600 }}>
                          <div style={{
                            whiteSpace: 'nowrap',
                            overflow: 'hidden', textOverflow: 'ellipsis',
                          }}>
                            {LatestMessage}
                          </div>
                        </Tooltip>
                        : <Image src={LatestMessage} width={50} height={50} />
                    )
                  },
                  {
                    title: i18n.t('inboundTime'),
                    dataIndex: 'ProcessTime',
                    width: 160,
                    render: (val: string) => timeS2L(val)
                  },
                  {
                    title: i18n.t('IP'),
                    dataIndex: 'IP',
                    width: 120,
                  },
                  {
                    title: i18n.t('endTime'),
                    dataIndex: 'EndTIme',
                    width: 160,
                    render: (val: string) => timeS2L(val)
                  },
                  {
                    title: i18n.t('operation'),
                    fixed: 'right',
                    width: 100,
                    render: (_, { key, ProcessTime, EndTIme, CustomerRole, CustomerAccount }) => (
                      <Button type="link"
                        onClick={() => onDetail(
                          key,
                          ProcessTime,
                          CustomerRole,
                          CustomerAccount,
                          EndTIme
                        )}>
                        {i18n.t('detail')}
                      </Button>
                    )
                  },
                ]}
                pagination={false}
              />
              <LayoutPagination total={ServiceHistory ? ServiceHistory.TotalRecord : 0} setPage={setPage} page={page} />
            </Col>
          </Row>
        </Form>
      </Content>
      <PopupChatHistoryDetail isOpen={isOpenDetail} close={() => setIsOpenDetail(false)}
        role={role} account={account} ProcessTime={sessionTime} sessionId={sessionId} refresh={mutate} EndTime={endTime}/>
    </div>
  );
};

export default PageMain;