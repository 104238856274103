import { InfoCircleFilled } from '@ant-design/icons';
import { Button, Col, Form, Input, InputNumber, Row, Select, Space, Spin, Tooltip, message } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { UploadFile } from 'antd/es/upload';
import { useForm } from 'antd/lib/form/Form';
import { FormInputPosition } from 'components/form.component';
import { DateSelector, LayoutNav, UploadImage } from 'components/layout.component';
import { MemberGroupSelect } from 'components/member.component';
import { CKEditorComp } from 'components/promotion.component';
import { RESPONSE_CODE_BONUS } from 'constants/response';
import dayjs from 'dayjs';
import { DATE_RANGE_LIMIT } from 'enum/date';
import { PROMOTION_STATE } from 'enum/promotion';
import { PROMOTION_CYCLE, WHETHER } from 'enum/state';
import useSite from 'hooks/site.hook';
import i18n from 'i18n';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { $get, $post } from 'services';
import { enumToOptions, selectFuzzyFilter, specialProviderName, timeL2S, timeS2L, tzShift, verify } from "utils/common";

// 優惠活動管理 編輯其他優惠

const PageMain: React.FC = () => {
  const site = useSite();
  const { id } = useParams();
  const navigate = useNavigate();
  const [form] = useForm();
  const [loading, setLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [isOneTime, setIsOneTime] = useState(false);
  const [editorContent, setEditorContent] = useState<string>('');
  const [categoryCodes, setCategoryCodes] = useState<any>([]);

  const { data: category } = $get({
    url: 'api/category/list',
    params: {
      Status: 1,
    },
  });
  const { data: provider } = $get({
    url: 'api/provider/list',
    params: {
      Status: 1,
      PageSize: 999,
      PageIndex: 1
    },
    allow: !!categoryCodes && categoryCodes.length > 0,
  });

  const [date, setDate] = useState([
    tzShift(dayjs().startOf('day'), 'YYYY-MM-DD HH:mm:ss'),
    tzShift(dayjs().endOf('day'), 'YYYY-MM-DD HH:mm:ss'),
    null
  ]);

  const { data: BonusInfo, isValidating } = $get({
    url: 'api/bonus/info',
    params: { Id: id },
  })
  useEffect(() => {
    if (BonusInfo) {
      const { Amount, ValidBet, ProviderIds, CategoryCodes } = JSON.parse(BonusInfo.Data.BonusRuleSetting);
      // const ProvidersId = JSON.parse(BonusInfo.Data.ProvidersId);
      const LevelsId = JSON.parse(BonusInfo.Data.LevelsId);
      const MemberGroupsId = JSON.parse(BonusInfo.Data.MemberGroupsId);

      form.setFieldsValue({
        promotionType: 99,
        promotionName: BonusInfo.Data.Name,
        popupDisplay: BonusInfo.Data.IsPopUp,
        redirectToPromotionPage: BonusInfo.Data.IsRedirect,
        period: BonusInfo.Data.Period,
        LevelId: LevelsId,
        MemberGroupsId,
        oneTimePromotion: BonusInfo.Data.IsOneTime,
        CategoryCodes: CategoryCodes,
        ProviderIds: ProviderIds,
        applicableMemberLevel: LevelsId,
        expectedValidBet: ValidBet,
        promotionAmount: Amount,
        rolloverRate: BonusInfo.Data.ValidBetTimes,
        applicationMethod: BonusInfo.Data.ApplyMethod,
        distributionMethod: BonusInfo.Data.SettleMethod,
        status: BonusInfo.Data.Status,
        Position: BonusInfo.Data.Position,
        internalContent: BonusInfo.Data.Content
      })
      setCategoryCodes(CategoryCodes);
      setImageData([
        {
          uid: BonusInfo.Data.Photo,
          name: BonusInfo.Data.Photo,
          url: BonusInfo.Data.Photo,
        },
      ])
      setEditorContent(BonusInfo.Data.Content);
      setIsDisabled(BonusInfo.Data.IsPopUp === 0);
      setDate([timeS2L(BonusInfo.Data.StartDate), timeS2L(BonusInfo.Data.EndDate), null])
      setIsOneTime(BonusInfo.Data.IsOneTime === 0)
    }
  }, [BonusInfo]);

  const onFinish = (formData: any) => {
    $post({
      url: 'api/bonus/update',
      send: {
        Id: id,
        Name: formData.promotionName,
        SettleMethod: formData.distributionMethod,
        ApplyMethod: formData.applicationMethod,
        Status: formData.status,
        IsPopUp: formData.popupDisplay,
        IsRedirect: formData.redirectToPromotionPage,
        Period: formData.period,
        IsOneTime: formData.oneTimePromotion,
        CategoryCodes: formData.CategoryCodes,
        ProviderIds: formData.ProviderIds,
        LevelsId: formData.LevelId,
        MemberGroupsId: formData.MemberGroupsId,
        ValidBet: formData.expectedValidBet,
        Amount: formData.promotionAmount,
        ValidBetTimes: formData.rolloverRate,
        Position: formData.Position,
        StartDate: timeL2S(date[0] as any),
        EndDate: timeL2S(date[1] as any),
        Content: editorContent,
        Photo: imageData[0] ? [imageData[0].url] : []
      },
      success: () => {
        message.success(i18n.t('editSuccess'));
        navigate('/promotion');
      },
      resCode: RESPONSE_CODE_BONUS
    }, setLoading)
  }

  const [imageData, setImageData] = useState<UploadFile<any>[]>([]);

  // 篩選遊戲類型的遊戲商
  const providerFilter = () => {
    let list: string[] = provider?.Data || [];
    return list.filter((item: any) => categoryCodes.includes(item.CategoryCode));
  }

  return (
    <div id="container">
      <LayoutNav />
      <Content className="ph-2">
        <Spin spinning={isValidating}>
          <Form layout="vertical" form={form} onFinish={onFinish}>
            <Row gutter={[12, 12]}>
              <Col span={24}>
                <Row justify="space-between" align="middle">
                  <Col>
                    <Form.Item name="promotionType" className="w-12">
                      <Select
                        disabled
                        options={[
                          { value: 99, label: i18n.t('otherPromotions') }
                        ]}
                      />
                    </Form.Item>
                  </Col>
                  <Col>
                    <Space size="middle">
                      <Button onClick={() => navigate('/promotion')}>{i18n.t('cancel')}</Button>
                      <Button type="primary" htmlType="submit" loading={loading}>{i18n.t('confirm')}</Button>
                    </Space>
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <Form.Item name="promotionName" label={i18n.t('promotionName')} rules={[{ required: true, message: `${i18n.t('required')}` },
                { type: 'string', max: 100, message: `${i18n.t('promotionActivityNameLimitedTo100Characters')}` }]}>
                  <Input className="w-12" placeholder={`${i18n.t('inputData')}`} />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Row gutter={[12, 12]} align="middle">
                  <Col>
                    <Form.Item name="popupDisplay" className="w-12" label={i18n.t('popupDisplay')} rules={[{ required: true, message: `${i18n.t('required')}` }]}>
                      <Select
                        onChange={e => {
                          setIsDisabled(e === 0);
                          form.setFieldValue('redirectToPromotionPage', 0);
                        }}
                        placeholder={i18n.t('pleaseSelect')}
                        options={enumToOptions(WHETHER)}
                      />
                    </Form.Item>
                  </Col>
                  <Col>
                    <Form.Item name="redirectToPromotionPage" className="w-12" label={i18n.t('redirectToPromotionPage')}
                      rules={[{ required: true, message: `${i18n.t('required')}` }]}>
                      <Select
                        disabled={isDisabled}
                        placeholder={i18n.t('pleaseSelect')}
                        options={enumToOptions(WHETHER)}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <Row gutter={[12, 12]}>
                  <Col>
                    <Form.Item name="period" className="w-12" label={i18n.t('cycle')} required
                      rules={[{ required: true, message: `${i18n.t('required')}` }]}>
                      <Select
                        placeholder={i18n.t('pleaseSelect')}
                        options={enumToOptions(PROMOTION_CYCLE)}
                      />
                    </Form.Item>
                  </Col>
                  <Col>
                    <Form.Item name="oneTimePromotion" className="w-12" label={i18n.t('oneTimePromotion')}
                      rules={[{ required: true, message: `${i18n.t('required')}` }]}>
                      <Select
                        onChange={e => {
                          setIsOneTime(e !== 1);
                          e === 0
                            ? form.setFieldValue('distributionMethod', 1)
                            : form.setFieldValue('distributionMethod', 0)
                        }}
                        placeholder={i18n.t('pleaseSelect')}
                        options={enumToOptions(WHETHER)}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
              {/* 遊戲大類 / 遊戲商 */}
              <Col span={24}>
                <Row gutter={[12, 12]}>
                  <Col>
                    <Form.Item name="CategoryCodes" className="w-12" label={
                      <>
                        {`${i18n.t('applicable')}${i18n.t('gameCategory')}`}<span className="color-03">{`(${i18n.t('selectableMultiple')})`}</span>
                      </>
                    }>
                      <Select
                        mode="multiple"
                        allowClear
                        placeholder={i18n.t('pleaseSelect')}
                        filterOption={selectFuzzyFilter}
                        onChange={setCategoryCodes}
                        options={
                          category?.Data.map((option: any) => ({
                            value: option.Code,
                            label: i18n.t(option.Name)
                          }))
                        }
                      />
                    </Form.Item>
                  </Col>
                  <Col>
                    <Form.Item name="ProviderIds" className="w-12" label={
                      <>
                        {`${i18n.t('applicable')}${i18n.t('gameProvider')}`}<span className="color-03">{`(${i18n.t('selectableMultiple')})`}</span>
                      </>
                    }>
                      <Select
                        mode="multiple"
                        allowClear
                        placeholder={i18n.t('pleaseSelect')}
                        filterOption={selectFuzzyFilter}
                        disabled={!categoryCodes || categoryCodes.length < 1}
                        options={
                          providerFilter().map((option: any) => ({
                            value: option.Id,
                            label: `${specialProviderName(option.Name, site.data.SiteName, option.Code)}${i18n.t(option.CategoryCode)}`
                          }))
                        }
                      />
                    </Form.Item>
                  </Col>
                  <Col>
                    <Form.Item className="w-12" name="MemberGroupsId" rules={[{ required: true, message: `${i18n.t('required')}` }]} label={
                      <>
                        {i18n.t('applicableMemberGroup')}<span className="color-03">{`(${i18n.t('selectableMultiple')})`}</span>
                      </>
                    }>
                      <MemberGroupSelect mode="multiple" name="MemberGroupsId" form={form} />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <Row gutter={[12, 12]} align="middle">
                  <Col>
                    <Form.Item name="expectedValidBet" label={
                      <Tooltip placement="top" title={i18n.t('meetTheSetConditions')}>
                        {i18n.t('expectedValidBet')}<InfoCircleFilled style={{ marginLeft: 2 }} />
                      </Tooltip>
                    }
                      rules={verify({ required: false, point: 4, max: 999999999 })}>
                      <Input className="w-12" placeholder={`${i18n.t('inputData')}`} />
                    </Form.Item>
                  </Col>
                  <Col>
                    <Form.Item name="promotionAmount" label={i18n.t('promotionAmount')} rules={verify({ point: 4, max: 999999999 })}>
                      <Input className="w-12" placeholder={`${i18n.t('inputData')}`} />
                    </Form.Item>
                  </Col>
                  <Col>
                    <Form.Item name="rolloverRate" label={i18n.t('rolloverRate')} rules={verify({ point: 0 })}>
                      <Input className="w-12" placeholder={`${i18n.t('inputData')}`} />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <Row gutter={[12, 12]} align="middle">
                  <Col>
                    <Form.Item name="applicationMethod" className="w-12" label={i18n.t('applicationMethod')}
                      rules={[{ required: true, message: `${i18n.t('required')}` }]}>
                      <Select
                        disabled
                        placeholder={i18n.t('pleaseSelect')}
                        options={[
                          { value: 0, label: `${i18n.t('autoApplication')}` },
                          { value: 1, label: `${i18n.t('memberManualApplication')}` },
                          { value: 2, label: `${i18n.t('serviceManualApplication')}` },
                          { value: 3, label: `${i18n.t('agentManualApplication')}` },
                        ]}
                      />
                    </Form.Item>
                  </Col>
                  <Col>
                    <Form.Item name="distributionMethod" className="w-12" label={i18n.t('distributionMethod')}
                      rules={[{ required: true, message: `${i18n.t('required')}` }]}>
                      <Select
                        disabled={isOneTime}
                        placeholder={i18n.t('pleaseSelect')}
                        options={[
                          { value: 0, label: `${i18n.t('systemDistribution')}` },
                          { value: 1, label: `${i18n.t('manualDispatch')}` },
                        ]}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <Row gutter={[12, 12]} align="middle">
                  <Col>
                    <Form.Item name="status" className="w-12" label={i18n.t('status')} rules={[{ required: true, message: `${i18n.t('required')}` }]}>
                      <Select
                        placeholder={i18n.t('pleaseSelect')}
                        options={enumToOptions(PROMOTION_STATE)}
                      />
                    </Form.Item>
                  </Col>
                  <Col>
                    <FormInputPosition className="w-12" />
                  </Col>
                  <Col>
                    <Form.Item name="promotionTime" label={`${i18n.t('promotionStartTime')} - ${i18n.t('promotionEndTime')}`}
                      rules={[{ required: true, message: `${i18n.t('required')}` }]}>
                      <DateSelector form={form} name="promotionTime" date={date} setDate={setDate} />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <Form.Item name="Photo" valuePropName="fileList" label={i18n.t("image")}
                  rules={[{ required: !imageData[0], message: `${i18n.t('uploadImage')}` }]}>
                  <UploadImage
                    name="Photo"
                    url={"/upload/images/public/bonus"}
                    preUrl={BonusInfo ? BonusInfo.Data.Photo : ""}
                    imageData={imageData}
                    setImageData={setImageData}
                    form={form}
                    w={1920}
                    h={560}
                  />
                </Form.Item>
              </Col>
              {/* 內文編輯器 */}
              <Col span={24}>
                <Form.Item name="internalContent" label={i18n.t("internalContent")}
                  rules={[{ required: true, message: `${i18n.t('required')}` }]}>
                  <CKEditorComp data={editorContent} setData={setEditorContent} form={form} fieldName={'internalContent'} image />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Spin>
      </Content >
    </div >
  );
};

export default PageMain;