import { Button, Checkbox, Col, Form, Input, Row, Select, Spin, Table, message } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { Content } from 'antd/es/layout/layout';
import { LayoutNav } from 'components/layout.component';
import { managementi18n, menui18n, operationi18n } from 'constants/permission';
import { CHECKBOX_DISPLAY, PERMISSION_STATE } from 'enum/state';
import useSite from 'hooks/site.hook';
import i18n from 'i18n';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { $get, $post } from 'services';
import { enumToOptions } from 'utils/common';

// 編輯權限

const PageMain: React.FC = () => {
  const navigate = useNavigate();
  const [form] = useForm();
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const { data: $s,isCashVersion: $sc } = useSite();

  // 信用版忽略頁面
  const creditDisabled = [
    'ManagementId-18', // 會員銀行卡列表

    'ManagementId-21', // 實名驗證
    'ManagementId-22', // 銀行卡驗證

    'ManagementId-27', // 提款查詢
    'ManagementId-28', // 入款查詢
    'ManagementId-30', // 轉帳出入款管理
    'ManagementId-32', // 轉帳出入款管理
    'ManagementId-33', // 轉帳出入款管理
    'ManagementId-35', // 轉帳出入款管理
    'ManagementId-36', // 三方出入款管理
    'ManagementId-37', // 三方出入款管理
    'ManagementId-38', // 三方出入款管理
    'ManagementId-39', // 三方出入款管理

    'ManagementId-49', // 代理提款查詢
    'ManagementId-50', // 代理入款查詢
    'ManagementId-52', // 代理銀行卡查詢
  ]

  // 全部權限
  const [allList, setAllList] = useState<any>([]);
  const { data: operationList } = $get({ url: 'api/permission/operation/list' });
  // 建立權限tree物件
  useEffect(() => {
    if (operationList) {
      const data: any = [];

      operationList.Data.forEach((item: any) => {

        const management = data.find((x: any) => x.key === item.ManagementId);
        // 頁面已經推進
        if (management) {
          management.child.push({ key: item.OperationId, name: item.OperationName })

          // 建立頁面
        } else {
          // 信用版忽略頁面
          if ($sc || !creditDisabled.includes(`ManagementId-${item.ManagementId}`)) {
            data.push({
              MenuId: item.MenuId, key: item.ManagementId, name: item.ManagementName, rowSpan: 0, child: [
                { key: item.OperationId, name: item.OperationName }
              ]
            });
            const menu = data.find((x: any) => x.MenuId === item.MenuId);
            if (menu) menu.rowSpan++
          }
        }

      });

      setAllList(data);
    }
  }, [operationList]);

  // 我的權限
  const { data: one, mutate } = $get({ url: 'api/permission/one', params: { Id: id }, allow: !!id });
  useEffect(() => {
    if (one && one.Data && allList.length > 0) {
      form.setFieldsValue({
        Name: one.Data.Name,
        Remark: one.Data.Remark,
        Status: one.Data.Status,
      })

      updateCheckStateOne();
    }
  }, [one, allList]);

  const onFinish = (formData: any) => {
    const permissionCode: any = [];
    allList.forEach((management: any) => {
      management.child.forEach((item: any) => {
        if (formData[`cb-${item.key}`]) permissionCode.push(item.key);
      })
    })
    $post({
      url: 'api/permission/update',
      send: {
        Id: id,
        Name: formData.Name,
        Remark: formData.Remark,
        Status: formData.Status,
        PermissionCode: permissionCode,
      },
      success: () => {
        mutate();
        message.success(i18n.t('saveSuccess'));
        navigate('/advanced/permission_name');
      },
      fail: () => {
        if (permissionCode.length === 0) {
          message.error(i18n.t('permissionsNotChecked'));
        }
      }
    }, setLoading)
  };

  const [menuCheckState, setMenuCheckState] = useState<any>({});
  const updateCheckState = async (record: any, level: any, checked: any, isRead?: any) => {
    setLoading(true);

    const fieldsValue: any = {};

    let menuTotal = 0
    let menuSum = 0
    let managementSum = 0

    allList.forEach((page: any) => {
      managementSum = 0;

      if (page.MenuId === record.MenuId) {
        let readCount = false;
        menuTotal = menuTotal + page.child.length;

        page.child.forEach((item: any, i: number) => {
          let cancelAll = false;
          // 是邊勾邊算 不是勾完再算
          // 計算勾選數量
          if (
            (level === 'menu' && checked) ||
            (page.key === record.key && (
              (level === 'management' && checked) ||
              (level === 'operation' && form.getFieldValue(`cb-${item.key}`))
            ))
          ) {
            managementSum++
            menuSum++
            if (i === 0) readCount = true;
          }
          else if (
            page.key !== record.key && (level === 'management' || level === 'operation') && form.getFieldValue(`cb-${item.key}`)
          ) {
            menuSum++
          }
          // 改變勾選狀態
          if ((level === 'management' && page.key === record.key) || level === 'menu') {
            fieldsValue[`cb-${item.key}`] = checked;
          }
          // 查看自動勾起
          if ((level === 'operation' && page.key === record.key) && checked) {
            if (page.child.length === 2 && !fieldsValue[`cb-${page.child[0].key}`] && !readCount) {
              managementSum++
              menuSum++
              readCount = true;
            }
            fieldsValue[`cb-${page.child[0].key}`] = true;
          // 查看全部取消
          } else if ((level === 'operation' && page.key === record.key && isRead) && !checked) {
            cancelAll = true;
          }
          if (i !== 0 && cancelAll && form.getFieldValue(`cb-${item.key}`)) {
            managementSum--
            menuSum--
            fieldsValue[`cb-${item.key}`] = false;
          }
        })

        if (((level === 'management' || level === 'operation') && page.key === record.key) || level === 'menu') {
          if (managementSum === 0) menuCheckState[`management-${page.key}`] = CHECKBOX_DISPLAY.empty
          else if (managementSum > 0 && managementSum < page.child.length) menuCheckState[`management-${page.key}`] = CHECKBOX_DISPLAY.square
          else if (managementSum === page.child.length) menuCheckState[`management-${page.key}`] = CHECKBOX_DISPLAY.tick
        }
      }
    })

    if (menuSum === 0) menuCheckState[`menu-${record.MenuId}`] = CHECKBOX_DISPLAY.empty
    else if (menuSum > 0 && menuSum < menuTotal) menuCheckState[`menu-${record.MenuId}`] = CHECKBOX_DISPLAY.square
    else if (menuSum === menuTotal) menuCheckState[`menu-${record.MenuId}`] = CHECKBOX_DISPLAY.tick
    form.setFieldsValue({ ...fieldsValue })
    setMenuCheckState({ ...menuCheckState })

    setLoading(false);
  };
  const updateCheckStateOne = async () => {
    const fieldsValue: any = {};
    const permissionCode = JSON.parse(one.Data.PermissionCode);

    let menuTotal = 0
    let menuSum = 0
    let managementSum = 0

    let menuId = allList[0]?.MenuId;

    allList.forEach((page: any, i: number) => {
      managementSum = 0;
      menuTotal = menuTotal + page.child.length;

      // management
      page.child.forEach((item: any) => {
        // 計算勾選數量
        if (permissionCode.includes(String(item.key))) {
          managementSum++
          menuSum++
          fieldsValue[`cb-${item.key}`] = true;
        }
      })
      if (managementSum === 0) menuCheckState[`management-${page.key}`] = CHECKBOX_DISPLAY.empty
      else if (managementSum > 0 && managementSum < page.child.length) menuCheckState[`management-${page.key}`] = CHECKBOX_DISPLAY.square
      else if (managementSum === page.child.length) menuCheckState[`management-${page.key}`] = CHECKBOX_DISPLAY.tick

      // menu
      if ((allList[i + 1]?.MenuId !== menuId) || !allList[i + 1]) {
        if (menuSum === 0) menuCheckState[`menu-${page.MenuId}`] = CHECKBOX_DISPLAY.empty
        else if (menuSum > 0 && menuSum < menuTotal) menuCheckState[`menu-${page.MenuId}`] = CHECKBOX_DISPLAY.square
        else if (menuSum === menuTotal) menuCheckState[`menu-${page.MenuId}`] = CHECKBOX_DISPLAY.tick

        menuId = allList[i + 1]?.MenuId;
        menuSum = 0;
        menuTotal = 0;
      }
    })
    form.setFieldsValue({ ...fieldsValue })
    setMenuCheckState({ ...menuCheckState })

    setLoading(false)
  };

  return (
    <div id="container">
      <LayoutNav />
      <Content className="ph-2 pt-1 pb-2">
        <Spin spinning={loading}>
          <Form layout="vertical" form={form} onFinish={onFinish}>
            <Row>
              {/* 名稱 */}
              <Col span={24}>
                <Row justify="space-between">
                  <Col>
                    <Row gutter={[12, 12]}>
                      <Col span={20}>
                        <Form.Item name="Name" label={i18n.t('permissionName')} rules={[{ required: true }]}>
                          <Input className="w-26" placeholder={`${i18n.t('inputData')}`} maxLength={100} showCount />
                        </Form.Item>
                      </Col>
                      <Col span={20}>
                        <Form.Item name="Remark" label={i18n.t('description')}>
                          <Input.TextArea className="w-26" placeholder={`${i18n.t('inputData')}`} autoSize={{ minRows: 2, maxRows: 1 }} />
                        </Form.Item>
                      </Col>
                      <Col span={20}>
                        <Form.Item name="Status" className="w-26" label={i18n.t('status')} rules={[{ required: true }]}>
                          <Select
                            placeholder={`${i18n.t('pleaseSelect')}`}
                            options={enumToOptions(PERMISSION_STATE)}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                  <Col>
                    <Button className="mr-1" onClick={() => navigate('/advanced/permission_name')}>{i18n.t('cancel')}</Button>
                    <Button type="primary" onClick={form.submit}>{i18n.t('confirm')}</Button>
                  </Col>
                </Row>
              </Col>
              {/* 權限 */}
              <Col className="size-16 font-w-md mt-1">{i18n.t('backOfficePermissions')}</Col>
              <Col span={24} className="mt-1">
                <Table
                  size="middle"
                  bordered
                  dataSource={allList.filter((record: any) => {
                    if (record.MenuId === 9 && !($sc && $s.CommissionModule === '2')) {
                      return false;
                    }
                    return true;
                  })}
                  columns={[
                    {
                      title: <><span className="require">*</span>{i18n.t('page')}</>,
                      width: '25%',
                      onCell: (record: any) => ({ rowSpan: record.rowSpan || 0 }),
                      render: (_, record: any) =>
                        <Checkbox
                          indeterminate={menuCheckState[`menu-${record.MenuId}`] === CHECKBOX_DISPLAY.square}
                          checked={menuCheckState[`menu-${record.MenuId}`] === CHECKBOX_DISPLAY.tick}
                          onChange={e => updateCheckState(record, 'menu', e.target.checked)}
                        >{menui18n[record.MenuId] ? menui18n[record.MenuId]/* + `(${record.MenuId})`*/ : record.MenuId}</Checkbox>,
                    },
                    {
                      className: 'size-12',
                      width: '25%',
                      render: (_, record: any) =>
                        <Checkbox
                          indeterminate={menuCheckState[`management-${record.key}`] === CHECKBOX_DISPLAY.square}
                          checked={menuCheckState[`management-${record.key}`] === CHECKBOX_DISPLAY.tick}
                          onChange={e => updateCheckState(record, 'management', e.target.checked)}
                        >{managementi18n[record.key] ? managementi18n[record.key]/* + `(${record.key})`*/ : record.key}</Checkbox>
                    },
                    {
                      title: i18n.t('permissions'),
                      width: '50%',
                      render: (_, record: any) => record.child.map((item: any) =>
                        <Form.Item key={item.key} name={`cb-${item.key}`} valuePropName="checked" style={{ display: 'inline-block' }}>
                          <Checkbox
                            onChange={e => updateCheckState(record, 'operation', e.target.checked, item.key === record.child[0].key)}
                          >{operationi18n[item.key] ? operationi18n[item.key] : item.key}</Checkbox>
                        </Form.Item>
                      )
                    },
                  ]}
                  pagination={false}
                />
              </Col>
            </Row>
          </Form>
        </Spin>
      </Content>
    </div>
  );
};

export default PageMain;