import { Button, Col, Form, Row, Spin, Table } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { useForm } from 'antd/lib/form/Form';
import { DatePickerCol, Export, LayoutNav } from 'components/layout.component';
import { ViewReportWinLose } from 'components/report.component';
import { ExcelColumns } from 'constants/excel';
import dayjs from 'dayjs';
import { DATE_RANGE_LIMIT, DATE_TYPE } from 'enum/date';
import useAccount from 'hooks/account.hook';
import i18n from 'i18n';
import React, { useState } from 'react';
import { $get } from 'services';
import * as common from 'utils/common';

// 輸贏報表

interface SearchParams {
  StartTime?: string;
  EndTime?: string;
  DateType?: any;
}

const PageMain: React.FC = () => {
  const { permissions: $p } = useAccount();

  const [form] = useForm();
  const [reset, setReset] = useState<boolean>(false);
  const [date, setDate] = useState<any>([
    common.tzShift(dayjs().startOf('month'), 'YYYY-MM-DD HH:mm:ss'),
    common.tzShift(dayjs().endOf('month'), 'YYYY-MM-DD HH:mm:ss'),
    DATE_TYPE.thisMonth
  ]);
  const [params, setParams] = useState<SearchParams>({
    StartTime: common.timeL2S(date[0]),
    EndTime: common.timeL2S(date[1]),
  })
  const { data, mutate, isValidating } = $get({
    url: 'api/report/winloss',
    params
  });
  const onFinish = (formData: any) => {
    if (date) {
      setParams({
        ...params,
        StartTime: common.timeL2S(date[0]),
        EndTime: common.timeL2S(date[1]),
      })
    }
    mutate();
  }

  const onClear = () => {
    setReset(!reset);
  }

  return (
    <div id="container">
      <LayoutNav />
      <Content className="p-2">
        {/* 搜尋列 */}
        <Form form={form} onFinish={onFinish}>
          <Row align="middle" gutter={[12, 12]}>
            <DatePickerCol date={date} setDate={setDate} initDate={reset}
              defaultDateType={DATE_TYPE.thisMonth} rangeLimit={DATE_RANGE_LIMIT.past31Days} />
            <Col><Button type="primary" htmlType="submit">{i18n.t('search')}</Button></Col>
            <Col><Button onClick={onClear}>{i18n.t('clear')}</Button></Col>
          </Row>
        </Form>
        {/* 匯出 */}
        <Col className="mt-1" span={24}>
          <Row justify="space-between">
            {$p('60802') && <Export url={'/report/winloss/export'} param={{
              StartTime: date ? date[0] : dayjs().startOf('day').format('YYYY-MM-DD HH:mm:ss'),
              EndTime: date ? date[1] : dayjs().endOf('day').format('YYYY-MM-DD HH:mm:ss'),
            }} sheetName={i18n.t('memberWinlossReport')} columns={ExcelColumns.ReportWinLose} reverse />}
          </Row>
        </Col>
        <Row className="mt-1" gutter={[10, 10]}>
          <Col span={24}>
            <Table
              loading={isValidating}
              size="middle"
              dataSource={
                [{
                  key: 1,
                  TotalBetCount: data?.TotalRecord?.TotalBetCount,
                  TotalBetAmount: data?.TotalRecord?.TotalBetAmount,
                  TotalWinLossAmount: data?.TotalRecord?.TotalWinLossAmount
                }]
              }
              columns={[
                {
                  dataIndex: 'TotalBetCount',
                  title: i18n.t('totalBetCount'),
                  align: 'right',
                  width: '33%',
                  render: (val) => common.toFormatNumber(val, 0)
                },
                {
                  dataIndex: 'TotalBetAmount',
                  title: i18n.t('totalBetAmounts'),
                  align: 'right',
                  width: '33%',
                  render: (val) => common.toFormatNumber(val)
                },
                {
                  dataIndex: 'TotalWinLossAmount',
                  title: i18n.t('totalWinLossAmount'),
                  align: 'right',
                  width: '33%',
                  render: (val) => <div className={common.numColor01(val)}>{common.toFormatNumber(val)}</div>
                },
              ]}
              pagination={false}
            />
          </Col>
        </Row>
        <Row className="mt-1">
          <Col span={24}>
            <Spin spinning={isValidating}>
              <ViewReportWinLose data={data} date={date} />
            </Spin>
          </Col>
        </Row>
      </Content>
    </div>
  );
};

export default PageMain;