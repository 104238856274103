import { InfoCircleFilled } from '@ant-design/icons';
import { Button, Col, Form, Input, Row, Select, Tooltip } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { Content } from 'antd/es/layout/layout';
import { DatePickerCol, LayoutNav } from 'components/layout.component';
import { DailyList } from 'components/report.component';
import dayjs from 'dayjs';
import { DATE_TYPE } from 'enum/date';
import i18n from 'i18n';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { $get } from 'services';
import * as common from 'utils/common';
import { timeL2S, toFormatNumber as $f } from "utils/common";

// 代理 > 每日報表

interface SearchType {
  AgentId?: number | string;
  StartDate: string | DATE_TYPE;
  EndDate: string | DATE_TYPE;
}

enum TYPE {
  revenue = 1,
  rebate = 2
}

const PageMain: React.FC = () => {

  const { id } = useParams();
  const [tableType, setTableType] = useState(TYPE.revenue);

  const [form] = useForm();
  // NOTE: 預設本週要注意初始值有點煩 優化進選擇器裡
  // 需求: 週一開始
  const [date, setDate] = useState([
    common.tzShift(dayjs().startOf('isoWeek'), 'YYYY-MM-DD HH:mm:ss'),
    common.tzShift(dayjs().endOf('isoWeek'), 'YYYY-MM-DD HH:mm:ss'),
    DATE_TYPE.thisWeek
  ]);
  const [reset, setReset] = useState(false);
  const [resetList, setResetList] = useState(false);

  const [params, setParams] = useState<SearchType>({
    StartDate: timeL2S(date[0] as string),
    EndDate: timeL2S(date[1] as string),
    AgentId: id
  });
  const { data: revenueshareList, mutate: mutateRevenueshare, isValidating: revenueshareValidating } = $get({
    url: 'api/agent/report/revenueshare/list',
    params,
  })
  const { data: commissionList, mutate: mutateCommission, isValidating: commissionValidating } = $get({
    url: 'api/agent/report/commission/list',
    params
  })
  const [account, setAccount] = useState('');
  const { data: agentInfo } = $get({
    url: 'api/agent/one',
    params: { Id: id },
    allow: !!id
  })
  useEffect(() => {
    if (agentInfo && id) {
      form.setFieldsValue({
        agentAccount: agentInfo.Data.Account
      })
      setAccount(agentInfo.Data.Account)
    }
  }, [agentInfo])

  const search = (formData: any) => {
    if (date) {
      setParams({
        ...params,
        StartDate: timeL2S(date[0] as string),
        EndDate: timeL2S(date[1] as string)
      })
      mutateRevenueshare()
      mutateCommission()
      setResetList(!resetList)
    }
  }

  const onClear = () => {
    form.resetFields();
    setReset(!reset);
  }

  return (
    <div id="container">
      <LayoutNav account={account} />
      <Content className="ph-2 pt-1">
        <Form form={form} onFinish={search} initialValues={{
          type: TYPE.revenue
        }}>
          <Row gutter={[12, 12]} className="pb-1">
            <Col span={3}>
              <Form.Item name="agentAccount">
                <Input placeholder={`${i18n.t('masterAgentOrAgentAccount')}`} disabled />
              </Form.Item>
            </Col>
            <Col span={3}>
              <Form.Item name="type">
                <Select
                  className="w-full"
                  onChange={setTableType}
                  options={[
                    { value: 1, label: `${i18n.t('revenueReport')}` },
                    { value: 2, label: `${i18n.t('rebateReport')}` },
                  ]}
                />
              </Form.Item>
            </Col>
            <DatePickerCol width={190} date={date} setDate={setDate} initDate={reset}
              defaultDateType={DATE_TYPE.thisWeek} />
            <Col>
              <Button type="primary" htmlType="submit">{i18n.t('search')}</Button>
            </Col>
            <Col>
              <Button onClick={onClear}>{i18n.t('clear')}</Button>
            </Col>
          </Row>

          {
            // 占成
            tableType === TYPE.revenue &&
            <DailyList loading={revenueshareValidating} data={revenueshareList?.Data} reset={resetList} columns={[
              {
                dataIndex: 'AgentAccount',
                title: i18n.t('account'),
                fixed: 'left',
                width: 200,
                render: (val: any, { AgentLevel, CategoryName }: any) => {
                  const word = common.agentLevelWord(AgentLevel);
                  return CategoryName === 'ALL'
                    ?
                    <span style={{ margin: '2px 8px', display: 'inline-block' }}>
                      {word}／{val}
                    </span>
                    : ''
                }
              },
              {
                dataIndex: 'CategoryName',
                title: i18n.t('gameCategory'),
                fixed: 'left',
                width: 120,
                render: (val: any) => i18n.t(`${val}`)
              },
              {
                dataIndex: 'ValidBetAmount',
                title: i18n.t('betAmountValid'),
                align: 'right',
                width: 150,
                render: (val: any) => $f(val)
              },
              {
                dataIndex: 'WinLoseAmount',
                title: i18n.t('totalMemberWinLoss'),
                align: 'right',
                width: 150,
                render: (val: any) => <div className={common.numColor01(val)}>{$f(val)}</div>
              },
              {
                dataIndex: 'DirectMemberWinLoseAmount',
                title: i18n.t('directMemberWinLoss'),
                align: 'right',
                width: 150,
                render: (val: any) => <div className={common.numColor01(val)}>{$f(val)}</div>
              },
              {
                dataIndex: 'TotalCost',
                title: i18n.t('totalCost'),
                align: 'right',
                width: 150,
                render: (val: any, { CategoryName }: any) => CategoryName === 'ALL' ? $f(val) : '-'
              },
              {
                dataIndex: 'DownlineRevenueShare',
                title: i18n.t('subordinateCommissionRate'),
                align: 'right',
                width: 150,
                render: (val: any) => <div className={common.numColor01(val)}>{$f(val)}</div>
              },
              {
                dataIndex: 'DownlineAgentCost',
                title: i18n.t('subordinateCost'),
                align: 'right',
                width: 150,
                render: (val: any, { CategoryName }: any) => CategoryName === 'ALL' ? $f(val) : '-'
              },
              {
                dataIndex: 'DownlineAgentProfit',
                title: i18n.t('subordinateProfit'),
                align: 'right',
                width: 150,
                render: (val: any, { CategoryName }: any) => CategoryName === 'ALL' ?
                  <div className={common.numColor01(val)}>{$f(val)}</div> : '-'
              },
              {
                dataIndex: 'RevenueShare',
                title: i18n.t('myCommissionRate'),
                align: 'right',
                width: 150,
                render: (val: any) => <div className={common.numColor01(val)}>{$f(val)}</div>
              },
              {
                dataIndex: 'AgentCost',
                title: i18n.t('myCost'),
                align: 'right',
                width: 150,
                render: (val: any, { CategoryName }: any) => CategoryName === 'ALL' ? $f(val) : '-'
              },
              {
                dataIndex: 'AgentProfit',
                fixed: 'right',
                title:
                  <>
                    <Tooltip placement="top" title={i18n.t('sitemasterMyProfitExplains')}>
                      <InfoCircleFilled />
                    </Tooltip>
                    <span style={{ paddingLeft: 3 }}>{i18n.t('myProfit')}</span>
                  </>
                ,
                align: 'right',
                width: 150,
                render: (val: any, { CategoryName }: any) => CategoryName === 'ALL' ?
                  <div className={common.numColor05(val)}>{$f(val)}</div> : '-'
              },
            ]} />
          }
          {
            // 返水
            tableType === TYPE.rebate &&
            <DailyList loading={commissionValidating} data={commissionList?.Data} reset={resetList} columns={[
              {
                dataIndex: 'AgentAccount',
                title: i18n.t('account'),
                fixed: 'left',
                width: 200,
                render: (val: any, { AgentLevel, CategoryName }: any) => {
                  const word = common.agentLevelWord(AgentLevel);
                  return CategoryName === 'ALL'
                    ?
                    <span style={{ margin: '2px 8px', display: 'inline-block' }}>
                      {word}／{val}
                    </span>
                    : ''
                }
              },
              {
                dataIndex: 'CategoryName',
                title: i18n.t('gameCategory'),
                fixed: 'left',
                width: 120,
                render: (val: any) => i18n.t(`${val}`)
              },
              {
                dataIndex: 'ValidBetAmount',
                title: i18n.t('accumulationEffectiveBets'),
                align: 'right',
                width: 300,
                render: (val: any) => $f(val)
              },
              {
                dataIndex: 'DirectMemberValidBetAmount',
                title: i18n.t('directMemberEffectiveBets'),
                align: 'right',
                width: 150,
                render: (val: any) => $f(val)
              },
              {
                dataIndex: 'WinLoseAmount',
                title: i18n.t('winLose'),
                align: 'right',
                width: 300,
                render: (val: any) => <div className={common.numColor01(val)}>{$f(val)}</div>
              },
              // 總返水
              {
                dataIndex: 'TotalCommission',
                title: i18n.t('totalRebate'),
                align: 'right',
                width: 300,
                render: (val: any) => $f(val)
              },
              {
                dataIndex: 'DownlineAgentCommission',
                title: i18n.t('downlineAgentRebate'),
                align: 'right',
                width: 150,
                render: (val: any) => $f(val)
              },
              {
                dataIndex: 'DownlineMemberCommission',
                title: i18n.t('downlineMemberRebate'),
                align: 'right',
                width: 150,
                render: (val: any) => $f(val)
              },
              {
                dataIndex: 'DirectMemberCommission',
                title: i18n.t('directMemberCashback'),
                align: 'right',
                width: 150,
                render: (val: any) => $f(val)
              },
              {
                dataIndex: 'Commission',
                fixed: 'right',
                title: i18n.t('myRebate'),
                align: 'right',
                width: 150,
                render: (val: any) => <div className={common.numColor05(val)}>{$f(val)}</div>
              },
            ]} />
          }
        </Form>
      </Content>
    </div>
  );
};

export default PageMain;