import { CloseCircleOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, Row, Table, Tabs, TabsProps } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { Content } from 'antd/es/layout/layout';
import { DatePickerCol, LayoutNav } from 'components/layout.component';
import { GameCategorySelect } from 'components/member.component';
import { ReportAgentList } from 'components/report.component';
import dayjs from 'dayjs';
import { DATE_RANGE_LIMIT } from 'enum/date';
import useSite from 'hooks/site.hook';
import i18n from 'i18n';
import React, { useEffect, useState } from 'react';
import { $get } from 'services';
import * as common from 'utils/common';
import { toFormatNumber as $f, agentLevelWord, timeL2S } from "utils/common";

// 代理統計分析
// NOTE: 同步BO代理優惠統計, 代理統計分析, AG成本報表

enum TAB {
  代理 = '1',
  會員 = '2',
  遊戲 = '3'
}

const PageMain: React.FC = () => {
  const site = useSite();
  const [activeKey, setActiveKey] = useState<string>(TAB.代理);
  const [formData, setFormData] = useState<any>({})
  const [form] = useForm();

  const items: TabsProps['items'] = [
    {
      key: TAB.代理,
      label: <div className="w-5 text-center">{i18n.t('agent')}</div>
    },
    {
      key: TAB.會員,
      label: <div className="w-5 text-center">{i18n.t('member')}</div>
    },
    {
      key: TAB.遊戲,
      label: <div className="w-5 text-center">{i18n.t('game')}</div>
    },
  ];

  const onTabClick = (key: string) => {
    setParams({ ...params, PageIndex: 1 });
    setActiveKey(key);
    updateTotal(key);
  }

  const [total, setTotal] = useState({
    key: 1,
    TotalCount: 0,
    TotalValidBetAmount: 0,
    TotalWinLoss: 0,
  });
  const updateTotal = (key: any) => {
    switch (key) {
      case TAB.代理:
        setTotal({
          key: 1,
          TotalCount: _saList?.TotalCount,
          TotalValidBetAmount: _saList?.TotalValidBetAmount,
          TotalWinLoss: _saList?.TotalWinLoss,
        })
        break;
      case TAB.會員:
        setTotal({
          key: 1,
          TotalCount: _memberList?.TotalCount,
          TotalValidBetAmount: _memberList?.TotalValidBetAmount,
          TotalWinLoss: _memberList?.TotalWinLoss,
        })
        break;
      case TAB.遊戲:
        setTotal({
          key: 1,
          TotalCount: _gameList?.TotalCount,
          TotalValidBetAmount: _gameList?.TotalValidBetAmount,
          TotalWinLoss: _gameList?.TotalWinLoss,
        })
        break;
    }
  }

  const [date, setDate] = useState([
    common.tzShift(dayjs().startOf('day'), 'YYYY-MM-DD HH:mm:ss'),
    common.tzShift(dayjs().endOf('day'), 'YYYY-MM-DD HH:mm:ss')
  ]);
  const [reset, setReset] = useState<boolean>(false);
  const [resetAgentList, setResetAgentList] = useState<boolean>(false);
  const [params, setParams] = useState<any>({
    StartDate: timeL2S(date[0]),
    EndDate: timeL2S(date[1]),
    PageIndex: 1,
    PageSize: 10,
  });
  const { data: _saList, mutate: mutateSa, isValidating: saValidating } = $get({
    url: 'api/report/bet/ag/list',
    params
  });
  const { data: _memberList, mutate: mutateMember, isValidating: memberValidating } = $get({
    url: 'api/report/bet/member/list',
    params
  });
  const { data: _gameList, mutate: mutateGame, isValidating: gameValidating } = $get({
    url: 'api/report/bet/game/list',
    params
  });
  useEffect(() => {
    updateTotal(activeKey)
  }, [_saList, _memberList, _gameList]);

  const search = (formData: any) => {
    setParams({
      StartDate: timeL2S(date[0]),
      EndDate: timeL2S(date[1]),
      PageIndex: 1,
      PageSize: params.PageSize,
      AgentAccount: formData.AgentAccount,
      MemberAccount: formData.MemberAccount,
      CategoryId: formData.CategoryId,
    })
    mutateSa()
    mutateMember()
    mutateGame()
    setResetAgentList(!resetAgentList)
    setFormData(formData)
  }

  const onClear = () => {
    form.resetFields();
    setReset(!reset)
    setParams({
      ...params,
      PageSize: 10,
      PageIndex: 1,
    })
  }
  const onClearAndToAgent = () => {
    form.setFieldsValue({
      AgentAccount: null,
      MemberAccount: formData.MemberAccount,
      CategoryId: formData.CategoryId
    });
    setParams({
      ...params,
      PageSize: 10,
      PageIndex: 1,
      AgentAccount: "",
    })
    mutateSa()
    mutateMember()
    mutateGame()
    setActiveKey((TAB.代理))
  }

  const searchAgent = (agent: any, tab: any) => {
    setActiveKey(tab);
    setParams({
      ...params,
      PageIndex: 1,
      AgentAccount: agent.Account,
      MemberAccount: formData.MemberAccount,
      CategoryId: formData.CategoryId,
    })
    form.setFieldsValue({
      AgentAccount: agent.Account,
      MemberAccount: formData.MemberAccount,
      CategoryId: formData.CategoryId,
    });
    setFormData((pre: any) => { return { ...pre, AgentAccount: agent.Account } })
  };

  const searchMember = (member: any, tab: any) => {
    setActiveKey(tab);
    setParams({
      ...params,
      PageIndex: 1,
      AgentAccount: formData.AgentAccount,
      MemberAccount: member.Account,
      CategoryId: formData.CategoryId,
    })
    form.setFieldsValue({
      AgentAccount: formData.AgentAccount,
      MemberAccount: member.Account,
      CategoryId: formData.CategoryId,
    });
    setFormData((pre: any) => { return { ...pre, MemberAccount: member.Account } })
  };

  const columns: any = [
    {
      dataIndex: 'Account',
      title: i18n.t('agentAccount'),
      width: 300,
      render: (val: any, record: any) => {
        const word = agentLevelWord(record.Level);
        return `${word}／${val}`
      }
    },
    {
      dataIndex: 'up_count',
      title: i18n.t('teamBetCount'),
      align: 'right',
      width: 200,
      render: (val: any) => $f(val, 0)
    },
    {
      dataIndex: 'up_BetAmount',
      title: i18n.t('teamValidBets'),
      align: 'right',
      width: 200,
      render: (val: any) => $f(val)
    },
    {
      dataIndex: 'up_WinLossAmount',
      title: i18n.t('teamWinLossAmount'),
      align: 'right',
      width: 200,
      render: (val: any) => <div className={common.numColor01(val)}>{$f(val)}</div>
    },
    {
      dataIndex: 'bet_count',
      title: i18n.t('betCounts'),
      align: 'right',
      width: 200,
      render: (val: any) => $f(val, 0)
    },
    {
      dataIndex: 'ValidBetAmount',
      title: i18n.t('betAmountValid'),
      align: 'right',
      width: 200,
      render: (val: any) => $f(val)
    },
    {
      dataIndex: 'WinLossAmount',
      title: i18n.t('totalWinLossAmounts'),
      align: 'right',
      width: 200,
      render: (val: any) => <div className={common.numColor01(val)}>{$f(val)}</div>
    },
    {
      title: i18n.t('operation'),
      width: 200,
      render: (_: any, record: any) => (
        <>
          <Button className="size-12" type="link" onClick={() => searchAgent(record, TAB.會員)}>
            {i18n.t('viewMember')}
          </Button>
          <Button className="size-12" type="link" onClick={() => searchAgent(record, TAB.遊戲)}>
            {i18n.t('viewGame')}
          </Button>
        </>
      )
    },
  ]

  return (
    <div id="container">
      <LayoutNav />
      <Content className="p-2">
        {/* 搜尋列 */}
        <Form form={form} onFinish={search}>
          <Row align="middle" gutter={[12, 12]}>
            <Col className='w-11'>
              <Form.Item name="AgentAccount">
                <Input placeholder={`${i18n.t('agentAccount')}`} addonAfter={<CloseCircleOutlined onClick={onClearAndToAgent} />} />
              </Form.Item>
            </Col>
            <Col className='w-11'>
              <Form.Item name="MemberAccount">
                <Input placeholder={`${i18n.t('memberAccount')}`} />
              </Form.Item>
            </Col>
            <Col className='w-12'>
              <Form.Item name="CategoryId">
                <GameCategorySelect name="CategoryId" form={form} />
              </Form.Item>
            </Col>
            <DatePickerCol width={190} date={date} setDate={setDate} initDate={reset} rangeLimit={DATE_RANGE_LIMIT.past31Days} />
            <Col>
              <Button type="primary" htmlType="submit" loading={saValidating}>{i18n.t('search')}</Button>
            </Col>
            <Col>
              <Button onClick={onClear}>{i18n.t('clear')}</Button>
            </Col>
          </Row>
        </Form>
        {/* 數據總覽 */}
        <Row className="mt-1" gutter={[10, 10]}>
          <Col span={24}>
            <Table
              size="small"
              dataSource={[total]}
              columns={[
                {
                  dataIndex: 'TotalCount',
                  title: i18n.t('totalBetCount'),
                  align: 'right',
                  width: '33%',
                  render: (val) => $f(val, 0)
                },
                {
                  dataIndex: 'TotalValidBetAmount',
                  title: `${i18n.t('totalAg')}${i18n.t('betAmountValid')}`,
                  align: 'right',
                  width: '33%',
                  render: (val) => $f(val)
                },
                {
                  dataIndex: 'TotalWinLoss',
                  title: i18n.t('totalWinLossAmount'),
                  align: 'right',
                  width: '34%',
                  render: (val) => <div className={common.numColor01(val)}>{$f(val)}</div>
                },
              ]}
              pagination={false}
            />
          </Col>
        </Row>
        {/*TODO:*/}
        <Tabs className="mt-1" activeKey={activeKey} items={items} onTabClick={onTabClick} />
        {/* 代理 */}
        {
          activeKey === TAB.代理 &&
          <Row className="mt-1" gutter={[10, 10]}>
            <Col span={24}>
              <ReportAgentList agentSaData={_saList} isValidating={saValidating}
                columns={columns} params={params} setParams={setParams} reset={resetAgentList} />
            </Col>
          </Row>
        }
        {/* 會員 */}
        {
          activeKey === TAB.會員 &&
          <Row className="mt-1" gutter={[10, 10]}>
            <Col span={24}>
              <Table
                size="middle"
                loading={memberValidating}
                dataSource={_memberList?.Data.map((item: any) => ({ key: item.id, ...item }))}
                columns={[
                  {
                    dataIndex: 'Account',
                    title: i18n.t('memberAccount'),
                    width: 300,
                  },
                  {
                    dataIndex: 'up_count',
                    title: i18n.t('teamBetCount'),
                    align: 'right',
                    width: 200,
                    render: (val) => $f(val, 0)
                  },
                  {
                    dataIndex: 'up_BetAmount',
                    title: i18n.t('teamValidBets'),
                    align: 'right',
                    width: 200,
                    render: (val) => $f(val)
                  },
                  {
                    dataIndex: 'up_WinLossAmount',
                    title: i18n.t('teamWinLossAmount'),
                    align: 'right',
                    width: 200,
                    render: (val) => <div className={common.numColor01(val)}>{$f(val)}</div>
                  },
                  {
                    dataIndex: 'bet_count',
                    title: i18n.t('betCounts'),
                    align: 'right',
                    width: 200,
                    render: (val) => $f(val, 0)
                  },
                  {
                    dataIndex: 'ValidBetAmount',
                    title: i18n.t('betAmountValid'),
                    align: 'right',
                    width: 200,
                    render: (val) => $f(val)
                  },
                  {
                    dataIndex: 'WinLossAmount',
                    title: i18n.t('totalWinLossAmounts'),
                    align: 'right',
                    width: 200,
                    render: (val) => <div className={common.numColor01(val)}>{$f(val)}</div>
                  },
                  {
                    title: i18n.t('operation'),
                    width: 200,
                    render: (_, record: any) => (
                      <Button className="size-12" type="link" onClick={() => searchMember(record, TAB.遊戲)}>
                        {i18n.t('viewGame')}
                      </Button>
                    )
                  },
                ]}
                pagination={{
                  showSizeChanger: true,
                  showTotal: (total) => `${i18n.t('overall')} ${total} ${i18n.t('item')}`,
                  total: _memberList?.TotalRecord,
                  current: params.PageIndex,
                  pageSize: params.PageSize
                }}
                onChange={p => setParams({ ...params, PageIndex: p.current, PageSize: p.pageSize })}
              />
            </Col>
          </Row>
        }
        {/* 遊戲 */}
        {
          activeKey === TAB.遊戲 &&
          <Row className="mt-1" gutter={[10, 10]}>
            <Col span={24}>
              <Table
                size="middle"
                loading={gameValidating}
                dataSource={_gameList?.Data.map((item: any) => ({ key: item.GameProvider, ...item }))}
                columns={[
                  {
                    title: i18n.t('gameCategoryGameName'),
                    width: 900,
                    render: (_, record: any) => `${common.specialProviderName(record.GameProvider, site.data.SiteName, record.ProviderCode)}${i18n.t(record.GameCategory)}`
                  },
                  {
                    dataIndex: 'bet_count',
                    title: i18n.t('betCounts'),
                    align: 'right',
                    width: 200,
                    render: (val) => $f(val, 0)
                  },
                  {
                    dataIndex: 'ValidBetAmount',
                    title: i18n.t('betAmountValid'),
                    align: 'right',
                    width: 200,
                    render: (val) => $f(val)
                  },
                  {
                    dataIndex: 'WinLossAmount',
                    title: i18n.t('totalWinLossAmounts'),
                    align: 'right',
                    width: 200,
                    render: (val) => <div className={common.numColor01(val)}>{$f(val)}</div>
                  },
                ]}
                pagination={{
                  showSizeChanger: true,
                  showTotal: (total) => `${i18n.t('overall')} ${total} ${i18n.t('item')}`,
                  total: _gameList?.TotalRecord,
                  current: params.PageIndex,
                  pageSize: params.PageSize
                }}
                onChange={p => setParams({ ...params, PageIndex: p.current, PageSize: p.pageSize })}
              />
            </Col>
          </Row>
        }
      </Content>
    </div>
  );
};

export default PageMain;