import { Button, Col, Form, Input, Row, Select, Spin, Table, Tooltip } from "antd";
import { useForm } from 'antd/es/form/Form';
import { Content } from 'antd/es/layout/layout';
import { DatePickerCol, Export, LayoutNav, LayoutPagination } from 'components/layout.component';
import { ExcelColumns } from 'constants/excel';
import dayjs from 'dayjs';
import { DATE_RANGE_LIMIT, DATE_TYPE } from "enum/date";
import useAccount from 'hooks/account.hook';
import i18n from 'i18n';
import React, { useEffect, useState } from "react";
import { $get } from 'services';
import * as common from 'utils/common';
import { timeL2S, toFormatNumber as $f } from "utils/common";
import { InfoCircleFilled } from "@ant-design/icons";
import { PopupExchangeLine } from "components/people-agent.component";
import { useSocket } from 'contexts/socket.context';
import { PEOPLE_AGENT_OPERATE_STATUS } from "enum/state";

// 全民代理查詢

interface SearchParams {
  MemberAccounts?: string;
  MLMLevelId?: number;
  UPMemberAccount?: string;
  AGAccount?: string;
  SAAccount?: string;
  StartDate: string | DATE_TYPE;
  EndDate: string | DATE_TYPE;
}

const PageMain: React.FC = () => {
  const { peopleAgentAction, peopleAgentStatus } = useSocket();
  const { permissions: $p } = useAccount();
  const [form] = useForm();
  const [page, setPage] = useState<number[]>([1, 10]);
  const [reset, setReset] = useState(false);
  const [date, setDate] = useState([
    common.tzShift(dayjs().startOf('month'), 'YYYY-MM-DD HH:mm:ss'),
    common.tzShift(dayjs().endOf('month'), 'YYYY-MM-DD HH:mm:ss'),
    DATE_TYPE.thisMonth
  ]);
  const [params, setParams] = useState<SearchParams>({
    StartDate: timeL2S(date[0] as string),
    EndDate: timeL2S(date[1] as string),
  });

  const { data: peopleAgentList, isValidating, mutate} = $get({
    url: 'api/peopleagent/list',
    params: {
      ...params,
      PageIndex: page[0],
      PageSize: page[1]
    }
  })

  const onFinish = (formData: any) => {
    setPage([1, page[1]]);
    if (date) {
      setParams({
        ...params,
        MemberAccounts: formData.memberAccounts,
        MLMLevelId: formData.MLMLevelId,
        UPMemberAccount: formData.recommendedMembers,
        AGAccount: formData.agentAccount,
        SAAccount: formData.masterAgent,
        StartDate: timeL2S(date[0] as string),
        EndDate: timeL2S(date[1] as string)
      })
    }
    mutate();
  }

  const onClear = () => {
    setPage([1, 10]);
    setReset(!reset);
    form.resetFields();
  }

  const [isOpenModal, setIsOpenModal] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [memberChanging, setMemberChanging] = useState(false);

  // 取得全民代理操作狀態
  const { data: operateStatus } = $get({ url: 'api/peopleagentcommission/operat/status' })
  const { data: peopleAgentLevelList } = $get({ url: 'api/bonus/peopleagent/level/list' })

  // 初次進入畫面取得按鈕是否disabled
  useEffect(() => {
    switch (operateStatus?.Data?.Action) {
      case null:
        setButtonDisabled(false);
        setMemberChanging(false);
        break;
      case 'allPass':
      case 'batchReject':
        setButtonDisabled(operateStatus.Data.Status === PEOPLE_AGENT_OPERATE_STATUS.executing);
        break;
      case 'recalculate':
        setButtonDisabled(
          operateStatus.Data.Status === PEOPLE_AGENT_OPERATE_STATUS.executing ||
          operateStatus.Data.Status === PEOPLE_AGENT_OPERATE_STATUS.recountResultUnread
        );
        break;
      case 'changeMember':
        setButtonDisabled(operateStatus.Data.Status === PEOPLE_AGENT_OPERATE_STATUS.executing);
        setMemberChanging(true);
        break;
      default:
        break;
    }
  }, [operateStatus]);

  // 聽socket 的Action與Status 決定按鈕是否disabled
  useEffect(() => {
    const [action, time] = (peopleAgentAction || '').split('-');

    switch(action) {
      // 已讀應跳回null
      case 'null':
        setButtonDisabled(false);
        setMemberChanging(false);
        break;
      case 'allPass':
      case 'batchReject':
        setButtonDisabled(peopleAgentStatus === PEOPLE_AGENT_OPERATE_STATUS.executing);
        break;
      case 'recalculate':
        setButtonDisabled(
          peopleAgentStatus === PEOPLE_AGENT_OPERATE_STATUS.executing ||
          peopleAgentStatus === PEOPLE_AGENT_OPERATE_STATUS.recountResultUnread
        );
        break;
      case 'changeMember':
        setButtonDisabled(peopleAgentStatus === PEOPLE_AGENT_OPERATE_STATUS.executing);
        setMemberChanging(true);
        break;
      default:
        break;
    }
  }, [peopleAgentAction]);

  return (
    <div id="container">
      <LayoutNav />
      <Content>
        <Form form={form} onFinish={onFinish} >
          <Row gutter={[12, 12]}>
            <Col span={24}>
              <Row align="middle" gutter={[12, 12]}>
                <Col >
                  <Form.Item name="memberAccounts" rules={[
                    {
                      validator: (_, value) => {
                        if (value) {
                          const accounts = value.split(',').map((account: any) => account.trim()); // 使用逗號分隔帳號
                          // 如果帳號數量超過10筆跳錯誤提示
                          if (accounts.length > 10) {
                            return Promise.reject(new Error(`${i18n.t('maximumOf10MembershipAccounts')}`));
                          }
                        }
                        return Promise.resolve(); // 沒錯誤則通過驗證
                      }
                    }
                  ]}>
                    <Input.TextArea
                      className='w-12'
                      placeholder={`${i18n.t('memberAccount')}${i18n.t('pleaseUseCommaToSeparateTheItems')}`}
                      autoSize={{ minRows: 1, maxRows: 2 }}
                    />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item className="w-12" name="MLMLevelId">
                    <Select
                      placeholder={`${i18n.t('allAgent')}${i18n.t('level')}`}
                      options={[
                      { value: '', label: `${i18n.t('ALL')}` },
                      ...(peopleAgentLevelList?.Data.map((item: any) => (
                        { value: item.Id, label: item.Name }
                      )) || [])
                    ]}/>
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item name="recommendedMembers">
                    <Input className="w-12" placeholder={`${i18n.t('recommendedMembers')}`} />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item name="agentAccount">
                    <Input className="w-12" placeholder={`${i18n.t('agentAccount')}`} />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item name="masterAgent">
                    <Input className="w-12" placeholder={`${i18n.t('masterAgent')}`} />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row align="middle" gutter={[12, 12]}>
                <DatePickerCol width={191} date={date} setDate={setDate} initDate={reset} defaultDateType={DATE_TYPE.today} rangeLimit={DATE_RANGE_LIMIT.past31Days} />
                <Col>
                  <Tooltip placement="top" className="size-12" title={i18n.t('singleQueryMonth')}>
                    <InfoCircleFilled />
                  </Tooltip>
                </Col>
                <Col>
                  <Button type="primary" htmlType="submit">{i18n.t('search')}</Button>
                </Col>
                <Col>
                  <Button onClick={onClear}>{i18n.t('clear')}</Button>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              {$p('80202') && <Export url={'/peopleagent/list/export'} param={{
                ...params, PageSize: peopleAgentList && peopleAgentList.TotalRecord, PageIndex: 1,
              }} sheetName={i18n.t('peopleAgentSearch')} columns={ExcelColumns.peopleAgentSearchExport} />}
            </Col>
            <Col span={24}>
              <Row align="middle" justify="space-between">
                <Col className="color-03">
                  {i18n.t('lastUpdateTime')}：{dayjs(peopleAgentList?.UpdateDateTime).format('YYYY-MM-DD HH:mm:ss')}
                </Col>
                {$p('80203') && 
                <Col>
                  <Button type="primary"
                          onClick={() => setIsOpenModal(true)}
                          disabled={buttonDisabled}>
                    {memberChanging ? i18n.t('changeLineProgress') : i18n.t('currencyExchange')}
                  </Button>
                </Col>}
              </Row>
            </Col>

            <Col span={24}>
              <Spin spinning={isValidating}>
                <Table
                  size="middle"
                  dataSource={peopleAgentList?.Data?.map((item: any, index: number) => ({ ...item, key: index }))}
                  columns={[
                    {
                      dataIndex: 'Account',
                      title: i18n.t('memberAccount'),
                      width: 200,
                    },
                    {
                      dataIndex: 'MLMLevelName',
                      title: `${i18n.t('allAgent')}${i18n.t('level')}`,
                      width: 200,
                      render: (val) => val || '-'
                    },
                    {
                      dataIndex: 'UPMemberAccount',
                      title: i18n.t(`${i18n.t('recommendedMembers')}／${i18n.t('agent')}／${i18n.t('generalAgent')}`),
                      width: 200,
                      render: (val, { AGAccount, SAAccount }: any) => (
                        <>
                          <span>{val || '-'}／</span>
                          <span>{AGAccount || '-'}／</span>
                          <span>{SAAccount || '-'}</span>
                        </>
                      )
                    },
                    {
                      dataIndex: 'DepositAmount',
                      title: i18n.t('depositAmounts'),
                      align: 'right',
                      width: 200,
                      render: (val) => <div>{common.toFormatNumber(val)}</div>
                    },
                    {
                      dataIndex: 'PersionalCommissionAmount',
                      title: i18n.t('personalRebateAmount'),
                      align: 'right',
                      width: 200,
                      render: (val) => <div>{common.toFormatNumber(val)}</div>
                    },
                    {
                      dataIndex: 'DirectCommissionAmount',
                      title: i18n.t('rebateAmountDirectDownline'),
                      align: 'right',
                      width: 200,
                      render: (val) => <div>{common.toFormatNumber(val)}</div>
                    },
                    {
                      dataIndex: 'DirectMemberCount',
                      title: i18n.t('totalNumberCumulativePromoters'),
                      align: 'right',
                      width: 200,
                      render: (val: any) => $f(val, 0)
                    },
                  ]}
                  pagination={false}
                />
                <LayoutPagination total={peopleAgentList ? peopleAgentList.TotalRecord : 0} setPage={setPage} page={page} />
                <PopupExchangeLine isOpen={isOpenModal} close={() => setIsOpenModal(false)} mutate={mutate} />
              </Spin>
            </Col>
          </Row>
        </Form>
      </Content>
    </div >
  );
};

export default PageMain;
