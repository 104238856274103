import { Button, Col, Form, Input, Row, Select, message } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { ViewAccount, ViewAccountHistory } from 'components/account.component';
import { DatePickerCol, LayoutNav } from 'components/layout.component';
import dayjs from 'dayjs';
import { MEMBER_LOG } from 'enum/cashlog';
import { DATE_RANGE_LIMIT, DATE_TYPE } from 'enum/date';
import i18n from 'i18n';
import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import * as common from 'utils/common';
import { timeL2S } from "utils/common";

// 帳戶紀錄

const PageMain: React.FC = () => {

  const { account: _account } = useParams();
  
  // call 元件聖經
  const viewAccount = useRef<any>();
  const viewAccountHistory = useRef<any>();

  const [form] = Form.useForm();
  const [date, setDate] = useState([
    common.tzShift(dayjs().startOf(_account ? 'month' : 'day'), 'YYYY-MM-DD HH:mm:ss'),
    common.tzShift(dayjs().endOf(_account ? 'month' : 'day'), 'YYYY-MM-DD HH:mm:ss'),
    _account ? DATE_TYPE.thisMonth : DATE_TYPE.today
  ]);
  const [reset, setReset] = useState<boolean>(false);
  const [page, setPage] = useState<number[]>([1, 10]);
  const [params, setParams] = useState<any>({
    Account: _account || null,
    StartDate: timeL2S(date[0]),
    EndDate: timeL2S(date[1])
  })
  // 上一次的搜尋參數
  const [oldParams, setOldParams] = useState<any>({})

  useEffect(() => {
    if (_account) {
      form.setFieldsValue({
        Account: _account
      });
      setOldParams({ 
        Account: _account,
        StartDate: timeL2S(date[0]as string).toString(),
        EndDate: timeL2S(date[1]as string).toString(),
      })
    }
  }, [_account]);

  // 聖經: 不要打兩次api
  const search = (formData: any) => {
    const newParams = {
      ...params,
      Account: formData.Account,
      StartDate: timeL2S(date[0]as string).toString(),
      EndDate: timeL2S(date[1]as string).toString(),
    };
    // 如果搜尋參數相同只用mutate
    if (common.objectsEqual(newParams, oldParams)) {
      viewAccount.current?.mutate();
      viewAccountHistory.current?.mutate();
    // 不一樣才更新搜尋參數
    } else {
      setPage([1, page[1]]);
      if (date) {
        setParams({ ...newParams })
        setOldParams({ ...newParams })
      }
    }
  };

  const onClear = () => {
    form.resetFields();
    setReset(!reset);
    setPage([1, 10]);
  }

  return (
    <div id="container">
      <LayoutNav />
      <Content>
        {/* 搜尋 */}
        <Row gutter={[12, 12]}>
          {/* 搜尋條件 */}
          <Col span={24}>
            <Form form={form} onFinish={search}>
              <Row align="top" gutter={[12, 12]}>
                <Col className="w-12">
                  <Form.Item name="Account" 
                    rules={[{ required: true, message: `${i18n.t('inputData')}${i18n.t('memberAccount')}` }]}>
                    <Input placeholder={`${i18n.t('memberAccount')}`} />
                  </Form.Item>
                </Col>
                <DatePickerCol width={190} date={date} setDate={setDate} initDate={reset}
                  defaultDateType={_account ? DATE_TYPE.thisMonth : DATE_TYPE.today} rangeLimit={DATE_RANGE_LIMIT.past31Days} />
                <Col>
                  <Button type="primary" htmlType="submit">{i18n.t('search')}</Button>
                </Col>
                <Col>
                  <Button onClick={onClear}>{i18n.t('clear')}</Button>
                </Col>
              </Row>
            </Form>
          </Col>
          {/* 當日數據 */}
          <Col span={24}>
            <ViewAccount refs={viewAccount} params={params} />
          </Col>
          {/* 交易紀錄 */}
          <Col span={24}>
            <Select
              className="w-12"
              defaultValue={''}
              options={[
                { value: '', label: i18n.t('allTransactionTypes') },
                ...common.enumToOptions(MEMBER_LOG)
              ]}
              onChange={(v) => {
                setParams({ ...params, KeyId: v })
                setPage([1, page[1]]);
              }}
            />
          </Col>
          <Col span={24}>
            <ViewAccountHistory refs={viewAccountHistory} params={params} page={page} setPage={setPage} />
          </Col>
        </Row>
      </Content>
    </div>
  );
};

export default PageMain;