import { DatabaseFilled } from '@ant-design/icons';
import { Button, Checkbox, Col, Form, Input, Popover, Row, Select, Table, message } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { Content } from 'antd/es/layout/layout';
import { DatePickerCol, Export, InquiryWindow, LayoutNav, LayoutPagination } from "components/layout.component";
import { RESPONSE_CODE_REBATE } from 'constants/response';
import dayjs from 'dayjs';
import { DATE_RANGE_LIMIT } from 'enum/date';
import { SETTLE_CYCLE } from "enum/promotion";
import { PEOPLE_AGENT_OPERATE_STATUS, PROMOTION_REBATE_STATE } from "enum/state";
import useAccount from 'hooks/account.hook';
import i18n from 'i18n';
import React, { useEffect, useState } from "react";
import { $get, $post } from 'services';
import * as common from 'utils/common';
import { timeL2S, timeS2L } from "utils/common";
import { CASHBACK_TYPE } from "../../../enum/rebate";
import { convertedToPercentage01 as $g } from 'utils/common';
import {
  PopupAllpassPeopleAgentPromotionRebate,
  PopupSingleRecountInfo,
  PopupRecountInfo,
  PopupRebateRecount,
} from "components/people-agent.component";
import { ExcelColumns } from "constants/excel";
import { useSocket } from 'contexts/socket.context';
import useSite from 'hooks/site.hook';
import { SETTLE_METHOD } from 'enum/role';

// 全民代理返水查詢

const PageMain: React.FC = () => {
  const { data: $s} = useSite();
  const { peopleAgentAction, peopleAgentStatus } = useSocket();
  const { permissions: $p } = useAccount();
  const [form] = useForm();
  const [allPass, setAllPass] = useState(false);
  const [allReject, setAllReject] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [page, setPage] = useState<number[]>([1, 10]);
  const [reset, setReset] = useState(false);
  const [isOpenBatchRejectModal, setIsOpenBatchRejectModal] = useState(false);
  const [isModalOpenAllpass, setIsModalOpenAllpass] = useState(false);
  const [isOpenSingleRecountInfo, setIsOpenSingleRecountInfo] = useState(false);
  const [recountInfo, setRecountInfo] = useState(null);
  const [isShowRecountInfoButton, setIsShowRecountInfoButton] = useState(false);
  const [isOpenRecountInfo, setIsOpenRecountInfo] = useState(false);
  const [isOpenPopupRebateRecount, setIsOpenPopupRebateRecount] = useState(false);

  const [date, setDate] = useState([
    common.tzShift(dayjs().startOf('day'), 'YYYY-MM-DD HH:mm:ss'),
    common.tzShift(dayjs().endOf('day'), 'YYYY-MM-DD HH:mm:ss')
  ]);
  const [params, setParams] = useState<any>({
    StartDate: timeL2S(date[0]),
    EndDate: timeL2S(date[1]),
    TimeCondition: 0
  });

  const { data: rebateList, isValidating , mutate} = $get({
    url: 'api/peopleagentcommission/list',
    params: {
      ...params,
      PageIndex: page[0],
      PageSize: page[1]
    }
  })

  const onFinish = (formData: any) => {
    if (date) {
      setParams({
        Code: formData.order,
        MemberAccount: formData.memberAccount,
        MLMLevelName: formData.MLMLevelName,
        Status: formData.status,
        UPMemberAccount: formData.recommendedMembers,
        AGAccount: formData.agentAccount,
        SAAccount: formData.masterAgent,
        MLMType: formData.cashbackType,
        CategoryId: formData.gameCategory,
        TimeCondition: formData.TimeCondition,
        StartDate: timeL2S(date[0]),
        EndDate: timeL2S(date[1]),
      })
    }
    mutate();
  }

  const onClear = () => {
    setPage([1, 10]);
    setReset(!reset);
    form.resetFields();
  }

  const colorChange = (type: number) => {
    switch (type) {
      case PROMOTION_REBATE_STATE.received:
      case PROMOTION_REBATE_STATE.dispatched:
        return 'color-pass'
      case PROMOTION_REBATE_STATE.pendingDistribution:
        return 'color-padding'
      case PROMOTION_REBATE_STATE.reject:
        return 'color-reject'
    }
  }

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  // 批次拒絕
  const handleAllReject = () => {
    $post({
      url: 'api/peopleagentcommission/batch/reject',
      send: { Ids: selectedRowKeys },
      success: () => {
        message.success(i18n.t('operationSuccess'));
        setSelectedRowKeys([]);
        mutate();
      },
      fail: (response: any) => {
        if (response.Message.includes("STATUS_FAIL")) {
          message.error(i18n.t('operationRepeatedPleaseRefreshPage'));
        } else {
          message.error(RESPONSE_CODE_REBATE[response.Message as keyof typeof RESPONSE_CODE_REBATE]);
        }
      },
      showMessage: false,
      resCode: RESPONSE_CODE_REBATE
    }, setAllReject)
  }

  // 聖經欄位顯示設定
  const [columns, setColumns] = useState<any>([
    {
      dataIndex: 'Code',
      title: i18n.t('order'),
      width: 250,
      fixed: 'left',
    },
    {
      dataIndex: 'MemberAccount',
      title: i18n.t('memberAccount'),
      width: 170,
      fixed: 'left',
    },
    {
      dataIndex: 'MLMLevel',
      title: `${i18n.t('allAgent')}${i18n.t('level')}`,
      width: 120,
      render: (val: any) => val || '-'
    },
    {
      dataIndex: 'UpMemberAccount',
      title: `${i18n.t('recommendedMembers')}／${i18n.t('agent')}／${i18n.t('generalAgent')}`,
      width: 250,
      render: (_: any, record: any) => (
        <>
          <span>{record.UpMemberAccount || '-'}</span><span> / </span>
          <span>{record.AGAccount || '-'}</span><span> / </span>
          <span>{record.SAAccount || '-'}</span>
        </>
      )
    },
    {
      dataIndex: 'CommissionCycle',
      title: i18n.t('rebatePeriod'),
      width: 80,
      render: (val: any) => i18n.t(SETTLE_CYCLE[val])
    },
    {
      dataIndex: 'ValidBetAmount',
      title: i18n.t('betAmountValid'),
      align: 'right',
      width: 170,
      render: (val: any) => common.toFormatNumber(val)
    },
    {
      // 返水類型
      dataIndex: 'Type',
      title: i18n.t('cashbackType'),
      width: 200,
      render: (val: any, record:any ) =>
        (val === CASHBACK_TYPE.personalRebateRecalculation || val === CASHBACK_TYPE.rebateForDirectDownlineValidMembersRecalculation) 
        ? (
          <Button className="size-12 p-0" type="link" onClick={() => {
            setRecountInfo(record);
            setIsOpenSingleRecountInfo(true);
          }}>
            {i18n.t(CASHBACK_TYPE[val])}
          </Button>
        ) : (
          i18n.t(CASHBACK_TYPE[val])
        ),
    },
    {
      dataIndex: 'CommissionRate',
      title: i18n.t('rebateRatio'),
      width: 90,
      render: (val: any) => `${$g(val)}%` || '-'
    },
    {
      dataIndex: 'Amount',
      title: i18n.t('amount'),
      align: 'right',
      width: 150,
      render: (val: any) => common.toFormatNumber(val)
    },
    {
      dataIndex: 'ValidBetTimes',
      title: i18n.t('rolloverRate'),
      align: 'right',
      width: 80,
    },
    {
      dataIndex: 'GameCategoryCode',
      title: i18n.t('gameCategory'),
      width: 100,
      render: (val: any) => i18n.t(`${val}`)
    },
    {
      dataIndex: 'CreateDate',
      title: i18n.t('createTime'),
      width: 160,
      render: (val: any) => timeS2L(val)
    },
    {
      dataIndex: 'DeliveryDate',
      title: i18n.t('distributionTime'),
      width: 160,
      render: (val: any) => timeS2L(val)
    },
    {
      dataIndex: 'ReceivedTime',
      title: i18n.t('takeTime'),
      width: 160,
      render: (val: any) => timeS2L(val)
    },
    {
      dataIndex: 'Status',
      title: i18n.t('status'),
      width: 150,
      render: (val: any, { OperatorAccount }: any) => (
        <>
          <div className={colorChange(val)}>
            {i18n.t(PROMOTION_REBATE_STATE[val])}
          </div>
          {
            val === PROMOTION_REBATE_STATE.pendingDistribution ?
              <div>
                {i18n.t('from')} {'System'} {i18n.t('add')}
              </div> :
              <div>
                {i18n.t('from')} {OperatorAccount || 'System'} {val === PROMOTION_REBATE_STATE.reject ? i18n.t('reject') : i18n.t('distribution')}
              </div>
          }
        </>
      )
    },
  ]?.map((item, i) => ({ ...item, key: i, show: true })));

  const updateColumnShow = (key: string, checked: boolean) => {
    columns.find((item: any) => {
      if (item.dataIndex === key) {
        item.show = checked;
        return true
      }
    })
    setColumns([...columns]);
  };

  // 全民代理等級
  const { data: peopleAgentLevelList } = $get({ url: 'api/bonus/peopleagent/level/list' })
  // 遊戲大類
  const { data: gameCategory } = $get({ url: 'api/category/list', params: { Status: 1 } })
  // 取得全民代理操作狀態
  const { data: operateStatus } = $get({ url: 'api/peopleagentcommission/operat/status' })
  // 全民代理上一週期設定值
  const { data: settingInfo } = $get({ url: 'api/peopleagentcommission/previous/history/settings' })

  const [allPassDisabled, setAllPassDisabled] = useState(false);
  const [batchRejectDisabled, setBatchRejectDisabled] = useState(false);
  const [recalculateDisabled, setRecalculateDisabled] = useState(false);

  // 初次進入畫面取得按鈕是否disabled
  // 初次進入畫面取得重計結果按鈕是否出現
  useEffect(() => {
    switch (operateStatus?.Data?.Action) {
      case null:
        setAllPassDisabled(false);
        setBatchRejectDisabled(false);
        setRecalculateDisabled(false);
        setIsShowRecountInfoButton(false);
        break;
      case 'allPass':
      case 'batchReject':
      case 'changeMember':
        setAllPassDisabled(operateStatus.Data.Status === PEOPLE_AGENT_OPERATE_STATUS.executing);
        setBatchRejectDisabled(operateStatus.Data.Status === PEOPLE_AGENT_OPERATE_STATUS.executing);
        setRecalculateDisabled(operateStatus.Data.Status === PEOPLE_AGENT_OPERATE_STATUS.executing);
        break;
      case 'recalculate':
        if (operateStatus.Data.Status === PEOPLE_AGENT_OPERATE_STATUS.executing) {
          setAllPassDisabled(true);
          setBatchRejectDisabled(true);
          setRecalculateDisabled(true);
          setIsShowRecountInfoButton(false);
        } else if (operateStatus.Data.Status === PEOPLE_AGENT_OPERATE_STATUS.recountResultUnread) {
          setAllPassDisabled(true);
          setBatchRejectDisabled(true);
          setRecalculateDisabled(false);
          setIsShowRecountInfoButton(true);
        }
        break;
      default:
        break;
    }
  }, [operateStatus]);

  // 聽socket 的Action與Status 決定按鈕是否disabled
  // 聽socket 的Action與Status 決定重計結果按鈕是否出現
  useEffect(() => {
    const [action, time] = (peopleAgentAction || '').split('-');

    switch(action) {
      // 已讀應跳回null
      case 'null':
          setAllPassDisabled(false);
          setBatchRejectDisabled(false);
          setRecalculateDisabled(false);
          setIsShowRecountInfoButton(false);
        break;
      case 'allPass':
      case 'batchReject':
      case 'changeMember':
          setAllPassDisabled(peopleAgentStatus === PEOPLE_AGENT_OPERATE_STATUS.executing);
          setBatchRejectDisabled(peopleAgentStatus=== PEOPLE_AGENT_OPERATE_STATUS.executing);
          setRecalculateDisabled(peopleAgentStatus === PEOPLE_AGENT_OPERATE_STATUS.executing);
        break;
      case 'recalculate':
        if (peopleAgentStatus === PEOPLE_AGENT_OPERATE_STATUS.executing) {
          setAllPassDisabled(true);
          setBatchRejectDisabled(true);
          setRecalculateDisabled(true);
          setIsShowRecountInfoButton(false);
        } else if (peopleAgentStatus === PEOPLE_AGENT_OPERATE_STATUS.recountResultUnread) {
          setAllPassDisabled(true);
          setBatchRejectDisabled(true);
          setRecalculateDisabled(false);
          setIsShowRecountInfoButton(true);
        }
        break;

      default:
        break;
    }
  }, [peopleAgentAction]);

  // 用於返水重計按鈕 當日00:00:00-11:59:59 disabled / 12:00:00 - 23:59:59 enabled
  const [isOver1200, setIsOver1200] = useState<boolean>(false);

  useEffect(() => {
    const interval = setInterval(() => {
      const timeNow = dayjs().tz($s && $s.TimeZone).format('YYYY-MM-DD HH:mm:ss');
      // 獲取當前時間的小時數
      const currentHour = dayjs(timeNow).hour();
      // 判斷目前時間是否超過中午12點
      setIsOver1200(currentHour >= 12);
    }, 1000);
    return () => clearInterval(interval);
  }, [$s]);

  return (
    <div id="container">
      <LayoutNav />
      <Content className="ph-2 pt-1">
        <Form form={form} onFinish={onFinish} initialValues={{
          TimeCondition: 0,
        }}>
          {/* 搜尋第一排 */}
          <Row gutter={10} align="middle">
            <Col className="w-12">
              <Form.Item name="order">
                <Input placeholder={`${i18n.t('order')}`} />
              </Form.Item>
            </Col>
            <Col className="w-12">
              <Form.Item name="memberAccount">
                <Input placeholder={`${i18n.t('memberAccount')}`} />
              </Form.Item>
            </Col>
            <Col className="w-12">
              <Form.Item  name="MLMLevelName">
                <Select
                  className="w-full"
                  placeholder={`${i18n.t('allAgent')}${i18n.t('level')}`}
                  options={[
                    { value: '', label: `${i18n.t('ALL')}` },
                    ...(peopleAgentLevelList?.Data?.map((item: any) => (
                      { value: item.Name, label: item.Name }
                    )) || [])
                  ]}/>
              </Form.Item>
            </Col>
            <Col className="w-12">
              <Form.Item name="status">
                <Select
                  className="w-full"
                  placeholder={i18n.t('status')}
                  options={[
                    { value: '', label: `${i18n.t('ALL')}` },
                    ...common.enumToOptions(PROMOTION_REBATE_STATE)
                  ]}
                />
              </Form.Item>
            </Col>
            <Col className="w-12">
              <Form.Item name="recommendedMembers">
                <Input placeholder={`${i18n.t('recommendedMembers')}`} />
              </Form.Item>
            </Col>
            <Col className="w-12">
              <Form.Item name="agentAccount">
                <Input placeholder={`${i18n.t('agentAccount')}`} />
              </Form.Item>
            </Col>
            <Col className="w-12">
              <Form.Item name="masterAgent">
                <Input  placeholder={`${i18n.t('masterAgent')}`} />
              </Form.Item>
            </Col>
          </Row>
          {/* 搜尋第二排 */}
          <Row gutter={10} className="mt-1">
            <Col className="w-12">
              <Form.Item name="cashbackType">
                <Select
                  className="w-full"
                  placeholder={i18n.t('cashbackType')}
                  options={[
                    { value: '', label: `${i18n.t('ALL')}` },
                    ...common.enumToOptions(CASHBACK_TYPE)
                  ]}
                />
              </Form.Item>
            </Col>

            <Col className="w-12">
              <Form.Item name="gameCategory">
                <Select
                  placeholder={i18n.t('gameCategory')}
                  options={[
                    { value: '', label: `${i18n.t('ALL')}` },  // 添加 "ALL" 选项
                    ...(gameCategory?.Data?.map((option: any) => ({
                      value: option.Id,
                      label: i18n.t(option.Name),
                    })) || []),
                  ]}
                />
              </Form.Item>
            </Col>
            <Col className="w-12">
              <Form.Item name="TimeCondition">
                <Select
                  className="w-full"
                  options={[
                    { value: 0, label: `${i18n.t('createTime')}` },
                    { value: 1, label: `${i18n.t('distributionTime')}` },
                    { value: 2, label: `${i18n.t('takeTime')}` },
                  ]}
                />
              </Form.Item>
            </Col>
            <DatePickerCol width={181} date={date} setDate={setDate} initDate={reset} rangeLimit={DATE_RANGE_LIMIT.past31Days} />
            <Col><Button type="primary" htmlType="submit">{i18n.t('search')}</Button></Col>
            <Col><Button onClick={onClear}>{i18n.t('clear')}</Button></Col>
          </Row>
          {/* 總計 */}
          <Row gutter={[16, 16]} className="mt-1">
            <Col span={24}>
              <Table
                loading={isValidating}
                size="small"
                dataSource={[{
                  key: 1,
                  TotalRecord: rebateList?.TotalRecord || 0,
                  TotalAmount: rebateList?.TotalAmount || 0
                }]}
                columns={[
                  {
                    dataIndex: 'TotalRecord',
                    title: i18n.t('totalCount'),
                    align: "right",
                    width: '50%'
                  },
                  {
                    dataIndex: 'TotalAmount',
                    title: i18n.t('totalRebate'),
                    align: "right",
                    width: '50%',
                    render: (val) => common.toFormatNumber(val)
                  },
                ]}
                pagination={false}
              />

            </Col>
          </Row>
          {/* 全部派發 顯示設定 */}
          <Row gutter={[16, 16]} className="mt-1" justify="space-between">
            <Col>
              {
                $p('30402') &&
                <Row align="middle" gutter={[12, 12]}>
                  {/* 匯出 */}
                  {$p('80302') &&
                  <Col>
                    <Export url={'/peopleagentcommission/list/export'} param={{
                      ...params, PageSize: rebateList && rebateList.TotalRecord, PageIndex: 1,
                    }} sheetName={i18n.t('peopleAgentRebateSearch')} columns={ExcelColumns.peopleAgentRebateSearchExport} />
                  </Col>}
                  {/* 批次拒絕 */}
                  {$p('80303') &&
                  <Col>
                    <Button
                      type="primary" ghost danger
                      disabled={
                        selectedRowKeys.length === 0 || 
                        batchRejectDisabled || 
                        settingInfo?.Data.SettleMethod !== SETTLE_METHOD.manualDispatch
                      }
                      loading={allReject}
                      onClick={() => setIsOpenBatchRejectModal(true)}
                    >{i18n.t('batchReject')}</Button>
                  </Col>}
                  {/* 全部派發 */}
                  {$p('80303') && 
                  <Col>
                    <Button
                      type="primary"
                      disabled={allPassDisabled || settingInfo?.Data.SettleMethod !== SETTLE_METHOD.manualDispatch}
                      loading={allPass}
                      onClick={() => setIsModalOpenAllpass(true)}
                    >{i18n.t('distributeAll')}
                    </Button>
                  </Col>}
                  {/* 返水重計 */}
                  {$p('80304') && !isShowRecountInfoButton && 
                  <Col>
                    <Button
                      type="primary" ghost
                      onClick={() =>setIsOpenPopupRebateRecount(true)}
                      disabled={recalculateDisabled || !isOver1200}
                    >
                      {operateStatus?.Data?.Action === 'recalculate' &&
                       operateStatus?.Data?.Status === PEOPLE_AGENT_OPERATE_STATUS.executing
                        ? i18n.t('rebateRecalculationProgress') : i18n.t('rebateRecalculation')}
                    </Button>
                  </Col>}
                  {/* 重計結果 */}
                  {$p('80304') && isShowRecountInfoButton && 
                  <Col>
                    <Button
                      type="primary" ghost
                      onClick={() => setIsOpenRecountInfo(true)}
                    >{i18n.t('recalculationResult')}
                    </Button>
                  </Col>}
                </Row>
              }
            </Col>
            {/* 顯示設定 */}
            <Col>
              <Popover
                placement="bottomRight"
                trigger="click"
                content={
                  <div>
                    {columns?.map((item: any, i: number) =>
                      <div key={i}>
                        <Checkbox checked={item.show} onChange={e => updateColumnShow(item.dataIndex, e.target.checked)}>
                          {item.title}
                        </Checkbox>
                      </div>
                    )}
                  </div>
                }
              >
                <Button><DatabaseFilled /></Button>
              </Popover>
            </Col>
          </Row>

          <Table
            loading={isValidating}
            size="small"
            className="mt-1"
            scroll={{ x: 2000 }}
            rowSelection={{
              selectedRowKeys,
              onChange: onSelectChange,
              getCheckboxProps: (record: any) => ({
                disabled:
                  record.status === PROMOTION_REBATE_STATE.dispatched ||
                  record.status === PROMOTION_REBATE_STATE.received ||
                  record.status === PROMOTION_REBATE_STATE.reject
              })
            }}
            dataSource={rebateList?.Data?.map((item: any) => ({ key: item.Id, ...item })) || []}
            columns={columns.filter((item: any) => item.show) as any}
            pagination={false}
          />
          <LayoutPagination total={rebateList && rebateList.TotalRecord} setPage={setPage} page={page} />
        </Form>
      </Content>

      <PopupAllpassPeopleAgentPromotionRebate isOpen={isModalOpenAllpass} close={() => setIsModalOpenAllpass(false)} mutate={mutate} 
        setAllPass={setAllPass} selectedRowKeys={selectedRowKeys} setSelectedRowKeys={setSelectedRowKeys} />
      <InquiryWindow isOpen={isOpenBatchRejectModal} close={() => setIsOpenBatchRejectModal(false)}
        msg={`${i18n.t('confirmBatchRejection')} ${selectedRowKeys.length} ${i18n.t('dataEntries')}`} action={handleAllReject} />
      <PopupRecountInfo isOpen={isOpenRecountInfo} close={() => setIsOpenRecountInfo(false)} />
      {/* 返水重計 */}
      <PopupRebateRecount isOpen={isOpenPopupRebateRecount} close={() => setIsOpenPopupRebateRecount(false)} mutate={mutate} />
      {/* 返水重計結果 */}
      <PopupSingleRecountInfo isOpen={isOpenSingleRecountInfo} close={() => setIsOpenSingleRecountInfo(false)} recountInfo={recountInfo}/>
    </div>
  );
};

export default PageMain;