import { Button, Col, Descriptions, Form, Input, Row, Select, Spin, Table } from "antd";
import { useForm } from "antd/lib/form/Form";
import { Content } from "antd/lib/layout/layout";
import { CopyButton, DatePickerCol, Export, LayoutNav, LayoutPagination } from "components/layout.component";
import { ExcelColumns } from "constants/excel";
import dayjs from "dayjs";
import { COUPON_EXPORT_STATUS } from "enum/coupon";
import { DATE_RANGE_LIMIT, DATE_TYPE } from "enum/date";
import useAccount from "hooks/account.hook";
import i18n from "i18n";
import { useState } from "react";
import { $get } from "services";
import { timeS2L, toFormatNumber, tzShift } from "utils/common";
import useSite from "../../../hooks/site.hook";

// 兌換碼優惠查詢

const PageMain: React.FC = () => {
  const [form] = useForm();
  const { permissions: $p } = useAccount();
  const [page, setPage] = useState<number[]>([1, 10]);
  const [reset, setReset] = useState(false);
  // 需求: 週一開始
  const [date, setDate] = useState([
    tzShift(dayjs().startOf('isoWeek'), 'YYYY-MM-DD HH:mm:ss'),
    tzShift(dayjs().endOf('isoWeek'), 'YYYY-MM-DD HH:mm:ss'),
    DATE_TYPE.thisWeek
  ]);
  const { Domain } = useSite().data;
  const [params, setParams] = useState<CouponQueryListQueryType>({
    TimeCondition: 1,
    StartDate: date[0],
    EndDate: date[1],
  });

  /* 兌換碼活動名稱列表 */
  const {
    data: couponNameList,
    isValidating: couponNameListIsLoading
  } = $get({
    url: 'api/bonus/redemption/name/list',
  });

  /* 兌換碼列表 */
  const {
    data: couponList,
    mutate,
    isValidating: couponListIsLoading
  } = $get({
    url: 'api/redemptioncode/list',
    params: {
      ...params,
      PageIndex: page[0],
      PageSize: page[1]
    }
  });

  const onFinish = (formData: any) => {
    setPage([1, page[1]]);
    setParams({
      ...params,
      StartDate: date[0],
      EndDate: date[1],
      AgentAccount: formData.AgentAccount,
      BonusTicketCode: formData.BonusTicketCode,
      MemberAccount: formData.MemberAccount,
      RedemptionCode: formData.RedemptionCode,
      BonusId: formData.BonusId,
      Status: formData.Status,
      TimeCondition: formData.TimeCondition,
    });
    mutate();
  };

  const onClear = () => {
    form.resetFields();
    setReset(!reset);
    setPage([1, 10]);
  };

  return (
    <div id="container">
      <LayoutNav />
      <Content>
        <Form form={form} onFinish={onFinish} initialValues={{
          TimeCondition: 1,
        }}>
          <Row gutter={[12, 12]}>
            <Col span={24}>
              <Row gutter={[12, 12]}>
                <Col className="w-12">
                  <Form.Item name="AgentAccount">
                    <Input placeholder={`${i18n.t('agentAccount')}`} />
                  </Form.Item>
                </Col>
                <Col className="w-12">
                  <Form.Item name="BonusTicketCode">
                    <Input placeholder={`${i18n.t('order')}`} />
                  </Form.Item>
                </Col>
                <Col className="w-12">
                  <Form.Item name="MemberAccount">
                    <Input placeholder={`${i18n.t('memberAccount')}`} />
                  </Form.Item>
                </Col>
                <Col className="w-12">
                  <Form.Item name="RedemptionCode">
                    <Input placeholder={`${i18n.t('couponCode')}`} />
                  </Form.Item>
                </Col>
                <Col className="w-12" >
                  <Form.Item name="BonusId">
                    <Select
                      loading={couponNameListIsLoading}
                      showSearch
                      optionFilterProp="label"
                      placeholder={`${i18n.t('promotionName')}`}
                      options={
                        couponNameList ? [
                          { value: '', label: i18n.t('ALL') },
                          ...couponNameList?.Data?.map((item: { Id: number, Name: string }) => (
                            { value: item.Id, label: item.Name }
                          ))
                        ] : []}
                    />
                  </Form.Item>
                </Col>
                <Col className="w-12" >
                  <Form.Item name="Status">
                    <Select
                      placeholder={`${i18n.t('status')}`}
                      options={[
                        { value: '', label: i18n.t('ALL') },
                        { value: 1, label: i18n.t('received') },
                        { value: 0, label: i18n.t('unclaimed') }
                      ]}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row gutter={[12, 12]}>
                <Col className="w-12">
                  <Form.Item name="TimeCondition">
                    <Select
                      options={[
                        { value: 1, label: i18n.t('createTime') },
                        { value: 0, label: i18n.t('takeTime') }
                      ]}
                    />
                  </Form.Item>
                </Col>
                <DatePickerCol width={180} date={date} setDate={setDate} initDate={reset}
                  defaultDateType={DATE_TYPE.thisWeek} />
                <Col>
                  <Button type="primary" htmlType="submit">{i18n.t('search')}</Button>
                </Col>
                <Col>
                  <Button onClick={onClear}>{i18n.t('clear')}</Button>
                </Col>
              </Row>
            </Col>
            {/* 總計 */}
            <Col span={24}>
              <Spin spinning={couponListIsLoading}>
                <Descriptions size="small" layout="vertical" column={3} bordered
                  contentStyle={{ display: 'flex', justifyContent: 'end' }}
                  labelStyle={{ display: 'flex', justifyContent: 'end', fontSize: '12px', fontWeight: 700 }}>
                  <Descriptions.Item className="size-12" label={i18n.t('count')}>
                    {toFormatNumber(couponList?.TotalRecord, 0)}
                  </Descriptions.Item>
                  <Descriptions.Item className="size-12" label={i18n.t('totalPromotions')}>
                    {toFormatNumber(couponList?.TotalAmount)}
                  </Descriptions.Item>
                  <Descriptions.Item className="size-12" label={`${i18n.t('received')}${i18n.t('total')}`}>
                    {toFormatNumber(couponList?.TotalReceivedAmount)}
                  </Descriptions.Item>
                </Descriptions>
              </Spin>
            </Col>
            {$p('31002') && <Col span={24}>
              <Export
                url={'/redemptioncode/export'}
                param={{
                  ...params, PageIndex: 1, PageSize: couponList?.TotalRecord || 0
                }}
                sheetName={i18n.t('couponQuery')}
                columns={ExcelColumns.CouponQuery}
                otherData={{ Domain }}
              />
            </Col>}
            <Col span={24}>
              <Spin spinning={couponListIsLoading}>
                <Table
                  size="middle"
                  pagination={false}
                  dataSource={couponList?.Data?.map((item: CouponQueryListType) => ({ key: item.RedemptionCode, ...item }))}
                  columns={[
                    {
                      title: i18n.t('couponCodes'),
                      dataIndex: 'RedemptionCode',
                      width: '23rem',
                      render: (val) => (
                        <Row justify="space-between" align="middle">
                          <Col>{val}</Col>
                          <Col><CopyButton text={val} /></Col>
                        </Row>
                      )
                    },
                    {
                      title: i18n.t('order'),
                      dataIndex: 'BonusTicketCode',
                      width: '14rem',
                      render: (val) => val || '-'
                    },
                    {
                      title: i18n.t('memberAccount'),
                      dataIndex: 'MemberAccount',
                      width: '8rem',
                      render: (val) => val || '-'
                    },
                    {
                      title: i18n.t('memberLevel'),
                      dataIndex: 'MemberLevelName',
                      width: '6rem',
                      render: (val) => val || '-'
                    },
                    {
                      title: `${i18n.t('recommendedMembers')}／${i18n.t('agent')}／${i18n.t('generalAgent')}`,
                      dataIndex: 'UpMemberAccount',
                      width: '13rem',
                      render: (val, { SAAccount, AGAccount }: any) => (
                        `${val || '-'}／${AGAccount || '-'}／${SAAccount || '-'}`
                      )
                    },
                    {
                      title: i18n.t('promotionName'),
                      dataIndex: 'BonusName',
                      width: '10rem'
                    },
                    {
                      title: i18n.t('redEnvelopeAmount'),
                      dataIndex: 'Amount',
                      width: '10rem',
                      render: (val) => toFormatNumber(val)
                    },
                    {
                      title: i18n.t('rolloverRate'),
                      dataIndex: 'ValidBetTimes',
                      width: '6rem'
                    },
                    {
                      title: i18n.t('status'),
                      dataIndex: 'RedemptionCodeStatus',
                      width: '6rem',
                      render: (val) => (
                        <div className={val ? 'color-pass' : 'color-reject'}>
                          {i18n.t(COUPON_EXPORT_STATUS[val])}
                        </div>
                      )
                    },
                    {
                      title: i18n.t('takeTime'),
                      dataIndex: 'RedemptionCodeReceivedTime',
                      width: '10rem',
                      render: (val) => timeS2L(val)
                    },
                    {
                      title: i18n.t('createTime'),
                      dataIndex: 'RedemptionCodeCreateDate',
                      width: '10rem',
                      render: (val) => timeS2L(val)
                    },
                  ]}
                />
                <LayoutPagination total={couponList?.TotalRecord || 0} page={page} setPage={setPage} />
              </Spin>
            </Col>
          </Row>
        </Form>
      </Content>
    </div>
  )
}

export default PageMain