import { Button, Col, Form, Input, Row, Select, Spin } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { useForm } from 'antd/lib/form/Form';
import { PopupAgentAddAdjust, ViewAdjustAgentList, ViewAdjustAgentOverview } from 'components/adjust.component';
import { DatePickerCol, Export, LayoutNav, LayoutPagination } from 'components/layout.component';
import { ExcelColumns } from 'constants/excel';
import dayjs from 'dayjs';
import { DATE_RANGE_LIMIT } from 'enum/date';
import { AGENT_ADJUST_STATE, SEARCH_DATE_TYPE } from 'enum/state';
import useAccount from 'hooks/account.hook';
import i18n from 'i18n';
import React, { useState } from 'react';
import { $get } from 'services';
import { enumToOptions, timeL2S, tzShift } from "utils/common";

// 代理調帳

const PageMain: React.FC = () => {
  const { permissions: $p } = useAccount();

  const [form] = useForm();
  const [page, setPage] = useState<number[]>([1, 10]);
  const [reset, setReset] = useState<boolean>(false);
  const [isOpenAddModal, setIsOpenAddModal] = useState(false);
  const [date, setDate] = useState([
    tzShift(dayjs().startOf('day'), 'YYYY-MM-DD HH:mm:ss'),
    tzShift(dayjs().endOf('day'), 'YYYY-MM-DD HH:mm:ss')
  ]);

  const defaultDate = SEARCH_DATE_TYPE.createTime;
  const [agentListParams, setAgentListParams] = useState<any>({
    CreateDateStartTime: timeL2S(date[0]),
    CreateDateEndTime: timeL2S(date[1]),
  })
  const { data: agentList, mutate, isValidating } = $get({
    url: 'api/adjustticket/agent/list',
    params: {
      ...agentListParams,
      PageIndex: page[0],
      PageSize: page[1],
    }
  })
  const search = (formData: any) => {
    const timeMode = formData.TimeMode;
    const type = timeMode === defaultDate ? 'Create' : 'Audited';
    setPage([1, page[1]]);
    setAgentListParams({
      Code: formData.Code,
      AgentAccount: formData.AgentAccount,
      SAAccoung: formData.SAAccoung,
      Status: formData.Status,
      [`${type}DateStartTime`]: timeL2S(date[0]),
      [`${type}DateEndTime`]: timeL2S(date[1])
    })
    mutate();
  }

  const onClear = () => {
    form.resetFields();
    setReset(!reset);
    setPage([1, 10]);
  }

  return (
    <div id="container">
      <LayoutNav />
      <Content className="p-2">
        {/* 搜尋列 */}
        <Form form={form} onFinish={search} initialValues={{
          TimeMode: defaultDate,
        }}>
          <Row align="middle" gutter={[12, 12]}>
            <Col className='w-12'>
              <Form.Item name="Code">
                <Input placeholder={`${i18n.t('orderNumber')}`} />
              </Form.Item>
            </Col>
            <Col className='w-12'>
              <Form.Item name="AgentAccount">
                <Input placeholder={`${i18n.t('agentAccount')}`} />
              </Form.Item>
            </Col>
            <Col className="w-12">
              <Form.Item name="SAAccoung">
                <Input placeholder={`${i18n.t('masterAgent')}`} />
              </Form.Item>
            </Col>
            <Col className='w-12'>
              <Form.Item name="Status">
                <Select
                  style={{ width: '100%' }}
                  placeholder={`${i18n.t('status')}`}
                  options={[
                    { value: '', label: i18n.t('ALL') },
                    ...enumToOptions(AGENT_ADJUST_STATE)
                  ]}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row className="mt-1" align="middle" gutter={[12, 12]}>
            <Col className='w-12' >
              <Form.Item name="TimeMode">
                <Select
                  className="w-full"
                  placeholder={i18n.t('pleaseSelect')}
                  options={enumToOptions(SEARCH_DATE_TYPE)}
                />
              </Form.Item>
            </Col>
            <DatePickerCol width={181} date={date} setDate={setDate} initDate={reset} rangeLimit={DATE_RANGE_LIMIT.past31Days} />
            <Col>
              <Button type="primary" htmlType="submit">{i18n.t('search')}</Button>
            </Col>
            <Col>
              <Button onClick={onClear}>{i18n.t('clear')}</Button>
            </Col>
          </Row>
        </Form>
        {/* 搜尋數據 */}
        <Row className="mt-1" gutter={[12, 12]}>
          {/* 總計 */}
          <Col span={24}>
            <Spin spinning={isValidating}>
              <ViewAdjustAgentOverview data={agentList} />
            </Spin>
          </Col>
          {/* 新增按鈕 */}
          <Col span={24}>
            <Row gutter={[12, 12]}>
              {
                $p('40703') &&
                <Col>
                  <Button type="primary" onClick={() => setIsOpenAddModal(true)}>{i18n.t('add')}</Button>
                </Col>
              }
              {/* 匯出按鈕 */}
              {
                $p('40702') &&
                <Col>
                  <Export url={'/adjustticket/agent/list/export'} param={{
                    ...agentListParams, PageSize: agentList && agentList.TotalRecord, PageIndex: 1
                  }} sheetName={i18n.t('agentAdjustmentQuery')} columns={ExcelColumns.AgentAdjustment} />
                </Col>
              }
            </Row>
          </Col>
          {/* 列表 */}
          <Col span={24}>
            <Spin spinning={isValidating}>
              <ViewAdjustAgentList data={agentList} mutate={mutate} />
              <LayoutPagination total={agentList && agentList.TotalRecord} page={page} setPage={setPage} />
            </Spin>
          </Col>
        </Row>
        <PopupAgentAddAdjust isOpen={isOpenAddModal} close={() => setIsOpenAddModal(false)} mutate={mutate} />
      </Content>
    </div>
  );
};

export default PageMain;