import { DatabaseFilled, InfoCircleFilled } from "@ant-design/icons";
import { Button, Checkbox, Col, Form, Popover, Row, Spin, Table, Tooltip } from "antd";
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { Content } from 'antd/es/layout/layout';
import { DatePickerCol, Export, LayoutNav } from 'components/layout.component';
import { ExcelColumns } from 'constants/excel';
import dayjs from 'dayjs';
import { DATE_RANGE_LIMIT, DATE_TYPE } from 'enum/date';
import useAccount from "hooks/account.hook";
import i18n from 'i18n';
import React, { useEffect, useState } from 'react';
import { Bar, Line } from 'react-chartjs-2';
import { $get } from "services";
import * as common from 'utils/common';
import { toFormatNumber as $f, timeL2S } from "utils/common";

// 營運報表

const chartOptions = {
  responsive: true,
  scales: {
    y: {
      ticks: {
        stepSize: 1
      }
    }
  },
  plugins: {
    legend: {
      position: 'bottom' as const,
    },
  },
}

const PageMain: React.FC = () => {
  const { init, permissions: $p } = useAccount();

  const [form] = Form.useForm();
  const [reset, setReset] = useState<boolean>(false);
  const [isOpenMore, setIsOpenMore] = useState(false);
  const [date, setDate] = useState([
    common.tzShift(dayjs().startOf('month'), 'YYYY-MM-DD HH:mm:ss'),
    common.tzShift(dayjs().endOf('month'), 'YYYY-MM-DD HH:mm:ss'),
    DATE_TYPE.thisMonth
  ]);

  const [reportBusinessParam, SetReportBusinessParam] = useState<ReportBusinessParam>({
    StartDate: timeL2S(date[0] as string),
    EndDate: timeL2S(date[1] as string),
  });
  const { data: reportBusiness, mutate, isValidating } = $get({
    url: 'api/report/business/list',
    params: reportBusinessParam,
    allow: !!reportBusinessParam
  });

  const [amountData, setAmountData] = useState<any>({ datasets: [] });
  const [newcomerData, setNewcomerData] = useState<any>({ datasets: [] });
  const [betData, setBetData] = useState<any>({ datasets: [] });
  const [profitData, setProfitData] = useState<any>({ datasets: [] });

  useEffect(() => {
    if (reportBusiness) {
      const res = [...reportBusiness?.Data].reverse() || [];
      const labels = res.map((item: any) => item.ReportDate);
      setAmountData({
        labels,
        datasets: [
          {
            label: i18n.t('totalDepositAmount'),
            data: res.map((item: any) => item.TotalDepositAmount || 0),
            backgroundColor: '#AFC8EA',
          },
          {
            label: i18n.t('totalAmountWithdrawal'),
            data: res.map((item: any) => item.TotalWithdrawAmount || 0),
            backgroundColor: '#CBEAD9',
          },
        ],
      });
      setNewcomerData({
        labels,
        datasets: [
          {
            label: i18n.t('newUsers'),
            data: res.map((item: any) => item.TotalNewMemberCount || 0),
            backgroundColor: '#AFC8EA',
          },
          {
            label: i18n.t('activeMembers'),
            data: res.map((item: any) => item.TotalActivityMemberCount || 0),
            backgroundColor: '#CBEAD9',
          },
          {
            label: i18n.t('bettingMembers'),
            data: res.map((item: any) => item.TotalActivityMemberCount || 0),
            backgroundColor: '#E79F9C',
          },
        ],
      });
      setBetData({
        labels,
        datasets: [
          {
            label: i18n.t('betAmountValid'),
            data: res.map((item: any) => item.TotalValidBetAmount || 0),
            backgroundColor: '#AFC8EA',
          },
          {
            label: i18n.t('totalBetMoney'),
            data: res.map((item: any) => item.TotalBetAmount || 0),
            backgroundColor: '#CBEAD9',
          },
        ],
      });
      setProfitData({
        labels,
        datasets: [
          {
            label: i18n.t('gameProfit'),
            data: res.map((item: any) => item.TotalWinLossAmount || 0),
            borderColor: '#4874AD',
            backgroundColor: '#4874AD',
          },
        ],
      });
    };
  }, [reportBusiness]);

  const onClear = () => {
    setReset(!reset);
  }

  const handleMore = (e: CheckboxChangeEvent) => {
    setIsOpenMore(e.target.checked)
  }

  const seach = () => {
    SetReportBusinessParam({
      StartDate: timeL2S(date[0] as string),
      EndDate: timeL2S(date[1] as string)
    })
    mutate()
  };

  const sum = (prop: any) => {
    return reportBusiness?.Data?.reduce((a: any, b: any) => b[prop] ? a + b[prop] : a, 0);
  };

  const [columns, setColumns] = useState<any>([]);

  const updateColumnShow = (key: string, checked: boolean) => {
    columns.find((item: any) => {
      if (item.children[0].dataIndex === key) {
        item.show = checked

        return true
      }
    })
    setColumns([...columns]);
  };

  useEffect(() => {
    if (init && reportBusiness) {
      setColumns([
        {
          title: i18n.t('date'),
          align: 'center',
          show: true,
          children: [
            {
              dataIndex: 'ReportDate',
              title: i18n.t('total'),
              align: 'right',
              fixed: 'left',
              width: 120,
              render: (val: any) => <div className="text-center">{val}</div>
            },
          ]
        },
        {
          title: i18n.t('newUsers'),
          show: true,
          children: [
            {
              dataIndex: 'TotalNewMemberCount',
              title: $f(sum('TotalNewMemberCount'), 0),
              align: 'right',
              width: 90,
              render: (val: any) => $f(val, 0)
            }
          ]
        },
        {
          title: i18n.t('activeMembers'),
          show: true,
          children: [
            {
              dataIndex: 'TotalActivityMemberCount',
              title: $f(sum('TotalActivityMemberCount'), 0),
              align: 'right',
              width: 90,
              render: (val: any) => $f(val, 0)
            }
          ]
        },
        {
          title: i18n.t('totalMemberCount'),
          show: true,
          children: [
            {
              dataIndex: 'TotalDepositRecordCount',
              title: $f(sum('TotalDepositRecordCount'), 0),
              align: 'right',
              width: 100,
              render: (val: any) => $f(val, 0)
            }
          ]
        },
        {
          title: i18n.t('totalWithdrawalCount'),
          show: true,
          children: [
            {
              dataIndex: 'TotalWithdrawRecordCount',
              title: $f(sum('TotalWithdrawRecordCount'), 0),
              align: 'right',
              width: 90,
              render: (val: any) => $f(val, 0)
            }
          ]
        },
        {
          title: i18n.t('firstDepositMembers'),
          show: true,
          children: [
            {
              dataIndex: 'TotalFirstTimeDepositMemberCount',
              title: $f(sum('TotalFirstTimeDepositMemberCount'), 0),
              align: 'right',
              width: 90,
              render: (val: any) => $f(val, 0)
            }
          ]
        },
        {
          title: i18n.t('redepositMembers'),
          show: true,
          children: [
            {
              dataIndex: 'TotalSecondTimeDepositRecordCount',
              title: $f(sum('TotalSecondTimeDepositRecordCount'), 0),
              align: 'right',
              width: 90,
              render: (val: any) => $f(val, 0)
            }
          ]
        },
        {
          title: i18n.t('firstDepositAmount'),
          show: true,
          children: [
            {
              dataIndex: 'TotalFirstTimeDepositAmount',
              title: $f(sum('TotalFirstTimeDepositAmount')),
              align: 'right',
              width: 120,
              render: (val: any) => $f(val)
            }
          ],
        },
        {
          title: i18n.t('totalDepositAmount'),
          show: true,
          children: [
            {
              dataIndex: 'TotalDepositAmount',
              title: $f(sum('TotalDepositAmount')),
              align: 'right',
              width: 120,
              render: (val: any) => $f(val)
            }
          ]
        },
        {
          title: i18n.t('totalAmountWithdrawal'),
          show: true,
          children: [
            {
              dataIndex: 'TotalWithdrawAmount',
              title: $f(sum('TotalWithdrawAmount')),
              align: 'right',
              width: 120,
              render: (val: any) => $f(val)
            }
          ]
        },
        {
          title: () => (
            <>
              <Tooltip placement="top" className="size-12"
                title={i18n.t(`dailyDataWithoutDuplicateAccounts`)}>
                <InfoCircleFilled />
              </Tooltip>
              <span style={{ marginLeft: 5 }}>{i18n.t('bettingMembers')}</span>
            </>
          ),
          show: true,
          children: [
            {
              dataIndex: 'TotalBettingMemberCount',
              title: $f(sum('TotalActivityMemberCount'), 0),
              align: 'right',
              width: 90,
              render: (val: any, record: any) => $f(record.TotalActivityMemberCount, 0)
            }
          ]
        },
        {
          title: i18n.t('totalBets'),
          show: true,
          children: [
            {
              dataIndex: 'TotalBetCount',
              title: $f(sum('TotalBetCount'), 0),
              align: 'right',
              width: 90,
              render: (val: any) => $f(val, 0)
            }
          ]
        },
        {
          title: i18n.t('betAmountValid'),
          show: true,
          children: [
            {
              dataIndex: 'TotalValidBetAmount',
              title: $f(sum('TotalValidBetAmount')),
              align: 'right',
              width: 120,
              render: (val: any) => $f(val)
            }
          ]
        },
        {
          title: i18n.t('payout'),
          show: true,
          children: [
            {
              dataIndex: 'TotalSettleAmount',
              title: $f(sum('TotalSettleAmount')),
              align: 'right',
              width: 120,
              render: (val: any) => $f(val)
            }
          ]
        },
        {
          title: i18n.t('gameProfit'),
          show: true,
          children: [
            {
              dataIndex: 'TotalWinLossAmount',
              title: $f(sum('TotalWinLossAmount')),
              align: 'right',
              width: 120,
              render: (val: any) => <span className={common.numColor03(val)}>{$f(val)}</span>
            }
          ]
        },
        {
          title: i18n.t('monthlyTotalWinLoss'),
          show: true,
          children: [
            {
              dataIndex: 'TotalMonthWinLossAmount',
              title: '-',
              align: 'right',
              width: 120,
              render: (val: any) => <span className={common.numColor01(val)}>{$f(val)}</span>
            }
          ]
        },
        {
          isOpenMore: true,
          title: i18n.t('promotionAmount'),
          show: true,
          children: [
            {
              dataIndex: 'TotalBonusAmount',
              title: $f(sum('TotalBonusAmount')),
              align: 'right',
              width: 120,
              render: (val: any) => $f(val)
            }
          ],
        },
        {
          isOpenMore: true,
          title: i18n.t('adjustmentAmount'),
          show: true,
          children: [
            {
              align: 'right',
              width: 120,
              title: () => {
                const adjustTotal = Math.abs(sum('TotalAdjustMinusAmount'));
                return (
                  <>
                    <div className={common.numColor03(sum('TotalAdjustPlusAmount'))}>
                      {$f(sum('TotalAdjustPlusAmount'))}
                    </div>
                    <div className={common.numColor03(-adjustTotal)}>
                      {adjustTotal !== 0 ? $f(-adjustTotal) : $f(0)}
                    </div>
                  </>
                )
              },
              render: (val: any, { TotalAdjustPlusAmount, TotalAdjustMinusAmount }: any) => {
                const adjustTotal = Math.abs(TotalAdjustMinusAmount);
                return (
                  <div>
                    <div className={common.numColor03(TotalAdjustPlusAmount)}>{$f(TotalAdjustPlusAmount)}</div>
                    <div className={common.numColor03(-adjustTotal)}>
                      {TotalAdjustMinusAmount !== 0 ? $f(-adjustTotal) : $f(0)}
                    </div>
                  </div>
                )
              }
            }
          ]
        }
      ])
    }
  }, [init && reportBusiness]);

  return (
    <div id="container">
      <LayoutNav />
      <Content className="p-2">
        {/* 搜尋 */}
        <Form form={form} onFinish={seach}>
          {/* 搜尋列 */}
          <Row align="middle" gutter={12}>
            <DatePickerCol width={190} date={date} setDate={setDate} initDate={reset}
              defaultDateType={DATE_TYPE.thisMonth} rangeLimit={DATE_RANGE_LIMIT.past31Days} />
            <Col>
              <Button type="primary" htmlType="submit">{i18n.t('search')}</Button>
            </Col>
            <Col>
              <Button onClick={onClear}>{i18n.t('clear')}</Button>
            </Col>
          </Row>
        </Form>
        {/* 圖表 */}
        <Spin spinning={isValidating}>
          <Row className="mt-1" gutter={[10, 10]}>
            <Col span={12}>
              <Col className="size-16">{i18n.t('financeStatistics')}</Col>
              <Bar options={chartOptions} data={amountData} />
            </Col>
            <Col span={12}>
              <Col className="size-16">{i18n.t('memberActivityStatistics')}</Col>
              <Bar options={chartOptions} data={newcomerData} />
            </Col>
          </Row>
          <Row className="mt-1" gutter={[10, 10]}>
            <Col span={12}>
              <Col className="size-16">{i18n.t('betStatistics')}</Col>
              <Bar options={chartOptions} data={betData} />
            </Col>
            <Col span={12}>
              <Col className="size-16">{i18n.t('gameProfit')}</Col>
              <Line options={chartOptions} data={profitData} />
            </Col>
          </Row>
        </Spin>
        {/* 列表 */}
        <Row className="mt-1" gutter={[10, 10]}>
          <Col span={24}>
            <Row justify="space-between">
              <Col>
                {$p('60302') && <Export url={'/report/business/list/export'} param={{ ...reportBusinessParam }}
                  sheetName={i18n.t('operationReports')} columns={ExcelColumns.ReportBusiness} />}
              </Col>
              <Col>
                <Row gutter={[5, 5]}>
                  <Col>
                    <Button onClick={() => setIsOpenMore(!isOpenMore)}>
                      <Checkbox style={{ marginRight: 5 }} defaultChecked={false} checked={isOpenMore} onChange={handleMore} />
                      {i18n.t('More')}
                    </Button>
                  </Col>
                  <Col>
                    <Popover
                      placement="bottomRight"
                      trigger="click"
                      content={
                        <div className="w-full">
                          {columns
                            .filter((item: any) => !item.isOpenMore)
                            .map((item: any) =>
                              <div key={item.key}>
                                <Checkbox
                                  checked={item.show}
                                  onChange={e => {
                                    updateColumnShow(item.children[0].dataIndex, e.target.checked)
                                  }}
                                >
                                  {typeof item.title === 'function' ? (
                                    React.Children.map(item.title().props.children[1], (child) =>
                                      typeof child === 'object' && child.type === 'span' ? (
                                        child.props.children
                                      ) : (
                                        child
                                      )
                                    )
                                  ) : (
                                    item.title
                                  )}
                                </Checkbox>
                              </div>
                            )}
                        </div>
                      }
                    >
                      <Button><DatabaseFilled /></Button>
                    </Popover>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col span={24} style={{ marginBottom: '60px' }}>
            <Table
              loading={isValidating}
              size="middle"
              bordered
              scroll={{ y: 600, x: '100%' }}
              dataSource={reportBusiness?.Data?.map((item: ReportBusiness) => ({ ...item, key: item.ReportDate }))}
              columns={columns
                .filter((item: any) => item.show)
                .filter((item: any) => !(!isOpenMore && item.isOpenMore)) as any}
              pagination={false}
            />
          </Col>
        </Row>
      </Content>
    </div>
  );
};

export default PageMain;