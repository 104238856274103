import { InfoCircleFilled } from "@ant-design/icons";
import { Button, Col, Form, Input, message, Row, Select, Spin, Table, Tooltip } from "antd";
import { useForm } from "antd/lib/form/Form";
import { Content } from "antd/lib/layout/layout";
import { DatePickerCol, Export, InquiryWindow, LayoutNav, LayoutPagination } from "components/layout.component";
import { ExcelColumns } from "constants/excel";
import { RESPONSE_CODE_BONUS } from "constants/response";
import dayjs from "dayjs";
import { COUPON_STATUS } from "enum/coupon";
import { DATE_TYPE } from "enum/date";
import useAccount from "hooks/account.hook";
import useSite from "hooks/site.hook";
import i18n from "i18n";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { $get, $post } from "services";
import { timeL2S, timeS2L, toFormatNumber, tzShift } from "utils/common";

// 兌換碼優惠管理

const PageMain: React.FC = () => {
  const { Domain } = useSite().data;
  const navigate = useNavigate();
  const { permissions: $p } = useAccount();
  const [form] = useForm();
  const [page, setPage] = useState<number[]>([1, 10]);
  const [reset, setReset] = useState(false);
  // 需求: 週一開始
  const [date, setDate] = useState([
    tzShift(dayjs().startOf('isoWeek'), 'YYYY-MM-DD HH:mm:ss'),
    tzShift(dayjs().endOf('isoWeek'), 'YYYY-MM-DD HH:mm:ss'),
    DATE_TYPE.thisWeek
  ]);

  const [params, setParams] = useState<CouponQueryType>({
    TimeCondition: 0,
    StartDate: timeL2S(date[0] as string),
    EndDate: timeL2S(date[1] as string),
  });
  const [activeDeleteKey, setActiveDeleteKey] = useState<number | null>(null);
  const [isOpenDeletePopup, setIsOpenDeletePopup] = useState(false);

  /* 兌換碼活動名稱列表 */
  const {
    data: couponNameList,
    isValidating: couponNameListIsLoading
  } = $get({
    url: 'api/bonus/redemption/name/list',
  });

  /* 兌換碼管理列表 */
  const {
    data: couponList,
    mutate,
    isValidating: couponListIsLoading
  } = $get({
    url: 'api/bonus/redemption/list',
    params: {
      ...params,
      PageIndex: page[0],
      PageSize: page[1]
    }
  });

  const onFinish = (formData: any) => {
    setPage([1, page[1]]);
    setParams({
      ...params,
      RedemptionCode: formData.RedemptionCode,
      Status: formData.Status,
      Name: formData.Name,
      TimeCondition: formData.TimeCondition,
      StartDate: timeL2S(date[0] as string),
      EndDate: timeL2S(date[1] as string)
    });
    mutate();
  };

  const onClear = () => {
    form.resetFields();
    setReset(!reset);
    setPage([1, 10]);
  };

  const onDelete = () => {
    $post({
      url: 'api/bonus/redemption/delete',
      send: { Id: activeDeleteKey },
      success: () => {
        message.success(i18n.t('deleteSuccess'));
        mutate();
      },
      resCode: RESPONSE_CODE_BONUS
    })
  };

  return (
    <div id="container">
      <LayoutNav />
      <Content>
        <Form form={form} onFinish={onFinish} initialValues={{
          TimeCondition: 0,
        }}>
          <Row gutter={[12, 12]}>
            {/* 搜尋一排 */}
            <Col span={24}>
              <Row gutter={[12, 12]}>
                <Col className="w-12">
                  <Form.Item name="RedemptionCode">
                    <Input placeholder={`${i18n.t('couponCode')}`} />
                  </Form.Item>
                </Col>
                <Col className="w-12" >
                  <Form.Item name="Status">
                    <Select
                      placeholder={`${i18n.t('status')}`}
                      options={[
                        { value: '', label: i18n.t('ALL') },
                        { value: 1, label: i18n.t('shelved') },
                        { value: 0, label: i18n.t('disable') },
                        { value: -1, label: i18n.t('deleted') },
                      ]}
                    />
                  </Form.Item>
                </Col>
                <Col className="w-12" >
                  <Form.Item name="Name">
                    <Select
                      loading={couponNameListIsLoading}
                      showSearch
                      placeholder={`${i18n.t('promotionName')}`}
                      options={
                        couponNameList ? [
                          { value: '', label: i18n.t('ALL') },
                          ...couponNameList?.Data?.map((item: { Id: number, Name: string }) => (
                            { value: item.Name, label: item.Name }
                          ))
                        ] : []}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            {/* 搜尋二排 */}
            <Col span={24}>
              <Row gutter={[12, 12]}>
                <Col className="w-12">
                  <Form.Item name="TimeCondition">
                    <Select
                      options={[
                        { value: 0, label: i18n.t('eventTime') },
                        { value: 1, label: i18n.t('createTime') }
                      ]}
                    />
                  </Form.Item>
                </Col>
                <DatePickerCol width={180} date={date} setDate={setDate} initDate={reset}
                  defaultDateType={DATE_TYPE.thisWeek} />
                <Col>
                  <Button type="primary" htmlType="submit">{i18n.t('search')}</Button>
                </Col>
                <Col>
                  <Button onClick={onClear}>{i18n.t('clear')}</Button>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              {$p('30902') &&
                <Button type="primary" onClick={() => navigate('/promotion/add-coupon')}>{i18n.t('add')}</Button>}
            </Col>
            <Col span={24}>
              <Spin spinning={couponListIsLoading}>
                <Table
                  size="middle"
                  pagination={false}
                  dataSource={couponList?.Data?.map((item: CouponListType) => ({ key: item.Id, ...item }))}
                  columns={[
                    {
                      dataIndex: 'Position',
                      title: i18n.t('frontSort'),
                      width: '6rem'
                    },
                    {
                      dataIndex: 'Name',
                      title: i18n.t('promotionName'),
                      width: '12rem'
                    },
                    {
                      title: i18n.t('couponCodes'),
                      width: '10rem',
                      render: (_, { key, Name }: any) => (
                        <Export
                          showLink
                          className=""
                          disabled={!$p('30905')}
                          title="export"
                          url={'/bonus/redemption/code/export'}
                          param={{ Id: key }}
                          sheetName={Name}
                          columns={ExcelColumns.CouponCodeExport}
                          otherData={{ Domain }}
                        />
                      )
                    },
                    {
                      title: `${i18n.t('remaining')} / ${i18n.t('totalNumberOfSets')}`,
                      width: '12rem',
                      render: (_, { ReceivedCount, RewardQuantity }) => (
                        <>
                          {toFormatNumber(RewardQuantity - ReceivedCount, 0)}／{toFormatNumber(RewardQuantity, 0)}
                        </>
                      )
                    },
                    {
                      dataIndex: 'RewardAmount',
                      title: i18n.t('redEnvelopeAmount'),
                      width: '16rem',
                      render: (val) => toFormatNumber(val)
                    },
                    {
                      dataIndex: 'ValidBetTimes',
                      title: i18n.t('rolloverRate'),
                      width: '6rem'
                    },
                    {
                      dataIndex: 'Status',
                      title: i18n.t('status'),
                      width: '8rem',
                      render: (val) => (
                        <div className={val === COUPON_STATUS.shelved ? 'color-pass' : 'color-reject'}>
                          {i18n.t(COUPON_STATUS[val])}
                        </div>
                      )
                    },
                    {
                      title: `${i18n.t('eventStartTime')} - ${i18n.t('endTime')}`,
                      width: '20rem',
                      render: (_, { StartDate, EndDate }) => (
                        <>
                          <div>{timeS2L(StartDate)} -{' '}</div>
                          <div>{EndDate === '0000-00-00 00:00:00'
                            ? i18n.t('permanent')
                            : dayjs(EndDate).isAfter(dayjs())
                              ? timeS2L(EndDate)
                              : <span className="color-down">{timeS2L(EndDate)}</span>
                          }</div>
                        </>
                      )
                    },
                    {
                      dataIndex: 'CreateDate',
                      title: i18n.t('createTime'),
                      width: '20rem',
                    },
                    {
                      title: () => (
                        <>

                          <Row align="middle" gutter={5}>
                            <Col className="size-12">{i18n.t('operation')}</Col>
                            <Tooltip placement="top" className="size-12" title={i18n.t('deletingWillInvalidateAllRedemptionCodesForThisEvent')}>
                              <InfoCircleFilled />
                            </Tooltip>
                          </Row>
                        </>
                      ),
                      width: '12rem',
                      render: (_, { key, EndDate, Status }) => (
                        <Row>
                          {
                            Status !== COUPON_STATUS.deleted &&
                            <>
                              {
                                $p('30903') &&
                                (EndDate === '0000-00-00 00:00:00' || dayjs(EndDate).isAfter(dayjs())) &&
                                <Col>
                                  <Button className="size-12" type="link"
                                    onClick={() => navigate(`/promotion/edit-coupon/${key}`)}>
                                    {i18n.t('edit')}
                                  </Button>
                                </Col>
                              }
                              {
                                $p('30904') &&
                                <Col>
                                  <Button className="size-12" type="link"
                                    onClick={() => {
                                      setIsOpenDeletePopup(true);
                                      setActiveDeleteKey(key);
                                    }}>
                                    {i18n.t('delete')}
                                  </Button>
                                </Col>
                              }
                            </>
                          }
                        </Row>
                      )
                    },
                  ]}
                />
                <LayoutPagination total={couponList?.TotalRecord || 0} page={page} setPage={setPage} />
              </Spin>
            </Col>
          </Row>
        </Form>
      </Content>
      <InquiryWindow isOpen={isOpenDeletePopup} close={() => setIsOpenDeletePopup(false)} action={onDelete} />
    </div>
  )
}

export default PageMain