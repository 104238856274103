import { QuestionCircleFilled } from '@ant-design/icons';
import { Button, Checkbox, Col, Descriptions, Divider, Form, Input, Row, Select, Spin, Tooltip, message, Tag } from "antd";
import { Content } from 'antd/es/layout/layout';
import { LayoutNav, LayoutTabMember } from 'components/layout.component';
import { MemberLevelSelect, PopupMemberLoginHistory, ViewMemberMemoList } from 'components/member.component';
import { REGEX_EMAIL, REGEX_PASSWORD } from 'constants/regex';
import { RESPONSE_CODE_MEMBER } from 'constants/response';
import { getDaysInMonth, getYear } from 'date-fns';
import { ENABLE, MEMBER_FREEZE, MEMBER_STATE } from "enum/state";
import useAccount from 'hooks/account.hook';
import i18n from 'i18n';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { $get, $post } from 'services';
import * as common from 'utils/common';
import { timeS2L } from "utils/common";

// 編輯會員

const PageMain: React.FC = () => {
  const { permissions: $p } = useAccount();

  const navigate = useNavigate();

  const { id, account, agId } = useParams();
  const [form] = Form.useForm();
  const [isOpenLoginHistory, setIsOpenLoginHistory] = useState(false);
  const [isLockLevel, setIsLockLevel] = useState(false);
  const [birthday, setBirthday] = useState({}) as any;
  const [day, setDay] = useState(0);
  const [isAllDisabled, setIsAllDisabled] = useState(false);

  // 會員標籤列表
  const { data: tagList } = $get({ url: 'api/tag/member/list' });
  const enableTagList = tagList?.Data?.filter((item: any)=>item.Status === ENABLE.enabled) || [];

  const { data: AllAgentList, isValidating: loadingAgentList } = $get({
    url: 'api/agent/settlecycle/member/list',
    params: { MemberId: id }
  })
  const { data: member, isValidating } = $get({ 
    url: 'api/member/one', 
    params: { Id: id }, 
    allow: !!id 
  });
  // 會員群組列表
  const { data: MemberGroupNameList } = $get({
    url: 'api/membergroup/list',
    params: { PageIndex: 1, PageSize: 9999 }
  });

  useEffect(() => {
    if (member && member.Data) {
      const birth = member.Data.Birth.split('-');
      const Tags = JSON.parse(member.Data.TagsId)
        .filter((tag: any) => enableTagList.some((item: any) => item.Id === tag));

      setBirthday({
        year: birth[0],
        month: birth[1],
        day: birth[2],
      });

      form.setFieldsValue({
        Account: member.Data.Account,
        Name: member.Data.Name,
        IDNumber: member.Data.IDNumber,
        PhonePrefix: member.Data.PhonePrefix ? member.Data.PhonePrefix.replace('+', '') : '',
        PhoneNumber: member.Data.PhoneNumber,
        Email: (member.Data.Email === null) ? '' : member.Data.Email,
        birthYear: parseInt(birth[0]) === 0 ? undefined : birth[0],
        birthMonth: parseInt(birth[1]) === 0 ? undefined : birth[1],
        birthDate: parseInt(birth[2]) === 0 ? undefined : birth[2],
        LevelId: member.Data.LevelId,
        MemberGroupId: member.Data.MemberGroupId,
        agent: member.Data.AGId,
        IsFreeze: member.Data.IsFreeze ? MEMBER_FREEZE.frozen : MEMBER_FREEZE.normal,
        Status: member.Data.Status,
        RefCode: member.Data.RefCode,
        CreateDate: timeS2L(member.Data.CreateDate),
        LastLogInDate: timeS2L(member.Data.LastLogInDate),
        VerifiedDate: timeS2L(member.Data.VerifiedDate),
        RegisterIP: member.Data.RegisterIP,
        Nickname: member.Data.Nickname,
        noCode: member.Data.NoCode,
        TagsId: Tags
      });

      setIsLockLevel(!!member.Data.IsLockLevel);
    }
  }, [member]);

  const onFinish = (formData: any) => {
    if (!REGEX_EMAIL.test(formData.Email)) {
      message.error(i18n.t('emailFormatError'));
      return;
    }
    if (formData.password?.length !== 0 && !REGEX_PASSWORD.test(formData.password)) {
      message.error(i18n.t('incorrectPasswordFormat'));
      return;
    }
    if (formData.Nickname?.length !== 0 && (formData.Nickname?.length < 4 || formData.Nickname?.length > 10)) {
      message.error(i18n.t('nicknameFormatError'));
      return;
    }
    $post({
      url: 'api/member/update',
      send: {
        Id: member?.Data?.Id || 0,
        IDNumber: formData.IDNumber,
        Type: 0,
        Name: formData.Name,
        Birth: `${formData.birthYear}-${formData.birthMonth}-${formData.birthDate} 00:00:00`,
        IsLockLevel: isLockLevel ? 1 : 0,
        Password: formData.password ? formData.password : null,
        LevelId: formData.LevelId,
        MemberGroupId: formData.MemberGroupId,
        TagsId: formData.TagsId || [],
        Email: formData.Email,
        AgentId: formData.agent,
        PhonePrefix: `+${formData.PhonePrefix}`,
        PhoneNumber: formData.PhoneNumber,
        IsFreeze: formData.IsFreeze === MEMBER_FREEZE.normal ? 0 : 1,
        Status: formData.Status,
        NickName: formData.Nickname,
      },
      success: () => {
        message.success(i18n.t('updateSuccess'));
        setIsAllDisabled(true);
      },
      resCode: RESPONSE_CODE_MEMBER
    })
  };
  useEffect(() => {
    const { year, month, day } = birthday;
    if (!year || !month || !day) return;

    const date = new Date(year, month - 1);
    const days = getDaysInMonth(date);

    if (parseInt(day) > days) {
      setBirthday({ ...birthday, day: '01' });
      form.setFieldValue('birthDate', '01');
    }

    if (parseInt(year) !== 0 && parseInt(month) !== 0) setDay(days);
  }, [birthday]);

  const [isPhoneRequire, setIsPhoneRequire] = useState(true);

  useEffect(() => {
    if (member && member.Data && member.Data.Status === MEMBER_STATE.waitApproval) {
      setIsPhoneRequire(false);
    }
  }, [member]);

  const onChangeMemberStatus = (select: number) => {
    switch (select) {
      case MEMBER_STATE.approvaled:
        setIsPhoneRequire(true);
        break;
      case MEMBER_STATE.waitApproval:
        setIsPhoneRequire(false);
        break;
    }
  }

  const $editPermissions = $p('10202');

  return (
    <div id="container">
      <LayoutNav id={id} account={account} agId={agId}/>
      <Content className="p-2">
        <LayoutTabMember activeKey="1" id={id} account={account} agId={agId} />
        {/* 基本資料 */}
        <Spin spinning={isValidating}>
          <Form form={form} onFinish={onFinish}>
            <Row align="middle" gutter={[12, 12]}>
              <Col span={24}>
                <Row justify="end" gutter={[12, 12]}>
                  {
                    isAllDisabled || !$editPermissions
                      ?
                      <Col><Button onClick={() => navigate('/member')}>{i18n.t('close')}</Button></Col>
                      : <>
                        <Col><Button onClick={() => navigate('/member')}>{i18n.t('cancel')}</Button></Col>
                        <Col><Button type="primary" htmlType="submit">{i18n.t('confirm')}</Button></Col>
                      </>
                  }
                </Row>
              </Col>
              <Col span={24}>
                <Descriptions
                  labelStyle={{ fontSize: 12, fontWeight: 700, width: 200 }}
                  contentStyle={{ width: 450 }}
                  size="small"
                  bordered
                >
                  <Descriptions.Item label={<><span className="require">*</span>{i18n.t('memberAccount')}</>}>
                    <Form.Item name="Account">
                      <Input disabled />
                    </Form.Item>
                  </Descriptions.Item>
                  <Descriptions.Item label={<><span className="require">*</span>{i18n.t('realName')}</>}>
                    <Form.Item name="Name" rules={[{ required: true, message: `${i18n.t('required')}`, max: 100 }]}>
                      <Input disabled={isAllDisabled || !$editPermissions} placeholder={`${i18n.t('inputData')}`} maxLength={100} />
                    </Form.Item>
                  </Descriptions.Item>
                  <Descriptions.Item label={i18n.t('idNum')}>
                    <Form.Item name="IDNumber">
                      <Input disabled={isAllDisabled || !$editPermissions} placeholder={`${i18n.t('inputData')}`} maxLength={50} />
                    </Form.Item>
                  </Descriptions.Item>
                  <Descriptions.Item label={
                    <>
                      {
                        isPhoneRequire
                          ? <><span className="require">*</span>{i18n.t('countryCode')}-{i18n.t('phoneNumber')}</>
                          : <>{i18n.t('countryCode')} - {i18n.t('phoneNumber')}</>
                      }
                    </>
                  }>
                    <Row>
                      <Col span={8}>
                        <Form.Item name="PhonePrefix"
                          rules={[...common.verify({ point: 7, required: isPhoneRequire })]}>
                          <Input autoComplete="off" disabled={isAllDisabled || !$editPermissions || !isPhoneRequire} placeholder={`${i18n.t('enterCountryCode')}`} maxLength={50} addonBefore={'+'} />
                        </Form.Item>
                      </Col>
                      <Col span={16}>
                        <Form.Item name="PhoneNumber" rules={[{ required: isPhoneRequire, message: `${i18n.t('required')}` }]}>
                          <Input disabled={isAllDisabled || !$editPermissions || !isPhoneRequire} placeholder={`${i18n.t('inputMobile')}`} maxLength={50} />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Descriptions.Item>
                  <Descriptions.Item label={<><span className="require">*</span>{i18n.t('email')}</>}>
                    <Form.Item name="Email" rules={[{ required: true, message: `${i18n.t('required')}` }]}>
                      <Input disabled={isAllDisabled || !$editPermissions} placeholder={`${i18n.t('inputData')}`} maxLength={50} />
                    </Form.Item>
                  </Descriptions.Item>
                  <Descriptions.Item label={<><span className="require">*</span>{i18n.t('birthday')}</>}>
                    <Row>
                      <Col span={8}>
                        <Form.Item name="birthYear" rules={[{ required: true, message: `${i18n.t('required')}` }]}>
                          <Select
                            className="w-full"
                            disabled={isAllDisabled || !$editPermissions}
                            placeholder={i18n.t('pleaseSelect')}
                            onChange={year => setBirthday({ ...birthday, year: `${year}` })}
                            options={
                              Array.from({ length: 100 }).map((_, i) => {
                                const year = getYear(new Date()) - i;
                                return { value: `${year}`, label: `${year}` }
                              })
                            }
                          />
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item name="birthMonth" rules={[{ required: true, message: `${i18n.t('required')}` }]}>
                          <Select
                            className="w-full"
                            disabled={isAllDisabled || !$editPermissions}
                            placeholder={i18n.t('pleaseSelect')}
                            onChange={month => setBirthday({ ...birthday, month: `${month}` })}
                            options={
                              Array.from({ length: 12 }).map((_, i) => {
                                const month = common.leftPad((i + 1).toString(), 2, '0');
                                return { value: `${month}`, label: `${month}` }
                              })
                            }
                          />
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item name="birthDate" rules={[{ required: true, message: `${i18n.t('required')}` }]}>
                          <Select
                            className="w-full"
                            disabled={isAllDisabled || !$editPermissions}
                            placeholder={i18n.t('pleaseSelect')}
                            onChange={day => setBirthday({ ...birthday, day: `${day}` })}
                            options={
                              Array.from({ length: day }).map((_, i) => {
                                const day = common.leftPad((i + 1).toString(), 2, '0');
                                return { value: `${day}`, label: `${day}` }
                              })
                            }
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Descriptions.Item>
                  <Descriptions.Item label={
                    <Row align="middle" gutter={[2, 12]}>
                      <Col>
                        <span className="require">*</span><span>{i18n.t('memberLevel')}</span>
                      </Col>
                      <Col>
                        <Checkbox
                          disabled={isAllDisabled || !$editPermissions}
                          checked={isLockLevel}
                          className="size-10 color-05"
                          onChange={() => setIsLockLevel(!isLockLevel)}
                        >{i18n.t('lockLevel')}
                        </Checkbox>
                        <span>
                          <Tooltip placement="top" className="size-12" title={i18n.t('notApplicableMemberLevelSetting')}>
                            <QuestionCircleFilled />
                          </Tooltip>
                        </span>
                      </Col>
                    </Row>
                  }>
                    <Form.Item name="LevelId" rules={[{ required: true, message: `${i18n.t('required')}` }]}>
                      <MemberLevelSelect name="LevelId" form={form} disabled={isAllDisabled || !$editPermissions} all={false} />
                    </Form.Item>
                  </Descriptions.Item>
                  <Descriptions.Item label={<><span className="require">*</span>{i18n.t('agent')}</>}>
                    <Form.Item name="agent" rules={[{ required: true, message: `${i18n.t('required')}` }]}>
                      <Select
                        className="w-full"
                        disabled={isAllDisabled || !$editPermissions}
                        loading={loadingAgentList}
                        placeholder={`${i18n.t('pleaseSelect')}`}
                        optionFilterProp="children"
                        showSearch
                        filterOption={common.selectFuzzyFilter}
                        options={
                          AllAgentList ?
                            AllAgentList?.Data?.map((item: { Id: number, Account: string }) => (
                              { value: item.Id, label: item.Account }
                            )) : []
                        }
                      />
                    </Form.Item>
                  </Descriptions.Item>
                  <Descriptions.Item label={<><span className="require">*</span>{i18n.t('status')}</>}>
                    <Form.Item name="IsFreeze">
                      <Select
                        disabled={isAllDisabled || !$editPermissions}
                        style={{ width: "100%" }}
                        options={common.enumToOptions(MEMBER_FREEZE)}
                      />
                    </Form.Item>
                  </Descriptions.Item>
                  <Descriptions.Item label={<><span className="require">*</span>{i18n.t('audit')}</>}>
                    <Form.Item name="Status">
                      <Select
                        onChange={onChangeMemberStatus}
                        disabled={isAllDisabled || !$editPermissions}
                        style={{ width: "100%" }}
                        options={common.enumToOptions(MEMBER_STATE)}
                      />
                    </Form.Item>
                  </Descriptions.Item>
                  <Descriptions.Item label={<>{i18n.t('password')}</>}>
                    <Form.Item name="password" rules={[
                      { min: 4, message: `4~20${i18n.t('requiresEnglishAndNumbersSupportDash')}` },
                      { max: 20, message: `4~20${i18n.t('requiresEnglishAndNumbersSupportDash')}` },
                    ]}>
                      <Input placeholder={`4~20${i18n.t('requiresEnglishAndNumbersSupportDash')}`} type="password" disabled={isAllDisabled || !$editPermissions} />
                    </Form.Item>
                  </Descriptions.Item>
                  <Descriptions.Item label={i18n.t('promotionCode')}>
                    <Form.Item name="RefCode">
                      <Input disabled />
                    </Form.Item>
                  </Descriptions.Item>
                  <Descriptions.Item label={i18n.t('createTime')}>
                    <Form.Item name="CreateDate">
                      <Input placeholder="-" disabled />
                    </Form.Item>
                  </Descriptions.Item>
                  <Descriptions.Item label={i18n.t('lastLoginTime')}>
                    <Form.Item name="LastLogInDate">
                      <Input placeholder="-" disabled />
                    </Form.Item>
                  </Descriptions.Item>
                  <Descriptions.Item label={i18n.t('verificationTime')}>
                    <Form.Item name="VerifiedDate" >
                      <Input placeholder="-" disabled />
                    </Form.Item>
                  </Descriptions.Item>
                  <Descriptions.Item label={
                    <Row justify="space-between" align="middle">
                      <Col>{i18n.t('IP')}</Col>
                      <Col>
                        <Button className="size-12" type="link" onClick={() => setIsOpenLoginHistory(true)}>{i18n.t('more')}</Button>
                      </Col>
                    </Row>
                  }>
                    <Form.Item name="RegisterIP" >
                      <Input disabled />
                    </Form.Item>
                  </Descriptions.Item>
                  <Descriptions.Item label={i18n.t('nickname')}>
                    <Form.Item name="Nickname" rules={[
                      { min: 4, message: `4~10${i18n.t('characters')}` },
                      { max: 10, message: `4~10${i18n.t('characters')}` },
                    ]}>
                      <Input placeholder={`4~10${i18n.t('characters')}`} disabled={isAllDisabled || !$editPermissions} />
                    </Form.Item>
                  </Descriptions.Item>
                  <Descriptions.Item label={i18n.t('identifier')}>
                    <Form.Item name="noCode">
                      <Input disabled />
                    </Form.Item>
                  </Descriptions.Item>
                  <Descriptions.Item label={i18n.t('memberTag')}>
                    <Form.Item name="TagsId">
                      <Select
                        mode="tags"
                        disabled={!$p('10202') || isAllDisabled || !$editPermissions}
                        className="w-full"
                        placeholder={i18n.t('pleaseSelect')}
                        options={
                          tagList?.Data
                            ?.filter((item: any)=>item.Status === ENABLE.enabled)
                            ?.map((option: any ) => ({
                            value: option.Id,
                            label:
                              <Tag key={option.Id} style={{ color: option.TextColor, background: option.Color }}>
                                {option.Name}
                              </ Tag>
                          }))
                        }
                      />
                    </Form.Item>
                  </Descriptions.Item>
                  <Descriptions.Item label={<><span className="require">*</span><span>{i18n.t('memberGroup')}</span></>}>
                    <Form.Item name="MemberGroupId" rules={[{ required: true, message: `${i18n.t('required')}` }]}>
                      <Select
                        className="w-full"
                        disabled={isAllDisabled || !$editPermissions}
                        placeholder={i18n.t('pleaseSelect')}
                        options={
                          MemberGroupNameList ?
                            MemberGroupNameList?.Data?.map((item: { Id: number, Name: string }) => (
                              { value: item.Id, label: item.Name }
                            )) : []
                        }
                      />
                    </Form.Item>
                  </Descriptions.Item>
                  {/*對齊用*/}
                  <Descriptions.Item >
                    <></>
                  </Descriptions.Item>
                </Descriptions>
              </Col>
            </Row>
          </Form>
        </Spin>
        <Divider />
        {/* 備註紀錄 */}
        {$p('10203') && <ViewMemberMemoList id={member?.Data?.Id} />}
        <PopupMemberLoginHistory isOpen={isOpenLoginHistory} close={() => setIsOpenLoginHistory(false)} id={member?.Data?.Id} account={member?.Data?.Account} />
      </Content>
    </div>
  );
};

export default PageMain;